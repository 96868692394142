import React, { useEffect, useState } from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { alternateAddressSearchSchema } from '../../../schema/index.js';
import FormInput from './FormInput.jsx';
import Button from '../../../common/Button.jsx';
import { logMessage, useAnalyticsEventTracker } from '../../../utils/utils.js';
import baseConfig from '../../../utils/config.js';
import {
  getAddressList,
  getCSRFToken,
} from '../../../service/addressSearch.js';
import { setAlternatePostOffices } from '../../../reducers/enrollmentReducer.js';
import StateList from '../../state-list.json';
import './address-search.scss';
import { updateAlternateAddressFormData } from '../../../reducers/alternateAddressSearchReducer.js';

export function AddressSearchForm({ setLoading, myPostOffice, hybrid }) {
  const [showForm, setShowForm] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker('Address Search');
  const dispatch = useDispatch();
  const addressSearchData = useSelector(
    (state) => state.alternateAddressSearch
  );
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(alternateAddressSearchSchema),
    defaultValues: addressSearchData,
  });
  const stateValue = watch('state');
  const [searchParams] = useSearchParams();
  const swa = useParams().state || searchParams.get('swa');
  const swa_xid = searchParams.get('swa_xid');
  const lang_cd = searchParams.get('lang_cd');
  const option_sum = searchParams.get('option_sum');

  useEffect(() => {
    getCSRFToken();
    window.isDirty = isDirty;
  }, [isDirty]);

  const handleChange = (event) => {
    const { name } = event.target;
    trigger(name);
  };

  const onSearchButtonClick = async (formData) => {
    logMessage('Alternate address search button clicked');
    gaEventTracker(`${swa}-Search Button Clicked`);
    const { streetAddress1, streetAddress2, city, state, zipcode } = formData;

    if (streetAddress1 && city && state && zipcode) {
      dispatch(updateAlternateAddressFormData(formData));
      const payload = {
        street_address: `${streetAddress1}`.trim(),
        city,
        state,
        zip_code: zipcode,
        swa,
        swa_xid,
      };

      if (zipcode.includes('-')) {
        payload.zip_code = zipcode.split('-')[0];
        payload.zip_ext = zipcode.split('-')[1];
      }

      if (streetAddress2) {
        payload.street_address_2 = `${streetAddress2}`.trim();
      }

      setLoading(true);
      try {
        const response = await getAddressList(swa, swa_xid, payload);
        if (
          response &&
          response.data &&
          Array.isArray(response.data.postOffices) &&
          response.data.postOffices.length === 0
        ) {
          dispatch(setAlternatePostOffices([]));
          sessionStorage.setItem('alternateAddress', JSON.stringify([]));
        } else if (response) {
          dispatch(setAlternatePostOffices(response.data.postOffices));
          sessionStorage.setItem(
            'alternateAddress',
            JSON.stringify(response.data.postOffices)
          );
        }
      } catch (error) {
        const state_usage_code = sessionStorage.getItem('state_usage_code');
        sessionStorage.clear();

        if (error?.response?.status === 409) {
          sessionStorage.setItem(
            'state_errorType',
            JSON.stringify({ type: 'DuplicateSwaXid' })
          );
          gaEventTracker(
            `${swa}-Duplicate swa-xid, Redirected to DuplicateSwaXid`
          );
          navigate(
            `/error/${swa}/?swa_xid=${swa_xid}&option_sum=${option_sum}&redirect_from=${'usps'}&lang_cd=${
              lang_cd || baseConfig.defaultLanguage
            }`,
            { state: { type: 'DuplicateSwaXid' } }
          );
        } else {
          sessionStorage.setItem('state_usage_code', state_usage_code);
          sessionStorage.setItem(
            'state_errorType',
            JSON.stringify({ type: 'HitaWall' })
          );
          gaEventTracker(
            `${swa}-USPS service unavailable due to internal server error`
          );
          navigate(
            `/error/${swa}/?swa_xid=${swa_xid}&option_sum=${option_sum}&redirect_from=${'usps'}&lang_cd=${
              lang_cd || baseConfig.defaultLanguage
            }`,
            { state: { type: 'HitaWall' } }
          );
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleForm = () => {
    logMessage('Alternate address button clicked');
    setShowForm((prevShowForm) => !prevShowForm);
  };

  return (
    <div className="usps-container" id="mainContent">
      <div className="address-search" style={{ padding: '0' }}>
        <section id="main-content">
          <h2 className="sub_heading">
            <span
              data-test-id="alternateAddress-subheading-text"
              className="subheading-text"
            >
              {hybrid && myPostOffice.length === 0 ? (
                <span data-testid="hybridNoPostOffice">
                  <FormattedMessage id="alternate_address_noPostOffice_header_message" />
                </span>
              ) : (
                <FormattedMessage id="alternate_address_heading_text" />
              )}
            </span>
          </h2>
          <div
            data-test-id="alternateAddress-helper-text1"
            className="helper-text"
          >
            <FormattedMessage id="alternate_address_helper_text" />
            <br />
          </div>

          {!showForm && (
            <div className="footer-actions">
              <Button
                type="primary"
                id="toggle-form-button"
                data-testid="toggle-form-button"
                onClick={toggleForm}
              >
                <FormattedMessage id="alternate_address_address_search_button" />
              </Button>
            </div>
          )}

          {showForm && (
            <>
              <div
                data-test-id="alternateAddress-helper-text2"
                className="helper-text-indent"
              >
                <FormattedMessage id="alternate_address_helper_text2" />
                <br />
              </div>
              <div>
                <FormattedMessage id="form_required_info_text" />
                <div className="required-field-quote required-field">*</div>
              </div>
              <br />
              <form
                className="form form-padding"
                onSubmit={handleSubmit(onSearchButtonClick)}
                data-test-id="alternate-address-search-form"
              >
                <FormInput
                  errors={errors}
                  labelKey={'input_street_address_1'}
                  accessor={'streetAddress1'}
                  required
                >
                  <input
                    id="street-address-1"
                    placeholder={formatMessage({
                      id: 'input_street_address_1_placeholder',
                    })}
                    maxLength={255}
                    data-test-id="street-address-1"
                    className={errors.streetAddress1 ? 'error-field' : ''}
                    {...register('streetAddress1', { onChange: handleChange })}
                    aria-required="true"
                    onClick={() =>
                      logMessage(
                        'Street address1 input box clicked in alternate address'
                      )
                    }
                  />
                </FormInput>
                <FormInput
                  errors={errors}
                  labelKey={'input_street_address_2'}
                  accessor={'streetAddress2'}
                >
                  <input
                    id="street-address-2"
                    placeholder={formatMessage({
                      id: 'input_street_address_2_placeholder',
                    })}
                    maxLength={255}
                    data-test-id="street-address-2"
                    className={errors.streetAddress2 ? 'error-field' : ''}
                    {...register('streetAddress2', { onChange: handleChange })}
                    onClick={() =>
                      logMessage(
                        'Street address2 input box clicked in alternate address'
                      )
                    }
                  />
                </FormInput>

                <FormInput
                  errors={errors}
                  labelKey={'input_city_name_1'}
                  accessor={'city'}
                  required
                  aria-required="true"
                >
                  <input
                    id="city"
                    placeholder={formatMessage({
                      id: 'input_city_name_placeholder',
                    })}
                    maxLength={50}
                    data-test-id="city"
                    className={errors.city ? 'error-field' : ''}
                    {...register('city', { onChange: handleChange })}
                    onClick={() =>
                      logMessage('City input box clicked in alternate address')
                    }
                  />
                </FormInput>

                <FormInput
                  errors={errors}
                  labelKey={'input_state_name'}
                  accessor={'state'}
                  required
                  aria-required="true"
                >
                  <select
                    id="state"
                    aria-label="Select State"
                    placeholder={formatMessage({
                      id: 'input_state_name_placeholder',
                    })}
                    className={
                      errors.state
                        ? 'state-dropdown error-field'
                        : 'state-dropdown'
                    }
                    data-test-id="state"
                    style={{
                      color: stateValue === '0' ? 'gray' : 'black',
                    }}
                    {...register('state', { onChange: handleChange })}
                    onClick={() =>
                      logMessage('State dropdown clicked in alternate address')
                    }
                  >
                    <option
                      disabled
                      key="0"
                      value="0"
                      style={{ color: 'gray' }}
                    >
                      <FormattedMessage id="Select_State_DropdownText" />
                    </option>
                    {StateList.map((state) => (
                      <option
                        id={state.abbreviation}
                        key={state.abbreviation}
                        data-test-id={state.abbreviation}
                      >
                        {state.abbreviation}
                      </option>
                    ))}
                  </select>
                </FormInput>

                <FormInput
                  errors={errors}
                  labelKey={'input_zipcode'}
                  accessor={'zipcode'}
                  required
                >
                  <input
                    id="zipcode"
                    placeholder={formatMessage({
                      id: 'input_zipcode_placehoder',
                    })}
                    className={errors.zipcode ? 'error-field' : ''}
                    data-test-id="zipcode"
                    {...register('zipcode', { onChange: handleChange })}
                    aria-required="true"
                    onClick={() =>
                      logMessage(
                        'Zipcode input box clicked in alternate address'
                      )
                    }
                  />
                </FormInput>

                <div className="footer-actions">
                  <Button
                    type="primary"
                    id="search-button"
                    data-testid="search-button"
                    disabled={!isValid}
                  >
                    <FormattedMessage id="alternate_address_form_submit_button" />
                  </Button>
                </div>
              </form>
            </>
          )}
        </section>
      </div>
    </div>
  );
}

export default AddressSearchForm;
