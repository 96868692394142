const baseConfig = {
  retries: process.env.REACT_APP_REMOTE_RETRIES,
  retryInterval: process.env.REACT_APP_REMOTE_RETRY_INTERVAL,
  defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE_CODE,
  logindotgovIAL: process.env.REACT_APP_LOGINDOTGOV_IAL,
  enableTestSiteBanner: process.env.REACT_APP_ENABLE_TEST_SITE_BANNER,
  showConsoleLogs: process.env.REACT_APP_SHOW_CONSOLE_LOGS,
};

export default baseConfig;
