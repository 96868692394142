import React from 'react';
import './Button.scss';

function Button(props) {
  const { type, customized = '', children } = props;
  return (
    <button {...props} className={`basic ${type || 'primary'} ${customized}`}>
      {children}
    </button>
  );
}

export default Button;
