import React, { useState, useEffect } from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router';
import axios from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import addressSearchSchema from '../../../schema';
import FormInput from './FormInput';
import Button from '../../../common/Button';
import { USPSDescription } from '../../usps-description/usps-description.jsx';
import {
  BASE_URL,
  useAnalyticsEventTracker,
  scrollToTop,
  scrollToBottom,
  changeDifferentMethodButton,
  logMessage,
} from '../../../utils/utils.js';
import baseConfig from '../../../utils/config';
import {
  getAddressList,
  getOptInApplicant,
  updateOptInApplicant,
  getServicesConfig,
} from '../../../service/addressSearch';
import { updateFormData } from '../../../reducers/addressSearchReducer';
import {
  setPostOffices,
  setEnrollmentCode,
} from '../../../reducers/enrollmentReducer';
import StateList from '../../state-list.json';
import './address-search.scss';
import StepActiveStatus from '../../../common/Stepper/StepActiveStatus.jsx';
import states, { richText } from '../../../i18n/states.js';
import FraudPreventionModal from '../../fraudPrevention-modal/FraudPreventionModal';

export function AddressSearch(props) {
  const { setLoading, setActiveStep, steps, activeStep, setSteps } = props;
  const gaEventTracker = useAnalyticsEventTracker('Address Search');
  const dispatch = useDispatch();
  const addressSearchData = useSelector((state) => state.addressSearch);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(addressSearchSchema),
    defaultValues: addressSearchData,
  });
  const stateValue = watch('state');
  const [searchParams] = useSearchParams();
  const swa = useParams().state || searchParams.get('swa');
  const swa_xid = searchParams.get('swa_xid');
  const lang_cd = searchParams.get('lang_cd');
  const enrollment_code = searchParams.get('enrollment_code');
  const auth_modal = searchParams.get('auth_modal');
  const validDuration = sessionStorage.getItem('validDuration');

  const [resetOptionSum, setResetOptionSum] = useState(0);
  const option_sum = searchParams.get('option_sum') || resetOptionSum;
  const [showFraudPreventionModal, setShowFraudPreventionModal] =
    useState(false);
  const [isTokenLoaded, setIsTokenLoaded] = useState(false);

  useEffect(() => {
    window.isDirty = isDirty;
  }, [isDirty]);

  const handleToken = async () => {
    const config = {
      method: 'get',
      url: `${BASE_URL}v1/token`,
      withCredentials: true,
    };
    localStorage.setItem('swa_xid', swa_xid);
    try {
      const response = await axios(config);
      window.csrf_token = response.data.csrf_token;
    } catch {
      const state_usage_code = sessionStorage.getItem('state_usage_code');
      sessionStorage.clear();
      sessionStorage.setItem('state_usage_code', state_usage_code);
      sessionStorage.setItem(
        'state_errorType',
        JSON.stringify({ type: 'HitaWall' })
      );
      gaEventTracker(`${swa}-USPS service unavailable`);
      navigate(
        `/error/${swa}/?swa_xid=${swa_xid}&redirect_from=${'usps'}&lang_cd=${
          lang_cd || baseConfig.defaultLanguage
        }`,
        {
          state: { type: 'HitaWall' },
        }
      );
    }
  };

  const handleChange = (event) => {
    const { name } = event.target;
    trigger(name);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.setItem('state_errorType', null);
    handleToken().then(() => {
      setIsTokenLoaded(true);
    });

    if (
      auth_modal === 'true' ||
      sessionStorage.getItem('auth_modal') === 'true'
    ) {
      setShowFraudPreventionModal(false);
    } else {
      setShowFraudPreventionModal(true);
    }

    if (option_sum === 0) {
      axios
        .get(`${BASE_URL}v1/services/${swa}`)
        .then((response) => {
          setResetOptionSum(
            response.data.login_dot_gov +
              response.data.usps_ipp +
              response.data.local_workforce
          );
          sessionStorage.setItem(
            'allow_alternate_address',
            response.data.identity_providers.usps_ipp.allow_alternative_location
          );
        })
        .catch((error) => {
          if (error.response.status === 404) {
            sessionStorage.clear();
            sessionStorage.setItem(
              'state_errorType',
              JSON.stringify({ type: 'WebAddressIncomplete' })
            );
            gaEventTracker(
              `${swa}-404 Error, Redirected to WebAddressIncomplete`
            );
            navigate(
              `/error/${swa}/?lang_cd=${
                lang_cd || baseConfig.defaultLanguage
              }&error_state=404`,
              {
                state: {
                  type: 'WebAddressIncomplete',
                },
              }
            );
          }
        });
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('state_usage_code') === '16') {
      sessionStorage.setItem(
        'state_errorType',
        JSON.stringify({ type: 'WebAddressIncomplete' })
      );
      navigate(`/error/?lang_cd=${lang_cd || baseConfig.defaultLanguage}`, {
        state: { type: 'WebAddressIncomplete' },
      });
    }

    if (!sessionStorage.getItem('state_usage_code')) {
      axios
        .get(`${BASE_URL}v1/services/${swa}`)
        .then((response) => {
          sessionStorage.setItem(
            'show_scanning_image',
            response.data.identity_providers.usps_ipp.image_config
          );
          if ((response.data.state_usage & 16) === 16) {
            sessionStorage.setItem('state_usage_code', '16');
            sessionStorage.setItem(
              'state_errorType',
              JSON.stringify({ type: 'WebAddressIncomplete' })
            );
            navigate(
              `/error/?lang_cd=${lang_cd || baseConfig.defaultLanguage}`,
              {
                state: { type: 'WebAddressIncomplete' },
              }
            );
          }
        })
        .catch(() => {
          sessionStorage.setItem(
            'state_errorType',
            JSON.stringify({ type: 'Error_500' })
          );
          navigate(
            `/error/${swa}/?swa_xid=${swa_xid}&lang_cd=${
              lang_cd || baseConfig.defaultLanguage
            }`,
            {
              state: { type: 'Error_500' },
            }
          );
        });
    }
  }, []);

  const onSearchButtonClick = async (formData) => {
    gaEventTracker(`${swa}-Search Button Clicked`);
    const {
      firstName,
      lastName,
      email,
      streetAddress1,
      streetAddress2,
      city,
      state,
      zipcode,
    } = formData;

    if (
      firstName &&
      lastName &&
      email &&
      streetAddress1 &&
      city &&
      state &&
      zipcode
    ) {
      dispatch(updateFormData(formData));
      const payload = {
        street_address: `${streetAddress1}`.trim(),
        city,
        state,
        zip_code: zipcode,
      };

      if (zipcode.includes('-')) {
        payload.zip_code = zipcode.split('-')[0];
        payload.zip_ext = zipcode.split('-')[1];
      }

      if (streetAddress2) {
        payload.street_address_2 = `${streetAddress2}`.trim();
      }

      setLoading(true);
      try {
        const response = await getAddressList(swa, swa_xid, payload);

        const optInPayload = {
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          contact_info: {
            street_address: `${streetAddress1}`.trim(),
            city: city.trim(),
            state,
            zip_code: zipcode,
            email: email,
          },
        };
        if (zipcode.includes('-')) {
          optInPayload.contact_info.zip_code = zipcode.split('-')[0];
          optInPayload.contact_info.zip_ext = zipcode.split('-')[1];
        }
        if (streetAddress2) {
          optInPayload.contact_info.street_address_2 =
            `${streetAddress2}`.trim();
        }
        if (
          response &&
          response.data &&
          Array.isArray(response.data.postOffices) &&
          response.data.postOffices.length === 0
        ) {
          sessionStorage.clear();
          sessionStorage.setItem('auth_modal', 'true');
          sessionStorage.setItem('firstName', optInPayload.first_name);
          sessionStorage.setItem('lastName', optInPayload.last_name);
          sessionStorage.setItem(
            'streetAddress1',
            optInPayload.contact_info.street_address
          );
          sessionStorage.setItem(
            'streetAddress2',
            optInPayload.contact_info.street_address_2 || ' '
          );
          sessionStorage.setItem('city', optInPayload.contact_info.city);
          sessionStorage.setItem('zipcode', optInPayload.contact_info.zip_code);
          sessionStorage.setItem('email', email);
          sessionStorage.setItem('barcode', enrollment_code);
          sessionStorage.setItem('state', optInPayload.contact_info.state);
          sessionStorage.setItem(
            'state_errorType',
            JSON.stringify({ type: 'OutOfNetwork' })
          );
          gaEventTracker(`${swa}-No Post Office Found near this address`);
          navigate(
            `/error/${swa}/?swa_xid=${swa_xid}&option_sum=${
              option_sum || '1'
            }&lang_cd=${lang_cd || baseConfig.defaultLanguage}`,
            { state: { type: 'OutOfNetwork' } }
          );
        } else {
          let optInResponse;

          if (enrollment_code === 'null' || enrollment_code === null) {
            optInResponse = await getOptInApplicant(optInPayload, swa, swa_xid);
            if (!validDuration) {
              const serviceResponse = await getServicesConfig(swa);
              sessionStorage.setItem(
                'validDuration',
                serviceResponse.data.identity_providers.usps_ipp.valid_duration
              );
            }
          } else {
            optInResponse = await updateOptInApplicant(
              optInPayload,
              enrollment_code,
              swa,
              swa_xid
            );
          }
          if (response && optInResponse) {
            dispatch(setPostOffices(response.data.postOffices));
            dispatch(setEnrollmentCode(optInResponse.data.enrollmentCode));
            setSteps((prevSteps) => {
              const temp = prevSteps;
              prevSteps.forEach((step) => {
                if (
                  step.id === activeStep.id &&
                  activeStep.id !== steps[steps.length - 1].id
                ) {
                  temp[step.id].disabled = false;
                }
              });
              return temp;
            });
            setActiveStep(steps[activeStep.id]);
            // sendEmailRequest(optInResponse.data.enrollmentCode);
            navigate(
              `/enrollment/${swa}/?swa_xid=${swa_xid}&option_sum=${
                option_sum || '1'
              }&lang_cd=${lang_cd || baseConfig.defaultLanguage}`
            );
          }
        }
      } catch (error) {
        const state_usage_code = sessionStorage.getItem('state_usage_code');
        sessionStorage.clear();

        if (error?.response?.status === 409) {
          sessionStorage.setItem(
            'state_errorType',
            JSON.stringify({ type: 'DuplicateSwaXid' })
          );
          gaEventTracker(
            `${swa}-Duplicate swa-xid, Redirected to DuplicateSwaXid`
          );
          navigate(
            `/error/${swa}/?swa_xid=${swa_xid}&option_sum=${option_sum}&redirect_from=${'usps'}&lang_cd=${
              lang_cd || baseConfig.defaultLanguage
            }`,
            { state: { type: 'DuplicateSwaXid' } }
          );
        } else {
          sessionStorage.setItem('state_usage_code', state_usage_code);
          sessionStorage.setItem(
            'state_errorType',
            JSON.stringify({ type: 'HitaWall' })
          );
          gaEventTracker(
            `${swa}-USPS service unavailable due to internal server error`
          );
          navigate(
            `/error/${swa}/?swa_xid=${swa_xid}&option_sum=${option_sum}&redirect_from=${'usps'}&lang_cd=${
              lang_cd || baseConfig.defaultLanguage
            }`,
            { state: { type: 'HitaWall' } }
          );
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const url = `start/${swa}/?swa_xid=${swa_xid}&lang_cd=${
    lang_cd || baseConfig.defaultLanguage
  }`;

  return (
    <div className="usps-container" id="mainContent">
      <div className="address-search">
        <section id="main-content">
          <h2 className="sub_heading">
            <StepActiveStatus activeStep={activeStep} />{' '}
            <span className="subheading-text">
              <FormattedMessage id="personal_info_heading_text" />
            </span>
          </h2>
          <div className="helper-text">
            <FormattedMessage id="heading_helper_text" />
            <span onClick={scrollToBottom} className="scrollToBottom">
              <FormattedMessage id="heading_helper_text1" />
            </span>
            <FormattedMessage id="heading_helper_text2" />
            <br />
            <div className="helper-text-indent">
              <FormattedMessage id="form_required_info_text" />
              <div className="required-field-quote required-field">*</div>
            </div>
          </div>

          <form
            className="form form-padding"
            onSubmit={handleSubmit(onSearchButtonClick)}
          >
            <FormInput
              errors={errors}
              labelKey={'input_first_name'}
              accessor={'firstName'}
              required
            >
              <input
                id="first-name"
                placeholder={formatMessage({
                  id: 'input_first_name_placeholder',
                })}
                maxLength={255}
                data-test-id="first-name"
                className={errors.firstName ? 'error-field' : ''}
                {...register('firstName', { onChange: handleChange })}
                aria-required="true"
                onClick={() => logMessage('First name input box clicked')}
              />
            </FormInput>
            <FormInput
              errors={errors}
              labelKey={'input_last_name'}
              accessor={'lastName'}
              required
            >
              <input
                id="last-name"
                placeholder={formatMessage({
                  id: 'input_last_name_placeholder',
                })}
                maxLength={255}
                className={errors.lastName ? 'error-field' : ''}
                data-test-id="last-name"
                {...register('lastName', { onChange: handleChange })}
                aria-required="true"
                onClick={() => logMessage('Last name input box clicked')}
              />
            </FormInput>

            <FormInput
              errors={errors}
              labelKey={'input_email'}
              accessor={'email'}
              required
            >
              <input
                id="email"
                placeholder={formatMessage({
                  id: 'input_email_placeholder',
                })}
                data-test-id="email"
                className={errors.email ? 'error-field' : ''}
                {...register('email')}
                onBlur={() => {
                  trigger('email');
                }}
                aria-required="true"
                onClick={() => logMessage('Email input box clicked')}
              />
            </FormInput>

            <FormInput
              errors={errors}
              labelKey={'input_street_address_1'}
              accessor={'streetAddress1'}
              required
            >
              <input
                id="street-address-1"
                placeholder={formatMessage({
                  id: 'input_street_address_1_placeholder',
                })}
                maxLength={255}
                data-test-id="street-address-1"
                className={errors.streetAddress1 ? 'error-field' : ''}
                {...register('streetAddress1', { onChange: handleChange })}
                aria-required="true"
                onClick={() => logMessage('Street address1 input box clicked')}
              />
            </FormInput>
            <FormInput
              errors={errors}
              labelKey={'input_street_address_2'}
              accessor={'streetAddress2'}
            >
              <input
                id="street-address-2"
                placeholder={formatMessage({
                  id: 'input_street_address_2_placeholder',
                })}
                maxLength={255}
                data-test-id="street-address-2"
                className={errors.streetAddress2 ? 'error-field' : ''}
                {...register('streetAddress2', { onChange: handleChange })}
                onClick={() => logMessage('Street address2 input box clicked')}
              />
            </FormInput>

            <FormInput
              errors={errors}
              labelKey={'input_city_name_1'}
              accessor={'city'}
              required
              aria-required="true"
            >
              <input
                id="city"
                placeholder={formatMessage({
                  id: 'input_city_name_placeholder',
                })}
                maxLength={50}
                data-test-id="city"
                className={errors.city ? 'error-field' : ''}
                {...register('city', { onChange: handleChange })}
                onClick={() => logMessage('City input box clicked')}
              />
            </FormInput>

            <FormInput
              errors={errors}
              labelKey={'input_state_name'}
              accessor={'state'}
              required
              aria-required="true"
            >
              <select
                id="state"
                aria-label="Select State"
                placeholder={formatMessage({
                  id: 'input_state_name_placeholder',
                })}
                className={
                  errors.state ? 'state-dropdown error-field' : 'state-dropdown'
                }
                data-test-id="state"
                style={{
                  color: stateValue === 0 ? 'gray' : 'black',
                }}
                {...register('state', { onChange: handleChange })}
                onClick={() => logMessage('State dropdown clicked')}
              >
                <option disabled key="0" value="0" style={{ color: 'gray' }}>
                  <FormattedMessage id="Select_State_DropdownText" />
                </option>
                {StateList.map((state) => {
                  return (
                    <option
                      id={state.abbreviation}
                      key={state.abbreviation}
                      data-test-id={state.abbreviation}
                    >
                      {state.abbreviation}
                    </option>
                  );
                })}
              </select>
            </FormInput>

            <FormInput
              errors={errors}
              labelKey={'input_zipcode'}
              accessor={'zipcode'}
              required
            >
              <input
                id="zipcode"
                placeholder={formatMessage({
                  id: 'input_zipcode_placehoder',
                })}
                className={errors.zipcode ? 'error-field' : ''}
                data-test-id="zipcode"
                {...register('zipcode', { onChange: handleChange })}
                aria-required="true"
                onClick={() => logMessage('Zipcode input box clicked')}
              />
            </FormInput>
            <div className="footer-actions">
              <Button
                type="primary"
                id="search-button"
                data-testid="search-button"
                disabled={!isValid || !isTokenLoaded}
                onClick={() => logMessage('Form submit button clicked')}
              >
                <FormattedMessage id="form_submit_button" />
              </Button>
              {(swa in states
                ? states[swa]?.chooseDifferentVerificationButton_USPS
                : true) && (
                <Button
                  type="secondary"
                  onClick={() => {
                    logMessage(
                      'Choose different verification button clicked on USPS search page'
                    );
                    changeDifferentMethodButton(
                      url,
                      swa,
                      gaEventTracker,
                      'Choose different button in search page'
                    );
                  }}
                  data-testId="enrollment-diff-verification"
                >
                  <FormattedMessage id="different_verification_method_link" />
                </Button>
              )}
            </div>
          </form>
          <USPSDescription swa={swa} />
        </section>
        <br />
        <br />
        <div className="scroll-top-height">
          <a className="scroll-top-color" onClick={scrollToTop} href="">
            {' '}
            <FormattedMessage
              id="scroll_to_top_text"
              values={{ ...richText }}
            />{' '}
          </a>
        </div>
        <br />
      </div>
      <FraudPreventionModal
        showFraudPreventionModal={showFraudPreventionModal}
        setShowFraudPreventionModal={setShowFraudPreventionModal}
        page={'usps'}
      />
    </div>
  );
}

export default AddressSearch;
