import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useViewport from '../hooks/useViewPort';
import './Stepper.scss';
import { getUrlParams } from '../../utils/utils';

function Stepper(props) {
  const { steps, activeStep, setActiveStep, setSteps } = props;
  const breakpoint = 768;

  const { width } = useViewport();
  const params = getUrlParams();
  const navigate = useNavigate();
  const myBarcode = sessionStorage.getItem('barcode');
  const swa = location.pathname.split('/')[2] || params?.swa;

  const handlePreviousBtnClick = () => {
    if (activeStep.id !== 1) {
      setActiveStep(steps[0]);
      navigate(
        `/search/${swa}/?swa_xid=${
          params.swa_xid
        }&enrollment_code=${myBarcode}&lang_cd=${params.lang_cd || 'en'}`
      );
    }
  };

  const handleStepClick = (selectedStep) => {
    if (!selectedStep.disabled) {
      setSteps((prevSteps) => {
        const temp = prevSteps;
        let disableSteps = false;
        prevSteps.forEach((step) => {
          if (step.id === activeStep.id) {
            disableSteps = true;
          }
          if (disableSteps && step.id !== 1) {
            temp[step.id - 1].disabled = true;
          }
        });
        return temp;
      });
      setActiveStep(selectedStep);

      if (selectedStep.id === 1) {
        navigate(
          `/search/${swa}/?swa_xid=${
            params.swa_xid
          }&enrollment_code=${myBarcode}&lang_cd=${params.lang_cd || 'en'}`
        );
      } else if (selectedStep.id === 2) {
        navigate(
          `/enrollment?swa=${params.swa}&swa_xid=${params.swa_xid}&lang_cd=${
            params.lang_cd || 'en'
          }`
        );
      }
    }
  };
  return width < breakpoint ? (
    <MobileStepper
      activeStep={activeStep}
      handlePreviousBtnClick={handlePreviousBtnClick}
      steps={steps}
      handleStepClick={handleStepClick}
    />
  ) : (
    <DesktopStepper
      activeStep={activeStep}
      steps={steps}
      handleStepClick={handleStepClick}
    />
  );
}

const getStepperClass = (step, steps, activeStep, index) => {
  let className = '';
  if (step.id === steps[activeStep.id - 1].id) {
    className = 'dot-active';
  } else if (activeStep.id > index + 1) {
    className = 'dot-completed';
  }
  return className;
};

const getStepperProgressClass = (step, steps, activeStep, index) => {
  let className = '';
  if (step.id === steps[activeStep.id - 1].id) {
    className = 'active';
  } else if (activeStep.id > index + 1) {
    className = 'completed';
  }
  return className;
};

const getStepperLableClass = (step, steps, activeStep, index) => {
  let className = '';
  if (step.id === steps[activeStep.id - 1].id) {
    className = 'active-label';
  } else if (activeStep.id > index + 1) {
    className = 'completed-label';
  }
  return className;
};

function MobileStepper(props) {
  const { activeStep, handlePreviousBtnClick, handleStepClick, steps } = props;

  return (
    <div className="mobile-stepper-container" data-testid="mobile-stepper">
      <div className="mobile-stepper-navigations">
        {activeStep.id > 1 && (
          <button
            data-testId="back-navigation-btn"
            className="back-navigation-btn"
            onClick={() => handlePreviousBtnClick()}
          >
            <ArrowBackIcon />
            <span>
              <FormattedMessage id="back_text" />
            </span>
          </button>
        )}

        <div className="dots-container">
          {steps.map((step, index) => {
            return (
              <div
                key={`${step.label}-${index}`}
                className={`step-dot ${getStepperClass(
                  step,
                  steps,
                  activeStep,
                  index
                )}`}
                onClick={() => handleStepClick(step)}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function DesktopStepper(props) {
  const { activeStep, steps, handleStepClick } = props;

  return (
    <div className="desktop-stepper-container">
      <div className="steps step-index">
        {steps.map((step, index) => {
          return (
            <div
              data-testId={`step-${index}`}
              key={`${step.label}-${index}`}
              className={index === 1 ? 'step-wrapper-disbaled' : 'step-wrapper'}
              onClick={() => handleStepClick(step)}
            >
              <div
                className={`step-progress ${getStepperProgressClass(
                  step,
                  steps,
                  activeStep,
                  index
                )}`}
              ></div>
              <p
                className={`step-label ${getStepperLableClass(
                  step,
                  steps,
                  activeStep,
                  index
                )}`}
              >
                <FormattedMessage
                  id={step.label}
                  values={{ sup: (data) => <sup>{data}</sup> }}
                />
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Stepper;
