import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { useAnalyticsEventTracker } from '../utils/utils';
import './Footer.scss';

const Footer = () => {
  const gaEventTracker = useAnalyticsEventTracker('Footer');
  const [about, setAbout] = useState('none');
  const [federal, setFederal] = useState('none');
  const [labor, setLabor] = useState('none');
  let swa = useParams().state;

  if (!swa) {
    swa = location.pathname.split('/')[2];
  }

  return (
    <footer className="footer-container usa-footer usa-footer--big">
      <div className="dol-footer-nav">
        <div className="grid-container"></div>
      </div>
      <div className="usa-footer__primary-section">
        <div className="grid-container">
          <div className="grid-row">
            <div className="tablet:grid-col-4">
              <section className="usa-footer__primary-content usa-footer__primary-content--collapsible border-top-0">
                <div className="footer-logo display-flex tablet:display-block dol-footer-seal margin-y-2">
                  <div className="dol-seal margin-right-2 tablet:margin-bottom-2">
                    <a
                      href="https://www.dol.gov/"
                      onClick={() =>
                        gaEventTracker(`${swa}-Clicked on Home Logo`)
                      }
                      title="DOL seal"
                      data-testid="footer-home-id"
                    >
                      <img
                        className="width-15"
                        src="https://www.dol.gov/themes/opa_theme/img/DOL-MasterLogo_BLUE.svg"
                        alt="United States Department of Labor"
                      />
                    </a>
                  </div>
                  <div className="footer-seal-block">
                    <div className="dol-name-and-seal">
                      <span>
                        <FormattedMessage id="footer_dol_name_and_seal" />
                      </span>
                    </div>
                    <div id="block-footeraddress">
                      <div>
                        <div className="address font-sans-2xs line-height-sans-4">
                          <div className="dol-phone-web">
                            <div>
                              <a
                                className="usa-link"
                                onClick={() =>
                                  gaEventTracker(
                                    `${swa}-Clicked on www.dol.gov`
                                  )
                                }
                                href="https://www.dol.gov/"
                                data-testid="footer-dol-link"
                              >
                                www.dol.gov
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="footer-collapsable tablet:grid-col-8 margin-top-2">
              <div className="usa-accordion" aria-multiselectable="true">
                <div className="mobile-lg:grid-col-12">
                  <h4 className="usa-accordion__heading">
                    <button
                      className="usa-accordion__button TEXT-UPPER"
                      aria-expanded={federal === 'block'}
                      aria-controls="footer-nav-federal"
                      role="div"
                      aria-label="Federal Government"
                      onClick={() => {
                        setFederal(federal === 'block' ? 'none' : 'block');
                      }}
                      data-testid="federalFooter"
                    >
                      <FormattedMessage id="footer_nav_federal" />
                    </button>
                  </h4>
                  <div
                    id="footer-nav-federal"
                    className="usa-accordion__content usa-prose"
                    style={{ display: federal }}
                  >
                    <ul
                      className="usa-list usa-list--unstyled"
                      style={{ textAlign: 'left' }}
                    >
                      <li
                        className="usa-footer__secondary-link"
                        data-testid="federalFooterList1"
                      >
                        <a
                          href="https://www.whitehouse.gov/"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Clicked on Federal Government > White House`
                            )
                          }
                          data-testid="federalFooterlistLink1"
                        >
                          <FormattedMessage id="footer_secondary_link_white_house" />
                        </a>
                      </li>

                      <li
                        className="usa-footer__secondary-link"
                        data-testid="federalFooterList2"
                      >
                        <a
                          href="https://www.dol.gov/coronavirus"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Clicked on Federal Government > Coronavirus Resources`
                            )
                          }
                          data-testid="federalFooterlistLink2"
                        >
                          <FormattedMessage id="footer_secondary_link_corona_virus" />
                        </a>
                      </li>
                      <li
                        className="usa-footer__secondary-link"
                        data-testid="federalFooterList3"
                      >
                        <a
                          href="https://www.dol.gov/general/stormrecovery"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Clicked on Federal Government > Severe Storm and Flood Recovery Assistance`
                            )
                          }
                          data-testid="federalFooterlistLink3"
                        >
                          <FormattedMessage id="footer_secondary_link_storm_recovery" />
                        </a>
                      </li>
                      <li
                        className="usa-footer__secondary-link"
                        data-testid="federalFooterList4"
                      >
                        <a
                          href="https://www.dol.gov/general/disasterrecovery"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Clicked on Federal Government > Disaster Recovery Assistance`
                            )
                          }
                          data-testid="federalFooterlistLink4"
                        >
                          <FormattedMessage id="footer_secondary_link_disaster_recovery" />
                        </a>
                      </li>
                      <li
                        className="usa-footer__secondary-link"
                        data-testid="federalFooterList5"
                      >
                        <a
                          href="https://www.disasterassistance.gov/"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Clicked on Federal Government > DisasterAssistance.gov`
                            )
                          }
                          data-testid="federalFooterlistLink5"
                        >
                          DisasterAssistance.gov
                        </a>
                      </li>
                      <li
                        className="usa-footer__secondary-link"
                        data-testid="federalFooterList6"
                      >
                        <a
                          href="https://www.usa.gov/"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Clicked on Federal Government > USA.gov`
                            )
                          }
                          data-testid="federalFooterlistLink6"
                        >
                          USA.gov
                        </a>
                      </li>
                      <li
                        className="usa-footer__secondary-link"
                        data-testid="federalFooterList7"
                      >
                        <a
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Clicked on Federal Government > No Fear Act Data`
                            )
                          }
                          href="https://www.dol.gov/agencies/oasam/centers-offices/civil-rights-center/resports/notification-and-federal-employee-antidiscrimination-retaliation-act-of-2002"
                          data-testid="federalFooterlistLink7"
                        >
                          <FormattedMessage id="footer_secondary_link_no_fear_act" />
                        </a>
                      </li>
                      <li
                        className="usa-footer__secondary-link"
                        data-testid="federalFooterList8"
                      >
                        <a
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Clicked on Federal Government > U.S. Office of Special Counsel`
                            )
                          }
                          href="https://osc.gov/"
                          data-testid="federalFooterlistLink8"
                        >
                          <FormattedMessage id="footer_secondary_link_special_counsel" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mobile-lg:grid-col-12">
                  <h4 className="usa-accordion__heading">
                    <button
                      className="usa-accordion__button TEXT-UPPER"
                      aria-expanded={labor === 'block'}
                      aria-controls="footer-nav-labor"
                      role="div"
                      aria-label="Labor Department"
                      onClick={() => {
                        setLabor(labor === 'block' ? 'none' : 'block');
                      }}
                      data-testid="laborFooter"
                    >
                      <FormattedMessage id="footer_nav_labor" />
                    </button>
                  </h4>
                  <div
                    id="footer-nav-labor"
                    className="usa-accordion__content usa-prose"
                    style={{ display: labor }}
                  >
                    <ul
                      className="usa-list usa-list--unstyled"
                      style={{ textAlign: 'left' }}
                    >
                      <li
                        className="usa-footer__secondary-link"
                        data-testid="laborFooterList1"
                      >
                        <a
                          href="https://www.dol.gov/general/aboutdol"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Labor Department > About DOL`
                            )
                          }
                          data-testid="laborFooterlistLink1"
                        >
                          <FormattedMessage id="footer_secondary_link_about_dol" />
                        </a>
                      </li>

                      <li
                        className="usa-footer__secondary-link"
                        data-testid="laborFooterList2"
                      >
                        <a
                          href="https://www.dol.gov/guidance"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Labor Department > Guidance Search`
                            )
                          }
                          data-testid="laborFooterlistLink2"
                        >
                          <FormattedMessage id="footer_secondary_link_guidance_search" />
                        </a>
                      </li>

                      <li
                        className="usa-footer__secondary-link"
                        data-testid="laborFooterList3"
                      >
                        <a
                          href="https://www.dol.gov/general/topic/spanish-speakingtopic"
                          onClick={() =>
                            gaEventTracker(`${swa}-Labor Department > Espanol`)
                          }
                          data-testid="laborFooterlistLink3"
                        >
                          Espa&#241;ol
                        </a>
                      </li>

                      <li
                        className="usa-footer__secondary-link"
                        data-testid="laborFooterList4"
                      >
                        <a
                          href="https://www.oig.dol.gov/"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Labor Department > Office of Inspector General`
                            )
                          }
                          data-testid="laborFooterlistLink4"
                        >
                          <FormattedMessage id="footer_secondary_link_inspector_general" />
                        </a>
                      </li>

                      <li
                        className="usa-footer__secondary-link"
                        data-testid="laborFooterList5"
                      >
                        <a
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Labor Department > Subscribe to the DOL Newsletter`
                            )
                          }
                          data-testid="laborFooterlistLink5"
                          href="https://public.govdelivery.com/accounts/USDOL/subscriber/new?topic_id=USDOL_167"
                        >
                          <FormattedMessage id="footer_secondary_link_dol_newsletter" />
                        </a>
                      </li>

                      <li
                        className="usa-footer__secondary-link"
                        data-testid="laborFooterList6"
                      >
                        <a
                          href="https://www.dol.gov/newsroom/newsletter"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Labor Department > Read the DOL Newsletter`
                            )
                          }
                          data-testid="laborFooterlistLink6"
                        >
                          <FormattedMessage id="footer_secondary_link_read_newsletter" />
                        </a>
                      </li>

                      <li
                        className="usa-footer__secondary-link"
                        data-testid="laborFooterList7"
                      >
                        <a
                          href="https://www.dol.gov/general/easl"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Labor Department > Emergency Accountability Status Link`
                            )
                          }
                          data-testid="laborFooterlistLink7"
                        >
                          <FormattedMessage id="footer_secondary_link_accountability_status" />
                        </a>
                      </li>

                      <li
                        className="usa-footer__secondary-link"
                        data-testid="laborFooterList8"
                      >
                        <a
                          href="https://www.dol.gov/general/siteindex"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-Labor Department > A to Z Index`
                            )
                          }
                          data-testid="laborFooterlistLink8"
                        >
                          <FormattedMessage id="footer_secondary_link_a_to_z_index" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mobile-lg:grid-col-12">
                  <h4 className="usa-accordion__heading">
                    <button
                      className="usa-accordion__button TEXT-UPPER"
                      aria-expanded={about === 'block'}
                      aria-controls="footer-nav-about"
                      role="div"
                      aria-label="About the Site"
                      onClick={() => {
                        setAbout(about === 'block' ? 'none' : 'block');
                      }}
                      data-testid="aboutFooter"
                    >
                      <FormattedMessage id="footer_nav_about" />
                    </button>
                  </h4>
                  <div
                    id="footer-nav-about"
                    className="usa-accordion__content usa-prose"
                    style={{ display: about }}
                  >
                    <ul
                      className="usa-list usa-list--unstyled"
                      style={{ textAlign: 'left' }}
                    >
                      <li
                        className="usa-footer__secondary-link"
                        data-testid="aboutFooterList1"
                      >
                        <a
                          href="https://www.dol.gov/general/foia"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-About the Site > Freedom of Information Act`
                            )
                          }
                          data-testid="aboutFooterlistLink1"
                        >
                          <FormattedMessage id="footer_secondary_link_information_act" />
                        </a>
                      </li>
                      <li
                        className="usa-footer__secondary-link"
                        data-testid="aboutFooterList2"
                      >
                        <a
                          href="https://www.dol.gov/general/disclaim"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-About the Site > Disclaimers`
                            )
                          }
                          data-testid="aboutFooterlistLink2"
                        >
                          <FormattedMessage id="footer_secondary_link_disclaimers" />
                        </a>
                      </li>

                      <li
                        className="usa-footer__secondary-link"
                        data-testid="aboutFooterList3"
                      >
                        <a
                          href="https://www.dol.gov/general/aboutdol/accessibility"
                          onClick={() =>
                            gaEventTracker(
                              `${swa}-About the Site > Accessibility Statement`
                            )
                          }
                          data-testid="aboutFooterlistLink3"
                        >
                          <FormattedMessage id="footer_secondary_link_accessibility_statement" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="usa-footer__secondary-section">
        <div className="grid-container">
          <div className="grid-row footer-secondary">
            <div className="desktop:grid-col-6 tablet:grid-col-6 footer-secondary-p1">
              <div className="grid-row">
                <div className="dol-connect font-sans-2xs tablet-lg:grid-col-4 desktop:grid-col-3">
                  <FormattedMessage id="footer_connect_font" />
                </div>
                <div className="grid-row grid-gap-1">
                  <div className="grid-col-auto">
                    <a
                      className="usa-social-link usa-social-link--facebook circle-6"
                      href="https://www.facebook.com/departmentoflabor"
                      title="Department of Labor Facebook"
                      onClick={() =>
                        gaEventTracker(`${swa}-Social Link > Facebook`)
                      }
                      data-testid="facebookLink"
                    >
                      <span style={{ fontSize: '10px' }}>Facebook</span>
                    </a>
                  </div>

                  <div className="grid-col-auto">
                    <a
                      className="usa-social-link usa-social-link--twitter circle-6"
                      href="https://twitter.com/USDOL"
                      title="Department of Labor Twitter"
                      onClick={() =>
                        gaEventTracker(`${swa}-Social Link > Twitter`)
                      }
                      data-testid="twitterLink"
                    >
                      <span style={{ fontSize: '10px' }}>Twitter</span>
                    </a>
                  </div>

                  <div className="grid-col-auto">
                    <a
                      className="usa-social-link usa-social-link--youtube circle-6"
                      href="https://www.youtube.com/user/USDepartmentofLabor"
                      title="Department of Labor YouTube"
                      onClick={() =>
                        gaEventTracker(`${swa}-Social Link > Youtube`)
                      }
                      data-testid="youtubeLink"
                    >
                      <span style={{ fontSize: '10px' }}>YouTube</span>
                    </a>
                  </div>

                  <div className="grid-col-auto">
                    <a
                      className="usa-social-link usa-social-link--instagram circle-6"
                      href="https://www.instagram.com/USDOL/"
                      title="Department of Labor Instagram"
                      onClick={() =>
                        gaEventTracker(`${swa}-Social Link > Instagram`)
                      }
                      data-testid="instagramLink"
                    >
                      <span style={{ fontSize: '10px' }}>Instagram</span>
                    </a>
                  </div>

                  <div className="grid-col-auto">
                    <a
                      className="usa-social-link usa-social-link--linkedin circle-6"
                      href="https://www.linkedin.com/company/u-s-department-of-labor/"
                      title="Department of Labor LinkedIn"
                      onClick={() =>
                        gaEventTracker(`${swa}-Social Link > LinkedIn`)
                      }
                      data-testid="linkedinLink"
                    >
                      <span style={{ fontSize: '10px' }}>LinkedIn</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="desktop:grid-col-6 tablet:grid-col-6 footer-secondary-p2">
              <div className="dol-util-links">
                <div className="font-sans-3xs">
                  <ul className="usa-list usa-list--unstyled">
                    <li>
                      <a
                        href="https://www.dol.gov/general/findit"
                        className="usa-link"
                        onClick={() => gaEventTracker(`${swa}-Sitemap`)}
                        data-testid="sitemapLink"
                      >
                        <FormattedMessage id="footer_findit" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.dol.gov/general/aboutdol/website-policies"
                        className="usa-link"
                        onClick={() =>
                          gaEventTracker(`${swa}-Important Website Notices`)
                        }
                        data-testid="websiteNoticeLink"
                      >
                        <FormattedMessage id="footer_website_policies" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.dol.gov/general/privacynotice"
                        className="usa-link"
                        // onClick={() =>
                        //   gaEventTracker('Privacy & Security Statement')
                        // }
                      >
                        <FormattedMessage id="footer_privacy_notice" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
