import { IntlProvider } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { Routes, Route, useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { LOCALE } from './i18n/locales';
import { messages } from './i18n/messages';
import UICP from './components/uicp/UICP';
import Confirmation from './components/login/Confirmation';
import Workforce from './components/workforce/Workforce';
import Logout from './components/logout/Logout';
import Header from './pages/Header';
import Footer from './pages/Footer';
import Error from './components/error/Error';
import AddressSearch from './components/usps/address-search/AddressSearch';
import './App.scss';
import Enrollment from './components/usps/enrollment/Enrollment';
import states from './i18n/states';
import {
  eventTarget,
  getUrlParams,
  validateSwaXid,
  useAnalyticsEventTracker,
} from './utils/utils';
import baseConfig from './utils/config';
import Stepper from './common/Stepper/Stepper';
import PageTitle from './common/PageTitle';

const STEPS = [
  {
    id: 1,
    label: 'personal_info_heading_text',
    disabled: false,
  },
  {
    id: 2,
    label: 'post_office_visit_text',
    disabled: true,
  },
];

const App = () => {
  const location = useLocation();
  const params = getUrlParams();
  const [loading, setLoading] = useState(false);
  const [showStepper, setShowStepper] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker('Application');
  const navigate = useNavigate();
  const uniqueSwaPathNames = ['search', 'enrollment'];
  const [activeStep, setActiveStep] = useState(STEPS[0]);
  const [steps, setSteps] = useState(STEPS);

  if (params['lang_cd']) {
    document.documentElement.setAttribute(
      'lang',
      params['lang_cd'] === 'es' ? LOCALE.SPANISH : LOCALE.ENGLISH
    );
  }
  const urlPathname = location.pathname.split('/')[1];

  const swa = location.pathname.split('/')[2] || params?.swa;
  const lang_cd_val =
    params?.lang_cd !== LOCALE.ENGLISH && params?.lang_cd !== LOCALE.SPANISH
      ? LOCALE.ENGLISH
      : params?.lang_cd;
  const stateMessages = states[swa] || {};
  const localWiseStateMessages = stateMessages[lang_cd_val] || {};

  const intLMessages = {
    ...messages[lang_cd_val],
    ...stateMessages,
    ...localWiseStateMessages,
  };

  const validateUniqueSwa =
    uniqueSwaPathNames.indexOf(urlPathname) >= 0 ? true : false;

  useEffect(() => {
    const path = location.pathname;

    if (
      path === `/search/${swa}/` ||
      path === '/search' ||
      path === '/enrollment' ||
      path === `/enrollment/${swa}/`
    ) {
      if (sessionStorage.getItem('state_usage_code') === '16') {
        setShowStepper(false);
      } else {
        setShowStepper(true);
      }
    } else {
      setShowStepper(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const currentPath = location.pathname + location.search;

    const handlePageShow = () => {
      const storedLocalLanguage = localStorage.getItem('language');
      if (storedLocalLanguage) {
        const params = new URLSearchParams(location.search);
        const storedLanguage = storedLocalLanguage || params.get('lang_cd');
        const urlLang = params.get('lang_cd');
        if (storedLanguage !== urlLang) {
          params.set('lang_cd', storedLanguage);
          navigate(`${location.pathname}?${params.toString()}`, {
            replace: true,
          });
        }
      }
    };

    handlePageShow();

    if (currentPath.includes('/error/')) {
      const previousPage = localStorage.getItem('previousValidPage');

      window.addEventListener('popstate', () => {
        if (previousPage) {
          navigate(previousPage, { replace: true });
        }
      });
    } else if (currentPath.includes('//')) {
      localStorage.setItem(
        'previousValidPage',
        localStorage.getItem('previousValidPage')
      );
    } else {
      localStorage.setItem('previousValidPage', currentPath);
    }

    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, [location, navigate]);

  useEffect(() => {
    const swa_xid = params?.swa_xid;
    const error_type = params?.error_type;
    const ial_type = params?.ial;
    const lang_cd = lang_cd_val;
    eventTarget.addEventListener('showLoader', () => {
      setLoading(true);
    });
    eventTarget.addEventListener('hideLoader', () => {
      setLoading(false);
    });
    if (error_type === 'login_unsuccessful' && !ial_type) {
      sessionStorage.clear();
      sessionStorage.setItem(
        'state_errorType',
        JSON.stringify({ type: 'LoginUnsuccessful' })
      );
      gaEventTracker(`${swa}-LoginUnsuccessful`);
      navigate(
        `/error/?error_type=login_unsuccessful&lang_cd=${
          lang_cd || baseConfig.defaultLanguage
        }`,
        {
          state: { type: 'LoginUnsuccessful' },
        }
      );
    }

    if (!swa_xid && !error_type) {
      sessionStorage.clear();
      sessionStorage.setItem(
        'state_errorType',
        JSON.stringify({ type: 'WebAddressIncomplete' })
      );
      gaEventTracker(`${swa}-swa_xid is null`);
      navigate(
        `/error/${swa}/?lang_cd=${lang_cd || baseConfig.defaultLanguage}`,
        {
          state: { type: 'WebAddressIncomplete' },
        }
      );
    }
    if (!sessionStorage.transactionId) {
      sessionStorage.setItem('transactionId', uuidv4());
    }
    if (!error_type) {
      validateSwaXid(swa, swa_xid, validateUniqueSwa).catch((reason) => {
        if (reason === 'HitaWall') {
          sessionStorage.clear();
          sessionStorage.setItem(
            'state_errorType',
            JSON.stringify({ type: 'Error_500' })
          );
          gaEventTracker(`${swa}-Identity verification service unavailable`);
          navigate(
            `/error/${swa}/?swa_xid=${swa_xid}&lang_cd=${
              lang_cd || baseConfig.defaultLanguage
            }`,
            {
              state: { type: 'Error_500' },
            }
          );
        } else {
          sessionStorage.clear();
          sessionStorage.setItem('auth_modal', 'true');
          sessionStorage.setItem(
            'state_errorType',
            JSON.stringify({ type: 'WebAddressIncomplete' })
          );
          gaEventTracker(
            `${swa}-Redirected to Invalid url page as status code is 4xx`
          );
          navigate(
            `/error/${swa}/?lang_cd=${lang_cd || baseConfig.defaultLanguage}`,
            {
              state: { type: 'WebAddressIncomplete' },
            }
          );
        }
      });
    }

    const languageDropdownElement = document.querySelector(
      '#demo-simple-select'
    );
    if (languageDropdownElement) {
      languageDropdownElement.tabIndex = -1;
    }
  }, []);

  return (
    <IntlProvider
      messages={intLMessages}
      locale={lang_cd_val}
      defaultLocale={LOCALE.ENGLISH}
    >
      <div className="App">
        {urlPathname !== 'identity' && <Header />}
        <PageTitle path={urlPathname} />
        {loading && (
          <div className="loading-wrapper">
            <div className="loader"></div>
          </div>
        )}
        {showStepper && (
          <Stepper
            steps={steps}
            setSteps={setSteps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        )}
        <Routes>
          <Route path="/workforce" element={<Workforce />} />
          <Route
            path="/start/:state"
            element={<UICP setLoading={setLoading} />}
          />
          <Route path="/identity/:state" element={<Confirmation />} />
          <Route path="/logout/:state" element={<Logout />} />
          <Route path="/" element="" />
          <Route path="/workforce/:state" element={<Workforce />} />
          <Route path="/error/:state" element={<Error />} />
          <Route
            path="/search/:state"
            element={
              <AddressSearch
                setLoading={setLoading}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                setSteps={setSteps}
                steps={steps}
              />
            }
          />
          <Route
            path="/enrollment/:state"
            element={
              <Enrollment
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                steps={steps}
                setLoading={setLoading}
                stepper={
                  <Stepper step={activeStep} setShowStepper={setShowStepper} />
                }
              />
            }
          />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </div>
    </IntlProvider>
  );
};

export default App;
