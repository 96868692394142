export default {
  AL: {
    name: 'Alabama',
    stateCode: 'AL',
    chooseDifferentVerificationButton_USPS: true,
    showVerificationButton: true,
    showErrorPageButton_HitaWall: true,
    showLanguageDropdown: true,
    showSameStateContent: true,
    // Fraud Modal:
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Alabama Department of Labor.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Alabama Department of Labor, <b>please file a report</b> of potential unemployment identity fraud with Alabama Department of Labor by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Alabama Department of Labor through USPS.',
    // Choice Screen:
    uicp_identity_footer_text:
      'The method you choose to verify your identity will not affect your ability to receive unemployment benefits. State staff will process your application following all applicable laws and regulations.',
    // State Content:
    state_content_para_header: `Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}{br}`,
    state_content_para_info1:
      '<b>Alabama Department of Labor</b>{br}1-866-234-5382{br}Monday-Friday 7:00 a.m.-5:00 p.m. Central Standard Time{br}<al_link>www.labor.alabama.gov</al_link>{br}{br}',
    state_content_para_info2:
      '<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
    state_more_information_header: 'More Information',
    state_more_information_para:
      '<li><al_link1>AL Benefit Rights and Responsibilities Booklet</al_link1></li><li><al_link2>Register for work at AlabamaWorks.gov</al_link2></li>{br}',
    // Login Confirmation:
    confirmation_next_step_text: 'Reminder',
    confirmation_state_title: 'Alabama Department of Labor',
    confirmation_steps_content_line_first: `1. If you haven't yet registered for work online using AlabamaWorks, please register at <al_link2>AlabamaWorks.gov</al_link2>{br}`,
    confirmation_steps_content_line_second:
      '2. Visit your local Alabama Career Center for further assistance <al_link3>Alabama Career Center Locations</al_link3>',
    // Login Unsuccessful:
    login_gov_help_txt: ' ',
    login_link: ' ',
    error_invalid_login_para3: ' ',
    // Logout Page:
    logout_intro: ' You may close this window.',
    logout_next_step_text: 'Reminder',
    uicp_more_info_text: ' ',
    // usps-description component:
    required_docs_heading: ' We use the address on your ID for verification.',
    online_radiobutton_doc_list_text_second: '<li>State ID card</li>',
    online_radiobutton_doc_list_text_first: '<li>State driver’s license</li>',
    // Error Pages:
    // Invalid url-No Keys needed with shared component:
    // HitaWall:
    hitawall_para2:
      '{br}Please try again later or choose a different verification method.{br}Thank you for your patience and understanding.',
    // OutOfNetwork(No Post office)-No Keys needed with shared component:
    // 404:
    error404_para1:
      '{br}We can’t find the page you are looking for. Please check your URL or return to the ',
    // 500:
    error_500_para2:
      'Please try again later. {br}Thank you for your patience and understanding.',
    es: {
      // Fraud Modal:
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Trabajo de Alabama.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Departamento de Trabajo de Alabama, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Departamento de Trabajo de Alabama siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Trabajo de Alabama a través de USPS.',
      // Choice Screen:
      uicp_identity_footer_text:
        'El método escogido para verificar su identidad no afectará sus posibilidades de recibir beneficios de desempleo. El personal estatal procesará su solicitud siguiendo todas las leyes y reglamentos aplicables.',
      unemployment_question2: ' ',
      online_radiobutton_label_text: 'En línea - Login.gov',
      online_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      online_radiobutton_doc_list_text_first:
        '<li>Documento de identidad estadual</li>',
      online_radiobutton_doc_list_text_second: ' ',
      // State Content:
      state_content_para_header: `A continuación le indicamos cómo comunicarse con nosotros si tiene preguntas sobre los beneficios de desempleo o necesita ayuda para verificar su identidad:{br}{br}`,
      state_content_para_info1:
        '<b>Departamento de Trabajo de Alabama</b>{br}1-866-234-5382{br}Lunes a viernes de 7:00 a.m. a 5:00 p.m. Hora estándar central{br}<al_link>www.labor.alabama.gov</al_link>{br}{br}',
      state_content_para_info2:
        '<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
      state_more_information_header: 'Más información',
      state_more_information_para:
        '<li><al_link1>Folleto de derechos y responsabilidades de los beneficios de AL</al_link1></li><li><al_link2>Regístrese para trabajar en AlabamaWorks.gov</al_link2></li>{br}',
      // Login Confirmation:
      confirmation_next_step_text: 'Recordatorio',
      confirmation_state_title: 'Departamento de Trabajo de Alabama',
      confirmation_steps_content_line_first: `1. Si aún no se ha registrado para trabajar en línea usando AlabamaWorks, regístrese en <al_link2>AlabamaWorks.gov</al_link2>{br}`,
      confirmation_steps_content_line_second:
        '2. Visite su Alabama Career Center local para obtener más ayuda <al_link3>Ubicaciones de los centros profesionales de Alabama</al_link3>',
      // Login Unsuccessful:
      login_gov_help_txt: ' ',
      login_link: ' ',
      error_invalid_login_para3: ' ',
      more_info_text1: ' ',
      // Logout Page:
      logout_intro: 'Puede cerrar esta ventana.',
      logout_next_step_text: 'Recordatorio',
      uicp_more_info_text: ' ',
      // usps-search page:
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      // usps-description component:
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      postOffice_text: 'Post Office<sup>TM</sup>',
      // Error Pages:
      // Invalid url-No Keys needed with shared component:
      // HitaWall:
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde o elija un método de verificación diferente.{br}Gracias por su paciencia y comprensión.',
      // OutOfNetwork(No Post office):
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_no_participate_para1:
        'No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_no_participate_para2: ' ',
      out_of_network_para1:
        'Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.{br}',
      out_of_network_para2: ' ',
      // 404:
      error404_para1:
        '{br}Parece que la URL que ingresó no es válida o ya no existe.  Verifique su URL o regrese a la página de ',
      error404_para2: ' ',
      moreinfo_link1: ' ',
      moreinfo_link2: ' ',
      // 500:
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde. {br}Gracias por su paciencia y comprensión.',
    },
  },
  AK: {
    name: 'Alaska',
    stateCode: 'AK',
    chooseDifferentVerificationButton_USPS: true,
    showVerificationButton: true,
    showErrorPageButton_HitaWall: true,
    showLanguageDropdown: true,
    unemployment_question2:
      'Here’s how to reach <b>Alaska Unemployment Insurance</b> if you have questions about unemployment benefits or other methods to verify your identity: {br}(907) 269-4700 or (907) 465-5552;{br}Monday to Friday from 10:00 a.m. to 3:00 p.m.{br}CLOSED on State Holidays.{br}<ak_link>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link>{br}{br}',
    required_docs_heading: ' We use the address on your ID for verification. ',
    required_docs_para:
      'If you live somewhere else or your ID has no address listed, you will also need proof of address:',
    usps_state_title: 'Alaska Unemployment Insurance',
    usps_state_number: '(907) 269-4700 o (907) 465-5552;{br}',
    usps_state_timing_para:
      'Monday to Friday from 10:00 a.m. to 3:00 p.m.{br}CLOSED on State Holidays.{br}<ak_link>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link>{br}{br}',
    usps_state_email: ' ',
    out_of_network_para2:
      'If you need help verifying your identity for your <b>Alaska Unemployment Insurance claim, (907) 269-4700 or (907) 465-5552;</b>{br}Monday to Friday from 10:00 a.m. to 3:00 p.m. {br}CLOSED on State Holidays.{br}',
    out_of_network_para3:
      '<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
    confirmation_checkmark:
      ' We shared your identity verification with the Alaska Unemployment Insurance program',
    confirmation_next_step_text: 'Reminder',
    confirmation_steps_content_line_first: `1. If you haven't yet registered for work online using Alaskajobs please register at <ak_link1>https://alaskajobs.alaska.gov</ak_link1>. Visit your local Workforce Development Division office for assistance. <ak_link2>https://alaskajobs.alaska.gov/vosnet/ContactUs.aspx?tab=0</ak_link2>`,
    moreinfo_link1:
      '<li><ak_link3>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link3></li><li><ak_link4>https://labor.alaska.gov/unemployment/home.htm</ak_link4></li><li><ak_link5>https://labor.alaska.gov/unemployment/ui-handbook.html</ak_link5></li>{br}',
    logout_intro: ' You may close this window.',
    logout_next_step_text: 'Reminder',
    scroll_to_top_text: 'Return to top',
    error_invalid_login_para3: ' ',
    more_info_text1: ' ',
    error_invalid_login_para4: ' ',
    error_invalid_login_para5: ' ',
    error_invalid_login_para6:
      'Here’s how to reach <b>Alaska Unemployment Insurance</b> if you have questions about unemployment benefits or other methods to verify your identity: {br}(907) 269-4700 or (907) 465-5552;{br}Monday to Friday from 10:00 a.m. to 3:00 p.m.{br}CLOSED on State Holidays.{br}<ak_link3>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link3>{br}{br}',
    more_info_text2: 'More Information',
    error_invalid_login_moreinfo_link1:
      '<li><ak_link3>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link3></li><li><ak_link4>https://labor.alaska.gov/unemployment/home.htm</ak_link4></li><li><ak_link5>https://labor.alaska.gov/unemployment/ui-handbook.html</ak_link5></li>',
    error_invalid_login_moreinfo_link2: ' ',
    hitawall_para3: ' ',
    hitawall_para4:
      'If you need help verifying your identity for your <b>Alaska Unemployment Insurance claim, (907) 269-4700 or (907) 465-5552;</b>{br}Monday to Friday from 10:00 a.m. to 3:00 p.m. {br}CLOSED on State Holidays.{br}',
    hitawall_para5: ' ',
    hitawall_para6: ' ',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Alaska Unemployment Insurance.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Alaska Unemployment Insurance, <b>please file a report</b> of potential unemployment identity fraud with Alaska Unemployment Insurance by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Alaska Unemployment Insurance through USPS.',
    moreinfo_confirmation_link1:
      '<li><ak_link3>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link3></li><li><ak_link4>https://labor.alaska.gov/unemployment/home.htm</ak_link4></li><li><ak_link5>https://labor.alaska.gov/unemployment/ui-handbook.html</ak_link5></li>{br}{br}',
    moreinfo_logout_link:
      '<li><ak_link3>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link3></li><li><ak_link4>https://labor.alaska.gov/unemployment/home.htm</ak_link4></li><li><ak_link5>https://labor.alaska.gov/unemployment/ui-handbook.html</ak_link5></li>{br}{br}',
    out_of_network_para4: ' ',
    hitawall_para7:
      '<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}',
    error_500_para2:
      'Please try again later. {br}Thank you for your patience and understanding.{br}{br}If you need help verifying your identity for your <b>Alaska Unemployment Insurance claim, (907) 269-4700 or (907) 465-5552;</b>{br}Monday to Friday from 10:00 a.m. to 3:00 p.m. {br}CLOSED on State Holidays{br}{br}',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: 'More Information',
    moreinfo_link1_500:
      '<li><ak_link3>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link3></li><li><ak_link4>https://labor.alaska.gov/unemployment/home.htm</ak_link4></li><li><ak_link5>https://labor.alaska.gov/unemployment/ui-handbook.html</ak_link5></li>',
    error404_para2:
      'If you need help verifying your identity for your <b>Alaska Unemployment Insurance claim, (907) 269-4700 or (907) 465-5552;</b>{br}Monday to Friday from 10:00 a.m. to 3:00 p.m. {br}CLOSED on State Holidays{br}{br}',
    more_info_text_404: 'More Information',
    invalid_url_para2:
      'The URL in your confirmation may have been entered incorrectly. <b>Please double-check the URL and try again.</b>{br}',
    invalid_url_para3:
      'If you need help verifying your identity for your <b>Alaska Unemployment Insurance claim, (907) 269-4700 or (907) 465-5552;</b>{br}Monday to Friday from 10:00 a.m. to 3:00 p.m. {br}CLOSED on State Holidays{br}{br}',
    invalid_url_para4: ' ',
    es: {
      online_radiobutton_label_text: 'En línea - Login.gov',
      online_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      online_radiobutton_doc_list_text_first:
        '<li>Documento de identidad estadual</li>',
      online_radiobutton_doc_list_text_second: ' ',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      unemployment_question2:
        'Así es como comunicarse con la <b>Seguro de desempleo de Alaska</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}',
      uicp_state_number:
        '(907) 269-4700 o (907) 465-5552;{br} Lunes a viernes de 10:00 a 15:00 horas.{br}CERRADO los días festivos estatales.{br}',
      uicp_state_timing_para:
        '<ak_link>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link>{br}{br}',
      uicp_state_email: ' ',
      confirmation_checkmark:
        'Compartimos su verificación de identidad con lo ',
      confirmation_state_title: 'Programa de seguro de desempleo de Alaska ',
      confirmation_next_step_text: 'Recordatorio',
      confirmation_steps_content_line_first:
        '1. Si aún no se ha registrado para trabajar en línea utilizando Alaskajobs, regístrese en <ak_link1>https://alaskajobs.alaska.gov</ak_link1>. Visite su oficina local de la División de Desarrollo de la Fuerza Laboral para obtener ayuda. <ak_link2>https://alaskajobs.alaska.gov/vosnet/ContactUs.aspx?tab=0</ak_link2>',
      confirmation_steps_content_line_second: ' ',
      uicp_more_info_text: 'Más información',
      moreinfo_link1:
        '<li>https://labor.alaska.gov/unemployment/call-centers.htm</li><li>https://labor.alaska.gov/unemployment/home.htm</li><li>https://labor.alaska.gov/unemployment/ui-handbook.html</li>',
      moreinfo_link2: ' ',
      logout_intro: 'Puede cerrar esta ventana.',
      logout_next_step_text: 'Recordatorio',
      scroll_to_top_text: 'Volver al principio',
      error_invalid_login_para3: ' ',
      error_invalid_login_para4: ' ',
      error_invalid_login_para5: ' ',
      error_invalid_login_para6:
        'Así es como comunicarse con la <b>Seguro de desempleo de Alaska</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(907) 269-4700 o (907) 465-5552; {br} Lunes a viernes de 10:00 a 15:00 horas.{br}CERRADO los días festivos estatales.{br}<ak_link>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link>{br}{br}',
      more_info_text1: ' ',
      more_info_text2: 'Más información',
      error_invalid_login_moreinfo_link1:
        '<li><ak_link3>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link3></li><li><ak_link4>https://labor.alaska.gov/unemployment/home.htm</ak_link4></li><li><ak_link5>https://labor.alaska.gov/unemployment/ui-handbook.html</ak_link5></li>',
      error_invalid_login_moreinfo_link2: ' ',
      error_invalid_login_para7: ' ',
      hitawall_login_subheading:
        'La verificación de su identidad con Login.gov no está disponible actualmente.',
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde o elija un método de verificación diferente. {br}Gracias por su paciencia y comprensión.',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      usps_state_title: 'Seguro de desempleo de Alaska',
      usps_state_number: '(907) 269-4700 o (907) 465-5552;{br}',
      usps_state_timing_para:
        'Lunes a viernes de 10:00 a 15:00 horas. {br}CERRADO los días festivos estatales.{br}<ak_link>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link>{br}',
      usps_state_email: ' ',
      USPS_facilities_located_around: 'Oficinas de Post Offices cerca:',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para2:
        'Así es como comunicarse con la <b>Seguro de desempleo de Alaska</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(907) 269-4700 o (907) 465-5552;{br}Lunes a viernes de 10:00 a 15:00 horas. {br}CERRADO los días festivos estatales.{br}<ak_link>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link>{br}{br}',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde. {br}Gracias por su paciencia y comprensión.',
      error_500_more_info_text1: ' ',
      error_500_para3:
        'Así es como comunicarse con la <b>Seguro de desempleo de Alaska</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(907) 269-4700 o (907) 465-5552;{br}Lunes a viernes de 10:00 a 15:00 horas. {br}CERRADO los días festivos estatales.{br}<ak_link>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link>{br}{br}',
      error_500_more_info_text2: 'Más información',
      more_info_text_404: 'Más información',
      choice_page_link: 'opciones de verificación de identificación.',
      error404_para2:
        'Así es como comunicarse con la <b>Seguro de desempleo de Alaska</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(907) 269-4700 o (907) 465-5552;{br}Lunes a viernes de 10:00 a 15:00 horas. {br}CERRADO los días festivos estatales.{br}<ak_link>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link>{br}{br}',
      invalid_url_para3:
        'Así es como comunicarse con la <b>Seguro de desempleo de Alaska</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(907) 269-4700 o (907) 465-5552;{br}Lunes a viernes de 10:00 a 15:00 horas. {br}CERRADO los días festivos estatales.{br}<ak_link>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link>',
      more_info_text_invalid: 'Más información',
      moreinfo_link1_invalid:
        '<li><ak_link3>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link3></li><li><ak_link4>https://labor.alaska.gov/unemployment/home.htm</ak_link4></li><li><ak_link5>https://labor.alaska.gov/unemployment/ui-handbook.html</ak_link5></li>',
      moreinfo_link2_invalid: ' ',
      invalid_url_para4: ' ',
      hitawall_para3: ' ',
      hitawall_para4:
        'Así es como comunicarse con la <b>Seguro de desempleo de Alaska</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(907) 269-4700 o (907) 465-5552;{br}Lunes a viernes de 10:00 a 15:00 horas. {br}CERRADO los días festivos estatales.{br}<ak_link>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link>',
      hitawall_more_info_text: ' ',
      hitawall_para5: ' ',
      postOffice_text: 'Post Office',
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Seguro de desempleo de Alaska',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Seguro de desempleo de Alaska, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Seguro de desempleo de Alaskade desempleo de Alaska siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Seguro de desempleo de Alaska través de USPS.',
      hitawall_para7: ' ',
      out_of_network_para3: ' ',
      moreinfo_link1_500:
        '<li><ak_link3>https://labor.alaska.gov/unemployment/call-centers.htm</ak_link3></li><li><ak_link4>https://labor.alaska.gov/unemployment/home.htm</ak_link4></li><li><ak_link5>https://labor.alaska.gov/unemployment/ui-handbook.html</ak_link5></li>',
      moreinfo_link2_500: ' ',
      moreinfo_link3_500: ' ',
      invalid_url_para2:
        'Es posible que la URL en su confirmación se haya ingresado incorrectamente. Vuelva a verificar la URL y vuelva a intentarlo.{br}',
    },
  },
  AS: {
    name: 'American Samoa',
    stateCode: 'AS',
  },
  AZ: {
    name: 'Arizona',
    stateCode: 'AZ',
  },
  AR: {
    name: 'Arkansas',
    stateCode: 'AR',
    swaRegex: '^[0-9]{8}-[0-9]{6}-[0-9]{7}-[0-9]{9}$',
    showFAQButton: 'true',
    showVerificationButton: true,
    showErrorPageButton_HitaWall: true,
    chooseDifferentVerificationButton_USPS: true,
    showLanguageDropdown: true,
    // english
    // uicp component
    workforce_radiobutton_label_text:
      '   I want to verify my identity in-person at an Arkansas Workforce Center',
    workforce_radiobutton_des_text:
      '  Bring your identity documents to the nearest Arkansas Workforce Center.',
    // Confirmation & logout component
    confirmation_state_title: 'Arkansas Division of Workforce',
    confirmation_steps_content_line_first:
      '1. If you have not yet called the <b>ADWS UI Hotline</b>, please call <b>1-844-908-2178</b> to have your claim processed.{br}',
    confirmation_steps_content_line_second:
      '2. Register for work online using Arkansas JobLink(AJL) at <ar_link>www.arjoblink.arkansas.gov</ar_link>. Visit your local ADWS office for assistance.',
    confirmation_button_text: 'Go to ADWS FAQ',
    faq_link: 'https://www.dws.arkansas.gov/unemployment/',
    // Enrollment component
    enrollment_description_para2:
      '   Afterward, you will need to visit an Arkansas Workforce Center to finalize your claim. Failing to respond may result in a delay or denial of benefits, or require you to repay any benefits you’ve received from the Arkansas Division of Workforce Services.',
    // usps-description component
    uicp_state_title: '<b>Arkansas Division of Workforce Services</b>{br}',
    uicp_state_number: '1-844-908-2178 {br}',
    uicp_state_timing_para:
      'Monday to Friday, 8:00 a.m. – 3:30 p.m Central Time{br}',
    uicp_state_email: '<ar_link1>ADWS.UI.Hotline@arkansas.gov</ar_link1>',
    redirect_email: 'mailto:ADWS.UI.Hotline@arkansas.gov',
    usps_state_title: 'Arkansas Division of Workforce Services',
    usps_state_number: '1-844-908-2178{br}',
    usps_state_timing_para:
      'Monday to Friday, 8:00 a.m. – 3:30 p.m Central Time',
    usps_state_email: 'ADWS.UI.Hotline@arkansas.gov',
    unemployment_question1: ' ',
    unemployment_question2:
      '{br}Here’s how to reach us if you have questions about unemployment benefits or need help with verifying your identity: {br}{br}',
    // workforce component
    workforce_appointment_text: ' ',
    workforce_para1:
      'ADWS needs more information from you before your claim can be processed.',
    para1_link_text: ' ',
    workfore_para2:
      'You will need to verify your identity in person at a local office.',
    workfore_para3:
      'Please call the <b>ADWS UI Hotline</b> at <b>1-844-908-2178</b> (Monday to Friday, 8:00 a.m. - 3:30 p.m. Central Time) within <b>7 calendar days</b> of when you submitted your application.',
    para3_link_text: ' ',
    workforce_more_info_text: 'More Information',
    workforce_moreinfo_link1:
      '<li>Find my local Arkansas Division of Workforce Services office{br}</li>',
    workforce_moreinfo_link2:
      '<li>Arkansas Division of Workforce Services Unemployment Insurance{br}</li>',
    workforce_moreinfo_link3:
      '<li>Your Unemployment Insurance Handbook — Arkansas Division of Workforce Services [PDF]{br}</li>',
    moreinfo_link1:
      '<li><ar_link2>Find my local Arkansas Division of Workforce Services office</ar_link2></li><li><ar_link3>Arkansas Division of Workforce Services Unemployment Insurance</ar_link3></li><li><ar_link4>Your Unemployment Insurance Handbook — Arkansas Division of Workforce Services [PDF]</ar_link4></li>',
    uicp_more_info_text: ' ',
    more_info_text1: ' ',
    moreinfo_link1_500:
      '<li><ar_link2>Find my local Arkansas Division of Workforce Services office</ar_link2></li><li><ar_link3>Arkansas Division of Workforce Services Unemployment Insurance</ar_link3></li><li><ar_link4>Your Unemployment Insurance Handbook — Arkansas Division of Workforce Services [PDF]</ar_link4></li>',
    moreinfo_link2_500: ' ',
    href_link1: 'https://dws.arkansas.gov/workforce-services/contact/',
    href_link2:
      'https://dws.arkansas.gov/workforce-services/unemployment/arkansas-claim-help/',
    href_link3:
      'https://dws.arkansas.gov/wp-content/uploads/Newest_Division_update_500_BOOKLET_8X11_ART_v07-2-2019.pdf',
    moreinfo_link1_invalid: ' ',
    moreinfo_link2_invalid: ' ',
    moreinfo_link3_invalid: ' ',
    error_invalid_login_moreinfo_link1:
      '<li><ar_link2>Find my local Arkansas Division of Workforce Services office</ar_link2></li><li><ar_link3>Arkansas Division of Workforce Services Unemployment Insurance</ar_link3></li><li><ar_link4>Your Unemployment Insurance Handbook — Arkansas Division of Workforce Services [PDF]</ar_link4></li>',
    error_invalid_login_moreinfo_link2: ' ',
    // Hitawal component
    hitawall_para2:
      '{br}Please try again later or choose a different verification method.{br}Thank you for your patience and understanding.',
    hitawall_para3:
      'If you need help verifying your identity for your Arkansas unemployment claim, please call the <b>ADWS UI Hotline at 1-844-908-2178</b> (Monday to Friday, 8:00 a.m - 3:30 p.m. Central Time).{br}{br}',
    // error-500
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para3:
      '{br}If you need help verifying your identity for your Arkansas unemployment claim, please call the <b>ADWS UI Hotline at 1-844-908-2178</b> (Monday to Friday, 8:00 a.m - 3:30 p.m. Central Time).',
    // page not found
    error404_para2:
      'If you need help verifying your identity for your Arkansas unemployment claim, please call the <b>ADWS UI Hotline at 1-844-908-2178</b> (Monday to Friday, 8:00 a.m - 3:30 p.m. Central Time).',
    // invalid url
    invalid_url_para3:
      'If you need help verifying your identity for your Arkansas unemployment claim, please call the <b>ADWS UI Hotline at 1-844-908-2178</b> (Monday to Friday, 8:00 a.m - 3:30 p.m. Central Time).',
    // out of network
    out_of_network_para2:
      'If you need help verifying your identity for your Arkansas unemployment claim, please call the <b>ADWS UI Hotline at 1-844-908-2178</b> (Monday to Friday, 8:00 a.m - 3:30 p.m. Central Time).',
    error_invalid_login_para4:
      '<b>Arkansas Division of Workforce Services:</b>{br}1-844-908-2178 {br}Monday to Friday: 8 a.m. - 3:30 p.m. Central Time{br}<ar_link1>ADWS.UI.Hotline@arkansas.gov</ar_link1>',
    more_info_text2: 'More Information',
    more_info_text_404: 'More Information',
    invalid_url_para4: ' ',
    choice_page_link: 'ID verification choice page.{br}',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: 'More Information',
    logout_intro: ' You may close this window.',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Arkansas Division of Workforce Services.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Arkansas Division of Workforce Services, <b>please file a report</b> of potential unemployment identity fraud with Arkansas Division of Workforce Services by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Arkansas Division of Workforce Services through USPS.',
    logout_next_step_text: 'Reminder',
    confirmation_next_step_text: 'Reminder',
    hitawall_para6: ' ',
    moreinfo_confirmation_link1:
      '<li><ar_link2>Find my local Arkansas Division of Workforce Services office</ar_link2></li><li><ar_link3>Arkansas Division of Workforce Services Unemployment Insurance</ar_link3></li><li><ar_link4>Your Unemployment Insurance Handbook — Arkansas Division of Workforce Services [PDF]</ar_link4></li>{br}{br}',
    moreinfo_logout_link:
      '<li><ar_link2>Find my local Arkansas Division of Workforce Services office</ar_link2></li><li><ar_link3>Arkansas Division of Workforce Services Unemployment Insurance</ar_link3></li><li><ar_link4>Your Unemployment Insurance Handbook — Arkansas Division of Workforce Services [PDF]</ar_link4></li>{br}{br}',
    out_of_network_para4: ' ',
    // spanish
    es: {
      // Enrollment component
      enrollment_description_para2:
        '  Deberá visitar un Centro de la Fuerza Laboral de Arkansas para finalizar su reclamación. La falta de respuesta puede demorar o denegar los beneficios, o requerir que usted pague los beneficios que ya recibió.',
      // usps-description component
      redirect_email: 'mailto:ADWS.UI.Hotline@arkansas.gov',
      // ErrorPage component
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. {br}{br}',
      hitawall_para2:
        'Vuelva a intentarlo más tarde o elija un método de verificación diferente. {br} Gracias por su paciencia y comprensión.',
      hitawall_para3:
        ' Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Arkansas, llame a la <b>línea directa de UI de ADWS al 1-844-908-2178 </b>(de lunes a viernes, de 8:00 a. m. a 3:30 p. m., hora central). {br}{br}',
      hitawall_para4: ' ',
      // workforce component
      workforce_radiobutton_label_text:
        'Quiero verificar mi identidad en persona visitando uno de los Centros de la Fuerza Laboral de Arkansas (Arkansas Workforce Center) más cercano',
      workforce_radiobutton_des_text:
        'Lleve sus documentos de identidad a uno de los Centros de la Fuerza Laboral de Arkansas (Arkansas Workforce Center) más cercano.',
      workforce_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      workforce_radiobutton_doc_list_text_first:
        '<li>Documento de identidad estadual</li>',
      workforce_radiobutton_doc_list_text_second:
        '<li>Tarjeta de identificación emitida por su estado</li>',
      workforce_radiobutton_doc_list_text_third:
        '<li>Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (requiere comprobante de domicilio)</li>',
      workforce_radiobutton_doc_list_text_fourth:
        '<li>Pasaporté estadunidense o tarjeta de Pasaporté (requiere comprobante de domicilio)</li>',
      workforce_radiobutton_doc_list_text_fifth:
        '<li>Tarjeta de Residente Permanente o Tarjeta Verde (necesita otra identificacion)</li>',
      workforce_radiobutton_doc_list_text_sixth:
        '<li>Tarjeta de identificación escolar emitida por su estado</li>',
      workforce_radiobutton_doc_list_text_seven:
        '<li>Licencia de portar arma</li>',
      workforce_radiobutton_doc_list_text_eight:
        '<li>Tarjeta de identificación para uso medico de marihuana</li>',
      workforce_appointment_text: ' ',
      workforce_para1:
        'La División de Servicios Laborales de Arkansas (ADWS) necesita que brinde más información para poder procesar su reclamación.',
      workfore_para2:
        'Usted tendrá que verificar su identidad en persona en una oficina local.',
      workfore_para3:
        'Llame a la <b>línea directa del Seguro de Desempleo de la División de Servicios Laborales de Arkansas (ADWS UI, sigla en inglés)</b> a <b>1-844-908-2178 </b>(Horario Telefonico Lunes a Viernes, 8:00 a.m. - 3:30 p.m., Hora Central) dentro de los <b>7 días del calendario</b> de presentar su solicitud.',
      moreinfo_link1:
        '<li><ar_link2>Encontrar mi oficina local de la División de Servicios Laborales de Arkansas</ar_link2></li><li><ar_link3>Seguro de Desempleo de la División de Servicios Laborales de Arkansas</ar_link3></li><li><ar_link4>Su Manual del Seguro de Desempleo — División de Servicios Laborales de Arkansas [PDF]</ar_link4></li>',
      moreinfo_link2: ' ',
      online_radiobutton_doc_list_text_second: ' ',
      unemployment_question1: ' ',
      unemployment_question2:
        'Si usted tiene preguntas sobre los beneficios de desempleo o si necesita ayuda para verificar su identidad puede contactarnos a través de:{br}{br}',
      uicp_state_title: '<b>Arkansas Division of Workforce Services:</b>{br}',
      uicp_state_number: '1-844-908-2178{br}',
      uicp_state_timing_para:
        'Horario Telefónico{br}Lunes a Viernes, 8:00 a.m. – 3:30 p.m., Hora Central{br}',
      uicp_state_email: '<ar_link1>ADWS.UI.Hotline@arkansas.gov</ar_link1>',
      scroll_to_top_text: 'Volver al principio',
      confirmation_steps_content_line_first:
        '1. Si aún no ha llamado a la <b>línea directa de ADWS UI</b>, hágalo <b>1-844-908-2178</b> para que se procese su reclamación.',
      confirmation_steps_content_line_second:
        '2. Regístrese en línea para trabajar usando Arkansas JobLink (AJL) en <ar_link>www.arjoblink.arkansas.gov.</ar_link> Visite su oficina local de ADWS para recibir asistencia.',
      confirmation_button_text: 'Ir a las preguntas frecuentes de ADWS',
      logout_intro: ' Puede cerrar esta ventana.',
      hitawall_more_info_text: ' ',
      hitawall_para5: ' ',
      error_invalid_login_para4:
        '<b>Arkansas Division of Workforce Services:</b>{br}1-844-908-2178 {br}Horario Telefónico{br}Lunes a Viernes: 8 a.m. - 3:30 p.m. Hora Central{br}<ar_link1>ADWS.UI.Hotline@arkansas.gov</ar_link1>{br}{br}<b>Para ayuda con Login.gov:</b>{br}<ar_link>https://www.login.gov/contact/</ar_link>{br}{br}',
      error_invalid_login_para5: ' ',
      error_invalid_login_para6: ' ',
      more_info_text2: 'Más información',
      error_invalid_login_moreinfo_link1:
        '<li>Encontrar mi oficina local de la División de Servicios Laborales de Arkansas</li><li>Seguro de Desempleo de la División de Servicios Laborales de Arkansas</li><li>Su Manual del Seguro de Desempleo — División de Servicios Laborales de Arkansas [PDF]</li>',
      error_invalid_login_moreinfo_link2: ' ',
      usps_state_timing_para:
        'Horario Telefonico {br}Lunes a Viernes, 8:00 a.m. – 3:30 p.m., Hora Central',
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      workforce_moreinfo_link1:
        '<li>Encontrar mi oficina local de la División de Servicios Laborales de Arkansas</li>',
      workforce_moreinfo_link2:
        '<li>Seguro de Desempleo de la División de Servicios Laborales de Arkansas</li>',
      workforce_moreinfo_link3:
        '<li>Su Manual del Seguro de Desempleo — División de Servicios Laborales de Arkansas [PDF]</li>{br}',
      error_500_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. {br}{br}Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.{br}',
      error_500_para2:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Arkansas, llame a la <b>línea directa de UI de ADWS al 1-844-908-2178 </b>(de lunes a viernes, de 8:00 a. m. a 3:30 p. m., hora central). {br}{br}<b>Para ayuda con Login.gov:</b>{br} <ar_link>https://www.login.gov/contact/</ar_link>{br}',
      error_500_para3: ' ',
      error_500_more_info_text1: 'Más información',
      error_500_more_info_text2: ' ',
      moreinfo_link1_500:
        '<li><ar_link2>Encontrar mi oficina local de la División de Servicios Laborales de Arkansas</ar_link2></li><li><ar_link3>Seguro de Desempleo de la División de Servicios Laborales de Arkansas</ar_link3></li><li><ar_link4>Su Manual del Seguro de Desempleo — División de Servicios Laborales de Arkansas [PDF]</ar_link4></li>',
      moreinfo_link2_500: ' ',
      moreinfo_link3_500: ' ',
      choice_page_link: ' opciones de verificación de identificación.',
      error404_para2:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Arkansas, llame a la <b>línea directa de UI de ADWS al 1-844-908-2178</b> (de lunes a viernes, de 8:00 a. m. a 3:30 p. m., hora central).{br}{br}',
      more_info_text_404: 'Más información',
      invalid_url_para3:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Arkansas, llame a la <b>línea directa de UI de ADWS al 1-844-908-2178</b> (de lunes a viernes, de 8:00 a. m. a 3:30 p. m., hora central).{br}',
      more_info_text_invalid: ' ',
      out_of_network_para3: ' ',
      out_of_network_para2:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Arkansas, llame a la <b>línea directa de UI de ADWS al 1-844-908-2178 </b>(de lunes a viernes, de 8:00 a. m. a 3:30 p. m., hora central). {br}',
      usps_radiobutton_doc_list_title: 'Documentos de identidad aceptados',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      hitawall_login_subheading:
        'La verificación de su identidad con Login.gov no está disponible actualmente.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      error404_more_info_text: ' ',
      online_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      online_radiobutton_doc_list_text_first:
        '<li>Documento de identidad estadual</li>',
      usps_state_title: 'Arkansas Division of Workforce Services:',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      workforce_more_info_text: 'Más información',
      logout_next_step_text: 'Recordatorio',
      postOffice_text: 'Post Office<sup>TM</sup>',
      login_gov_help_txt: ' ',
      confirmation_next_step_text: 'Recordatorio',
      login_link: ' ',
      moreinfo_confirmation_link1:
        '<li><ar_link2>Encontrar mi oficina local de la División de Servicios Laborales de Arkansas</ar_link2></li><li><ar_link3>Seguro de Desempleo de la División de Servicios Laborales de Arkansas</ar_link3></li><li><ar_link4>Su Manual del Seguro de Desempleo — División de Servicios Laborales de Arkansas [PDF]</ar_link4></li>{br}{br}',
      moreinfo_logout_link:
        '<li><ar_link2>Encontrar mi oficina local de la División de Servicios Laborales de Arkansas</ar_link2></li><li><ar_link3>Seguro de Desempleo de la División de Servicios Laborales de Arkansas</ar_link3></li><li><ar_link4>Su Manual del Seguro de Desempleo — División de Servicios Laborales de Arkansas [PDF]</ar_link4></li>{br}{br}',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Arkansas Division of Workforce Services.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Arkansas Division of Workforce Services, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el  Arkansas Division of Workforce Services siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Arkansas Division of Workforce Services a través de USPS.',
    },
  },
  CA: {
    name: 'California',
    stateCode: 'CA',
  },
  CO: {
    name: 'Colorado',
    stateCode: 'CO',
    showVerificationButton: false,
    chooseDifferentVerificationButton_USPS: false,
    showLanguageDropdown: true,
    usps_radiobutton_doc_list_title: '<b>Accepted ID documents</b>',
    uicp_state_number: ' ',
    uicp_state_timing_para:
      'Monday to Friday from 8:00 a.m. to 4:00 p.m. CLOSED on State Holidays.{br}',
    uicp_state_title:
      '{br}Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}',
    uicp_state_email: '<co_link>coloradoui.gov</co_link>{br}{br}',
    uicp_identity_footer_text: ' ',
    unemployment_question1: ' ',
    unemployment_question2:
      'Here’s how to reach <b>Colorado Unemployment Insurance Division</b> if you have questions about unemployment benefits or other methods to verify your identity:',

    usps_state_title: 'Colorado Unemployment Insurance Division',
    usps_state_number:
      'Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}',
    usps_state_timing_para:
      'Monday to Friday from 8:00 a.m. to 4:00 p.m. CLOSED on State Holidays.{br}',
    usps_state_email: ' ',
    usps_contact:
      '<co_link>https://cdle.colorado.gov/unemployment/contact-us</co_link>{br}{br}{br}',
    invalid_url_para3:
      'If you need help verifying your identity for your <b>Colorado unemployment claim</b> , call:{br}{br}Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}Monday to Friday from 8:00 a.m. to 4:00 p.m. CLOSED on State Holidays.{br}<co_link>coloradoui.gov</co_link>{br}',
    hitawall_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}',
    hitawall_para2: 'Thank you for your patience and understanding.',
    hitawall_para3:
      '{br}If you need help verifying your identity for your <b>Colorado unemployment claim</b> , call:{br}Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}Monday to Friday from 8:00 a.m. to 4:00 p.m. CLOSED on State Holidays.{br}<co_link>coloradoui.gov</co_link>',
    // error-500
    error_500_para3:
      'If you need help verifying your identity for your <b>Colorado unemployment claim</b> , call:{br}Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}Monday to Friday from 8:00 a.m. to 4:00 p.m. CLOSED on State Holidays.{br}<co_link>coloradoui.gov</co_link>',
    login_gov_help_txt: ' ',
    login_link: ' ',
    moreinfo_link1:
      '<li><co_link1>www.connectingcolorado.com</co_link1></li><li><co_link2>coloradoui.gov</co_link2></li><li><co_link3>https://cdle.colorado.gov/unemployment</co_link3></li>',
    moreinfo_link2: ' ',
    href_link1: 'https://www.connectingcolorado.com/',
    href_link2: 'https://cdle.colorado.gov/unemployment',
    href_link3: 'https://cdle.colorado.gov/unemployment',
    error404_para1:
      'We can’t find the page you are looking for. Please check your URL or return to the ',
    choice_page_link: 'ID verification choice page.',
    error404_para2:
      'If you need help verifying your identity for your <b>Colorado unemployment claim</b>, call:{br}Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}Monday to Friday from 8:00 a.m. to 4:00 p.m. CLOSED on State Holidays.{br}<co_link>coloradoui.gov</co_link>',
    error_500_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}Thank you for your patience and understanding.',
    error_500_para2: ' ',
    error_invalid_login_para3:
      'If you need help verifying your identity for your <b>Colorado unemployment claim</b>, call:',
    moreinfo_link1_500: ' ',
    moreinfo_link2_500: ' ',
    moreinfo_link3_500: ' ',
    out_of_network_para2:
      '{br}If you need help verifying your identity for your <b>Colorado unemployment claim</b>, call:{br}{br}Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}Monday to Friday from 8:00 a.m. to 4:00 p.m. CLOSED on State Holidays.{br}<co_link>coloradoui.gov</co_link>',
    more_info_text_404: 'More Information',
    invalid_url_para4: ' ',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: ' ',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Colorado Unemployment Insurance Division.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Colorado Unemployment Insurance Division, <b>please file a report</b> of potential unemployment identity fraud with Colorado Unemployment Insurance Division by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Colorado Unemployment Insurance Division through USPS.',
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    out_of_network_para4: ' ',
    out_of_network_para_text1:
      'If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.{br}',
    es: {
      // UICP Component
      identity_text: ' ',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      usps_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      uicp_identity_footer_text: ' ',
      unemployment_question1: ' ',
      unemployment_question2:
        'Así es como comunicarse con la <b>División de Seguro de Desempleo de Colorado</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:',
      uicp_state_number: ' ',
      uicp_state_timing_para:
        'Lunes a Viernes de 8:00 a 16:00 hrs. CERRADO los días festivos estatales.{br}',
      // usps-description component
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      postOffice_text: 'Post Office<sup>TM</sup>',
      usps_state_title: 'División de Seguro de Desempleo de Colorado',
      usps_state_timing_para:
        'Lunes a Viernes de 8:00 a 16:00 hrs. CERRADO los días festivos estatales.{br}',
      // error component
      invalid_url_para3:
        'Si necesita ayuda para verificar su identidad para su <b>Reclamo de desempleo de Colorado:</b>{br}Denver Metro: 303-318-9000 | Llamada gratuita al 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}Lunes a Viernes de 8:00 a 16:00 hrs. CERRADO los días festivos estatales.{br}<co_link>coloradoui.gov</co_link>{br}{br}',
      // Hitawal component
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2: 'Gracias por su paciencia y comprensión.',
      hitawall_para3:
        'Si necesita ayuda para verificar su identidad para su <b>Reclamo de desempleo de Colorado:</b>{br}Denver Metro: 303-318-9000 | Llamada gratuita al 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}Lunes a Viernes de 8:00 a 16:00 hrs. CERRADO los días festivos estatales.{br}<co_link>coloradoui.gov</co_link>{br}',
      hitawall_para4: ' ',
      hitawall_more_info_text: ' ',
      hitawall_para5: ' ',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_no_participate_para1:
        'No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_no_participate_para2: ' ',
      out_of_network_para2:
        '{br}Si necesita ayuda para verificar su identidad para su <b>Reclamo de desempleo de Colorado:</b>{br}{br}Denver Metro: 303-318-9000 | Llamada gratuita al 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}Lunes a Viernes de 8:00 a 16:00 hrs. CERRADO los días festivos estatales.{br}<co_link>coloradoui.gov</co_link>{br}{br}',
      out_of_network_para3: ' ',
      error_500_title: 'Tenemos problemas para conectar ',
      error_500_heading:
        'La verificación de su identidad no está disponible actualmente.',
      error_500_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. ',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para3:
        'Así es como comunicarse con la <b>División de Seguro de Desempleo de Colorado</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}Lunes a Viernes de 8:00 a 16:00 hrs. CERRADO los días festivos estatales.{br}<co_link>coloradoui.gov</co_link>{br}{br}',
      // page not found
      more_info_text_404: 'Más información',
      error404_para1:
        'Parece que la URL que ingresó no es válida o ya no existe.  Verifique su URL o regrese a la página de ',
      choice_page_link: 'opciones de verificación de identificación.',
      error404_para2:
        'Así es como comunicarse con la <b>División de Seguro de Desempleo de Colorado</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515{br}Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402{br}Lunes a Viernes de 8:00 a 16:00 hrs. CERRADO los días festivos estatales.{br}<co_link>coloradoui.gov</co_link>{br}{br}',
      error_500_more_info_text1: ' ',
      error_500_more_info_text2: 'Más información',
      moreinfo_link1_500: '<li>www.connectingcolorado.com</li>',
      moreinfo_link2_500: '<li>coloradoui.gov</li>',
      moreinfo_link3_500: '<li>https://cdle.colorado.gov/unemployment</li>',
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      more_info_text_invalid: ' ',
      moreinfo_link1_invalid: ' ',
      moreinfo_link2_invalid: ' ',
      moreinfo_link3_invalid: ' ',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con División de Seguro de Desempleo de Colorado.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el División de Seguro de Desempleo de Colorado, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el División de Seguro de Desempleo de Colorado siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con División de Seguro de Desempleo de Colorado a través de USPS.',
      hitawall_para7: ' ',
      moreinfo_link1:
        '<li><co_link1>www.connectingcolorado.com</co_link1></li><li><co_link2>coloradoui.gov</co_link2></li><li><co_link3>https://cdle.colorado.gov/unemployment</co_link3></li>',
    },
  },
  CT: {
    name: 'Connecticut',
    stateCode: 'CT',
  },
  DE: {
    name: 'Delaware',
    stateCode: 'DE',
  },
  DC: {
    name: 'District Of Columbia',
    stateCode: 'DC',
  },
  FM: {
    name: 'Federated States Of Micronesia',
    stateCode: 'FM',
  },
  FL: {
    name: 'Florida',
    stateCode: 'FL',
  },
  GA: {
    name: 'Georgia',
    stateCode: 'GA',

    showVerificationButton: true,
    showErrorPageButton_HitaWall: true,
    chooseDifferentVerificationButton_USPS: true,
    showLanguageDropdown: true,
    workforce_radiobutton_label_text:
      'I want to verify my identity at a Georgia Department of Labor Career Center <ga_link>https://dol.georgia.gov/locations/career-center</ga_link>',
    workforce_radiobutton_des_text:
      'Bring one or more of the acceptable identity(ID)  documents displayed below to the nearest Georgia Department of Labor Career Center.  The document(s) must be a valid government-issued picture ID. You will be required to complete an Applicant Status Affidavit to file an unemployment claim.',
    workforce_radiobutton_doc_list_text_first: `<li>State driver's license</li>`,
    workforce_radiobutton_doc_list_text_second: '<li>State ID card</li>',
    workforce_radiobutton_doc_list_text_third: '<li>U.S. Passport</li>',
    workforce_radiobutton_doc_list_text_fourth: ' ',
    workforce_radiobutton_doc_list_text_fifth: ' ',
    workforce_radiobutton_doc_list_text_sixth: ' ',
    workforce_radiobutton_doc_list_text_seven: ' ',
    workforce_radiobutton_doc_list_text_eight: ' ',
    uicp_identity_footer_text:
      'The method you choose to verify your identity will not affect your ability to receive unemployment benefits. However, you will not be able to use online unemployment services.{br}',
    unemployment_question1:
      'You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits: {br}Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry){br}Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays{br}or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
    confirmation_steps_content_line_first:
      '1. You may now use your log in credentials to access your <ga_link2>MyUI Claimant Portal</ga_link2> to continue the claim filing process. If you have forgotten or need to reset your password, access your <ga_link2>MyUI Claimant Portal</ga_link2> select Create/forgot password and/or PIN.',
    uicp_more_info_text: 'Resources',
    moreinfo_confirmation_link1:
      '<li><ga_link>Find my local GDOL Career Center</ga_link></li><li><ga_link3>UI Claimant Video and Handbook</ga_link3></li>{br}{br}',
    logout_intro: 'You may close this window.',
    logout_next_step_text: 'Next Steps',
    moreinfo_logout_link:
      '<li><ga_link>Find my local GDOL Career Center</ga_link></li><li><ga_link3>UI Claimant Video and Handbook</ga_link3></li>{br}{br}',
    error_invalid_login_para3: ' ',
    more_info_text1: ' ',
    more_info_text2: 'Resources',
    error_invalid_login_moreinfo_link1:
      '<li><ga_link>Find my local GDOL Career Center</ga_link></li><li><ga_link3>UI Claimant Video and Handbook</ga_link3></li>',
    error_invalid_login_para5:
      '<li><ga_link>Find my local GDOL Career Center</ga_link></li><li><ga_link3>UI Claimant Video and Handbook</ga_link3></li>',
    error_invalid_login_para6:
      'You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits: {br}{br}Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry){br}Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays{br}or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
    hitawall_para2:
      '{br}Please try again later or choose a different verification method.{br}Thank you for your patience and understanding.',
    hitawall_para4:
      '{br}You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits: {br}Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry){br}Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays{br}or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
    error_500_para2:
      'Please try again later. {br}Thank you for your patience and understanding.',
    error_500_para3:
      'You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits: {br}Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry){br}Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays{br}or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
    error_500_more_info_text2: 'Resources',
    moreinfo_link1_500:
      '<li><ga_link>Find my local GDOL Career Center</ga_link></li><li><ga_link3>UI Claimant Video and Handbook</ga_link3></li>{br}',
    error404_para2:
      'You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits: {br}Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry){br}Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays{br}or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
    more_info_text_404: 'Resources',
    moreinfo_link1:
      '<li><ga_link>Find my local GDOL Career Center</ga_link></li><li><ga_link3>UI Claimant Video and Handbook</ga_link3></li>',
    required_docs_heading: ' We use the address on your ID for verification.',
    required_docs_para:
      'If you live somewhere else or your ID has no address listed, you will also need proof of address:',
    usps_state_timing_para:
      'You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits: {br}Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry){br}Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays{br}or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}',
    out_of_network_para2:
      'You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits: {br}Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry){br}Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays{br}or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
    invalid_url_para3:
      'You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits: {br}Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry){br}Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays{br}or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
    more_info_text_invalid: 'Resources',
    moreinfo_link1_invalid:
      '<li><ga_link>Find my local GDOL Career Center</ga_link></li><li><ga_link3>UI Claimant Video and Handbook</ga_link3></li>',
    confirmation_state_title: 'Georgia Department of Labor (GDOL)',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Georgia Department of Labor.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Georgia Department of Labor, <b>please file a report</b> of potential unemployment identity fraud with Georgia Department of Labor by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim</b> with Georgia Department of Labor through USPS.',
    workforce_appointment_text:
      'You’ve chosen to verify your identity in person.',
    workforce_para1:
      'You’ve chosen to verify your identity in person. Please report to your local career center within 7 calendar days to complete this process.',
    workfore_para2:
      'Bring one or more of the acceptable identity (ID) documents displayed below to the nearest <b>Georgia Department of Labor (GDOL)</b> Career Center.  The document(s) must be a valid government-issued picture ID. You will be required to complete an Applicant Status Affidavit to file an unemployment claim.',
    workfore_para3:
      '<b>Accepted ID documents</b><li>State driver’s license</li><li>State ID card</li><li>U.S. Passport</li>{br}If you fail to verify your identity <b>within 7 calendar days</b> you will not be able to file a claim or receive any other unemploment services.',
    workforce_more_info_text: 'Resources',
    workforce_moreinfo_link1:
      '<li><ga_link>Find my local GDOL Career Center</ga_link></li><li><ga_link3>UI Claimant Video and Handbook</ga_link3></li>{br}',
    es: {
      online_radiobutton_label_text: 'En línea - Login.gov',
      online_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      online_radiobutton_doc_list_text_first:
        '<li>Documento de identidad estadual</li>',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      workforce_radiobutton_label_text:
        'Quiero verificar mi identidad en un Centro de Desarrollo Profesional del Departamento de Trabajo de Georgia{br}<ga_link>htps://dol.georgia.gov/locations/career-center</ga_link>',
      workforce_radiobutton_des_text:
        'Lleve uno o más de los documentos de identidad aceptables que se muestran abajo al Centro de Desarrollo Profesional del Departamento de Trabajo de Georgia más cercano. Estos deben ser documentos de identidad válidos con foto emitidos por el gobierno. Deberá completar una Declaración jurada de estado de solicitante para presentar una reclamación de seguro de desempleo.{br}',
      workforce_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      workforce_radiobutton_doc_list_text_first:
        '<li>Licencia de conducir estatal </li>',
      workforce_radiobutton_doc_list_text_second:
        '<li>Documento de identidad estatal </li>',
      workforce_radiobutton_doc_list_text_third:
        '<li>Pasaporte estadounidense</li>',
      uicp_identity_footer_text:
        'El método que seleccione para verificar su identidad no afectará su capacidad de recibir beneficios de desempleo. No obstante, no podrá utilizar los servicios de desempleo en línea.{br}',
      unemployment_question1:
        'Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:{br}Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).{br}Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales{br}o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
      unemployment_question2: ' ',
      confirmation_header:
        'Próximos pasos para la verificación de identidad de las solicitudes de desempleo',
      confirmation_state_title: 'Departamento de Trabajo de Georgia (GDOL)',
      confirmation_next_step_text: 'Siguientes pasos',
      confirmation_steps_content_line_first:
        '1. Ahora puede utilizar sus credenciales de inicio de sesión para acceder a <ga_link2>MyUI Claimant Portal</ga_link2> (Mi Portal de seguro de desempleo para reclamantes) para continuar el proceso de presentación de la reclamación. Si ha olvidado o necesita restablecer su contraseña, acceda a <ga_link2>MyUI Claimant Portal</ga_link2> y seleccione Create/forgot password and/or PIN (Crear/olvidé mi contraseña y/o PIN).',
      confirmation_steps_content_line_second: ' ',
      moreinfo_confirmation_link1:
        '<li><ga_link>Encontrar mi Centro de Desarrollo Profesional local del GDOL</ga_link></li><li><ga_link3>Manual y video para reclamantes de seguro de desempleo</ga_link3></li>{br}{br}',
      uicp_more_info_text: 'Recursos',
      logout_intro: ' Puede cerrar esta ventana.',
      logout_next_step_text: 'Siguientes pasos',
      moreinfo_logout_link:
        '<li><ga_link>Encontrar mi Centro de Desarrollo Profesional local del GDOL</ga_link></li><li><ga_link3>Manual y video para reclamantes de seguro de desempleo</ga_link3></li>{br}{br}',
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde o elija un método de verificación diferente. {br}Gracias por su paciencia y comprensión.',
      hitawall_para4:
        '{br}Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:{br}Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).{br}Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales{br}o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para3:
        'Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:{br}Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).{br}Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales{br}o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
      error_500_more_info_text2: 'Recursos',
      moreinfo_link1_500:
        '<li><ga_link>Encontrar mi Centro de Desarrollo Profesional local del GDOL</ga_link></li><li><ga_link3>Manual y video para reclamantes de seguro de desempleo</ga_link3></li>{br}',
      error404_para2:
        'Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:{br}Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).{br}Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales{br}o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
      more_info_text_404: 'Recursos',
      moreinfo_link1:
        '<li><ga_link>Encontrar mi Centro de Desarrollo Profesional local del GDOL</ga_link></li><li><ga_link3>Manual y video para reclamantes de seguro de desempleo</ga_link3></li>{br}',
      moreinfo_link2: ' ',
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_docs_para:
        'Si no tiene dirección o su dirección es diferente de la que esta declarada en su documento de identificación, necesita presentar comprobante de domicilio, por ejemplo: ',
      usps_state_timing_para:
        'Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:{br}Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).{br}Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales{br}o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para2:
        'Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:{br}Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).{br}Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales{br}o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
      invalid_url_para3:
        'Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:{br}Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).{br}Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales{br}o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}<b>Para obtener ayuda con Login.gov:</b> {br}<login_link>https://www.login.gov/contact/</login_link>{br}',
      more_info_text_invalid: 'Recursos',
      moreinfo_link1_invalid:
        '<li><ga_link>Encontrar mi Centro de Desarrollo Profesional local del GDOL</ga_link></li><li><ga_link3>Manual y video para reclamantes de seguro de desempleo</ga_link3></li>{br}',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Trabajo de Georgia.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Departamento de Trabajo de Georgia, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Departamento de Trabajo de Georgia siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Trabajo de Georgia a través de USPS.',
      workforce_appointment_text:
        'Usted ha optado por verificar su identidad en persona.',
      workforce_para1:
        'Preséntese en su centro de desarrollo profesional local dentro de un plazo de siete (7) días calendario para completar este proceso.',
      workfore_para2:
        'Lleve uno o más de los documentos de identidad aceptables que se muestran abajo al Centro de Desarrollo Profesional del <b>Departamento de Trabajo de Georgia (GDOL)</b> más cercano. Estos deben ser documentos de identidad válidos con foto emitidos por el gobierno. Deberá completar una Declaración jurada de estado de solicitante para presentar una reclamación de seguro de desempleo.',
      workfore_para3:
        '<b>Documentos de identidad aceptados</b><li>Licencia de conducir estatal</li><li>Documento de identidad estatal</li><li>Pasaporte estadounidense</li>{br}Si no verifica su identidad dentro del plazo de <b>siete (7) días calendario</b>, no podrá presentar una reclamación ni recibir otro servicio de desempleo.',
      workforce_more_info_text: 'Recursos',
      workforce_moreinfo_link1:
        '<li><ga_link>Encontrar mi Centro de Desarrollo Profesional local del GDOL</ga_link></li><li><ga_link3>Manual y video para reclamantes de seguro de desempleo</ga_link3></li>{br}',
      error_invalid_login_para3: ' ',
      more_info_text1: ' ',
      more_info_text2: 'Recursos',
      error_invalid_login_para5:
        '<li><ga_link>Encontrar mi Centro de Desarrollo Profesional local del GDOL</ga_link></li><li><ga_link3>Manual y video para reclamantes de seguro de desempleo</ga_link3></li>',
      error_invalid_login_para6:
        'Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:{br}{br}Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).{br}Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales{br}o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.{br}<ga_link1>https://dol.georgia.gov/contact-us</ga_link1>{br}{br}',
    },
  },
  GU: {
    name: 'Guam',
    stateCode: 'GU',
  },
  HI: {
    name: 'Hawaii',
    stateCode: 'HI',
    swaRegex: '^[A-Z0-9]{8}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{12}$',
    showFAQButton: 'false',
    showErrorPageButton_HitaWall: true,
    showVerificationButton: true,
    chooseDifferentVerificationButton_USPS: true,
    showLanguageDropdown: true,
    // Confirmation & logout component
    logout_next_step_text: 'Reminder',
    confirmation_steps_content_line_first: `1. If you haven't yet registered for work online using HireNet Hawaii please register at <hi_link>https://www.hirenethawaii.com/vosnet/default.aspx.</hi_link>  Visit your local Workforce Development Division office for assistance.`,
    confirmation_steps_content_line_second: ' ',
    // usps-description component
    redirect_email: 'https://labor.hawaii.gov/ui/contact/',
    usps_state_title: 'Hawaii Unemployment Insurance Division',
    usps_state_number: 'Toll free at (833) 901-2272 or (833) 901-2275;{br}',
    usps_state_timing_para:
      'Monday to Friday from 7:45 a.m. to 4:30 p.m.{br}CLOSED on State Holidays.{br}<hi_link1>https://labor.hawaii.gov/ui/contact/</hi_link1>',
    moreinfo_link1:
      '<li><hi_link1>Find my local Hawaii Unemployment Insurance local office</hi_link1></li><li><hi_link3>Hawaii Unemployment Insurance Division</hi_link3></li><li><hi_link4>Your Unemployment Insurance Handbook</hi_link4></li>{br}',
    href_link1: 'https://labor.hawaii.gov/ui/contact/',
    href_link2: 'https://labor.hawaii.gov/ui/',
    href_link3:
      'https://labor.hawaii.gov/ui/ui-forms/unemployment-insurance-handbook-printable-pdf/',
    uicp_identity_footer_text:
      'The method you choose to verify your identity will not affect your ability to receive unemployment benefits. State staff will process your application following all applicable laws and regulations.',
    uicp_state_number: ' ',
    uicp_state_timing_para: ' ',
    uicp_state_email: ' ',
    unemployment_question1: ' ',
    unemployment_question2:
      '{br}Here’s how to reach <b>Hawaii Unemployment Insurance Division</b> if you have questions about unemployment benefits or other methods to verify your identity: {br}Toll free at (833) 901-2272 or (833) 901-2275;{br}Monday to Friday from 7:45 a.m. to 4:30 p.m.{br}CLOSED on State Holidays{br}<hi_link1>https://labor.hawaii.gov/ui/contact/</hi_link1>{br}{br}',
    online_radiobutton_doc_list_text_second: '<li>State ID card</li>',
    confirmation_state_title: 'Hawaii Unemployment Insurance Division ',
    confirmation_next_step_text: 'Reminder',
    error_invalid_login_para3: ' ',
    out_of_network_para2:
      '{br}If you need help verifying your identity for your <b>Hawaii unemployment claim, Toll free at (833) 901-2272 or (833) 901-2275;</b> {br}Monday to Friday from 7:45 a.m. to 4:30 p.m. CLOSED on State Holidays or schedule an appointment at:{br}<hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>{br}',
    hitawall_para2:
      '{br}Please try again later or choose a different verification method.{br}Thank you for your patience and understanding.',
    hitawall_para3: ' ',
    error_500_para2:
      'Please try again later. {br}Thank you for your patience and understanding.',
    error_500_para3:
      '{br}If you need help verifying your identity for your <b>Hawaii unemployment claim, Toll free at (833) 901-2272 or (833) 901-2275;</b> {br}Monday to Friday from 7:45 a.m. to 4:30 p.m. CLOSED on State Holidays or schedule an appointment at:{br}<hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>{br}',
    more_info_text1: ' ',
    more_info_text2: 'More Information',
    moreinfo_link1_500:
      '<li><hi_link1>Find my local Hawaii Unemployment Insurance local office</hi_link1></li><li><hi_link3>Hawaii Unemployment Insurance Division</hi_link3></li><li><hi_link4>Your Unemployment Insurance Handbook</hi_link4></li>',
    moreinfo_link2_500: ' ',
    error404_para2:
      'If you need help verifying your identity for your <b>Hawaii unemployment claim, Toll free at (833) 901-2272 or (833) 901-2275;</b>{br}Monday to Friday from 7:45 a.m. to 4:30 p.m.  CLOSED on State Holidays or schedule an appointment at:{br}<hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>{br}{br}',
    more_info_text_404: 'More Information',
    invalid_url_para3:
      'If you need help verifying your identity for your <b>Hawaii unemployment claim, Toll free at (833) 901-2272 or (833) 901-2275;</b>{br}Monday to Friday from 7:45 a.m. to 4:30 p.m.  CLOSED on State Holidays or schedule an appointment at: {br}<hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>{br}',
    moreinfo_link1_invalid: ' ',
    moreinfo_link2_invalid: ' ',
    moreinfo_link3_invalid: ' ',
    error_invalid_login_para4: ' ',
    error_invalid_login_para5: ' ',
    error_invalid_login_moreinfo_link1:
      '<li><hi_link1>Find my local Hawaii Unemployment Insurance local office</hi_link1></li><li><hi_link3>Hawaii Unemployment Insurance Division</hi_link3></li><li><hi_link4>Your Unemployment Insurance Handbook</hi_link4></li>',
    error_invalid_login: ' ',
    error_invalid_login_para6:
      'If you need help verifying your identity for your <b>Hawaii unemployment claim, Toll free at (833) 901-2272 or (833) 901-2275;</b>{br}Monday to Friday from 7:45 a.m. to 4:30 p.m. CLOSED on State Holidays or schedule an appointment at:{br}<hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>',
    online_radiobutton_doc_list_text_first: '<li>State driver’s license</li>',
    hitawall_para4:
      '{br}If you need help verifying your identity for your <b>Hawaii unemployment claim, Toll free at (833) 901-2272 or (833) 901-2275;</b> {br}Monday to Friday from 7:45 a.m. to 4:30 p.m. CLOSED on State Holidays or schedule an appointment at:{br}<hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>{br}{br}',
    hitawall_para5:
      '<li><hi_link1>Find my local Hawaii Unemployment Insurance local office</hi_link1></li><li><hi_link3>Hawaii Unemployment Insurance Division</hi_link3></li><li><hi_link4>Your Unemployment Insurance Handbook</hi_link4></li>',
    hitawall_more_info_text: 'More Information',
    invalid_url_para4: ' ',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: '{br}More Information',
    logout_intro: ' You may close this window.',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Hawaii Unemployment Insurance Division.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Hawaii Unemployment Insurance Division, <b>please file a report</b> of potential unemployment identity fraud with Hawaii Unemployment Insurance Division by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Hawaii Unemployment Insurance Division through USPS.',
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    moreinfo_confirmation_link1:
      '<li><hi_link1>Find my local Hawaii Unemployment Insurance local office</hi_link1></li><li><hi_link3>Hawaii Unemployment Insurance Division</hi_link3></li><li><hi_link4>Your Unemployment Insurance Handbook</hi_link4></li>{br}{br}',
    moreinfo_logout_link:
      '<li><hi_link1>Find my local Hawaii Unemployment Insurance local office</hi_link1></li><li><hi_link3>Hawaii Unemployment Insurance Division</hi_link3></li><li><hi_link4>Your Unemployment Insurance Handbook</hi_link4></li>{br}{br}',
    out_of_network_para4: ' ',
    // spanish
    es: {
      // Enrollment component
      enrollment_heading_text:
        'Visite Post Office<sup>TM</sup> antes de o para el dia ',
      enrollment_description_para1:
        'Está a punto de confirmar su identidad para su <b>reclamo de desempleo</b> con USPS. Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.',
      enrollment_code_bold_text: '<b>Código de inscripción</b>',
      enrollment_different_verification_method_link:
        'Seleccione un método de verificación diferente',
      heading_helper_text:
        'Ingrese su nombre y dirección exactamente como aparecen en su documento de identidad emitido por el gobierno.',
      USPS_facilities_located_around: 'Oficinas de Correos cerca de:',
      confirmation_swa_id_text:
        'El código utilizado para verificar su identidad es el siguiente: ',
      print_this_pageBtn_text: 'Imprime esta página',
      online_radiobutton_label_text: 'En linea - Login.gov',
      enrollment_personal_information_text: 'Información personal',
      enrollment_id_details_name_bold_text: '<b>Nombre:</b>',
      confirmation_state_title: 'División de Seguro de Desempleo de Hawái',
      error_invalid_login_para6:
        'Si necesita ayuda para verificar su identidad para su <b>reclamo de desempleo de Hawái, número gratuito al (833) 901-2272 o (833) 901-2275; </b>Lunes a Viernes de 7:45 am a 4:30 pm CERRADO los días festivos estatales o programe una cita en: <hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>{br}',
      scroll_to_top_text: 'Volver al principio',
      postOffice_text: 'Post Office<sup>TM</sup>',
      postOffice_span: 'no aceptará:',
      // usps-description component
      moreinfo_link1:
        '<li><hi_link1>Encuentre mi oficina local de seguro de desempleo en Hawái</hi_link1></li><li><hi_link3>División de Seguro de Desempleo de Hawái</hi_link3></li><li><hi_link4>Su manual de seguro de desempleo</hi_link4></li>',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      moreinfo_link2: ' ',
      redirect_email: 'https://labor.hawaii.gov/ui/contact/',
      usps_state_title: 'División de Seguro de Desempleo de Hawái',
      usps_state_number:
        'Llamada gratuita al (833) 901-2272 o (833) 901-2275;{br}',
      uicp_identity_footer_text:
        'El método escogido para verificar su identidad no afectará sus posibilidades de recibir beneficios de desempleo. El personal estatal procesará su solicitud siguiendo todas las leyes y reglamentos aplicables.',
      uicp_state_number:
        'Llamada gratuita al (833) 901-2272 o (833) 901-2275;{br}',
      usps_state_email: 'https://labor.hawaii.gov/ui/contact/',
      hitawall_usps_heading: 'Parece que tenemos un problema',
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      // ErrorPage component
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. {br}{br}',
      hitawall_para2:
        'Vuelva a intentarlo más tarde o elija un método de verificación diferente.{br} Gracias por su paciencia y comprensión.',
      hitawall_para3: ' ',
      hitawall_para4:
        'Si necesita ayuda para verificar su identidad para su <b>reclamo de desempleo de Hawái, número gratuito al (833) 901-2272 o (833) 901-2275; </b>Lunes a Viernes de 7:45 am a 4:30 pm CERRADO los días festivos estatales o programe una cita en: <hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>{br}',
      // workforce component
      workforce_appointment_text: ' ',
      workforce_para1:
        'La División de Servicios Laborales de Hawái necesita que brinde más información para poder procesar su reclamación.',
      workfore_para2:
        'Usted tendrá que verificar su identidad en persona en una oficina local.',
      workfore_para3:
        'Llame a la <b>línea directa del Seguro de Desempleo de la División de Servicios Laborales de Hawái</b> a <b>1-844-908-2178 </b>(Horario Telefonico Lunes a Viernes, 8:00 a.m. - 3:30 p.m., Hora Central) dentro de los <b>7 días del calendario</b> de presentar su solicitud.',
      unemployment_question1: ' ',
      unemployment_question2: ' ',
      uicp_state_title:
        '{br}Así es como comunicarse con la <b>División de Seguro de Desempleo de Hawái</b>{br}si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}',
      uicp_state_timing_para:
        'Lunes a Viernes de 7:45 a 16:30 hrs.{br}CERRADO los días festivos estatales.{br}',
      uicp_state_email: 'https://labor.hawaii.gov/ui/contact/',
      confirmation_steps_content_line_first:
        '1. Si aún no se ha registrado para trabajar en línea usando HireNet Hawaii en, regístrese en <hi_link>https://www.hirenethawaii.com/vosnet/default.aspx.</hi_link> Visite su oficina local de la División de Desarrollo de la Fuerza Laboral para obtener ayuda.',
      confirmation_steps_content_line_second: ' ',
      confirmation_button_text: ' ',
      moreinfo_confirmation_link1:
        '<li><hi_link3>Encuentre mi oficina local de seguro de desempleo en Hawái</hi_link3></li><li><hi_link4>División de Seguro de Desempleo de Hawái</hi_link4></li><li><hi_link1>Su manual de seguro de desempleo</hi_link1></li>{br}{br}',
      logout_intro: ' Puede cerrar esta ventana.',
      moreinfo_logout_link:
        '<li><hi_link3>Encuentre mi oficina local de seguro de desempleo en Hawái</hi_link3></li><li><hi_link4>División de Seguro de Desempleo de Hawái</hi_link4></li><li><hi_link1>Su manual de seguro de desempleo</hi_link1></li>{br}{br}',
      hitawall_more_info_text: 'Más información',
      hitawall_para5:
        '<li><hi_link3>Encuentre mi oficina local de seguro de desempleo en Hawái</hi_link3></li><li><hi_link4>División de Seguro de Desempleo de Hawái</hi_link4></li><li><hi_link1>Su manual de seguro de desempleo</hi_link1></li>',
      error_invalid_login_para3: ' ',
      error_invalid_login_para4: ' ',
      error_invalid_login_para5: ' ',
      error_invalid_login_moreinfo_link1:
        '<li><hi_link1>Encuentre mi oficina local de seguro de desempleo en Hawái</hi_link1></li><li><hi_link3>División de Seguro de Desempleo de Hawái</hi_link3></li><li><hi_link4>Su manual de seguro de desempleo</hi_link4></li>',
      error_invalid_login_moreinfo_link2: ' ',
      usps_state_timing_para:
        'Lunes a Viernes de 7:45 am a 4:30 pm{br} CERRADO los días festivos estatales.',
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      hitawall_login_heading: 'Parece que tenemos un problema',
      error_500_title: 'Tenemos problemas para conectar',
      error_500_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. {br}{br}Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.{br}',
      error_500_para2:
        'Si necesita ayuda para verificar su identidad para su <b>Reclamo de desempleo de Hawái, número gratuito al (833) 901-2272 o (833) 901-2275;</b> Lunes a Viernes de 7:45 am a 4:30 pm CERRADO los días festivos estatales o programe una cita en:{br}<hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>{br}{br}',
      error_500_para3: ' ',
      error_500_more_info_text3: ' ',
      error_400_para2: ' ',
      error_500_more_info_text2: 'Más información',
      choice_page_link: ' opciones de verificación de identificación.',
      error404_para2:
        'Si necesita ayuda para verificar su identidad para su <b>Reclamo de desempleo de Hawái, número gratuito al (833) 901-2272 o (833) 901-2275;</b> Lunes a Viernes de 7:45 am a 4:30 pm CERRADO los días festivos estatales o programe una cita en:{br}<hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>{br}{br}',
      more_info_text_404: 'Más información',
      invalid_url_para3:
        'Si necesita ayuda para verificar su identidad para su <b>Reclamo de desempleo de Hawái, número gratuito al (833) 901-2272 o (833) 901-2275;</b> Lunes a Viernes de 7:45 am a 4:30 pm CERRADO los días festivos estatales o programe una cita en:{br}<hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>',
      more_info_text_invalid: ' ',
      error_invalid_title: 'Inicio de sesión fallido',
      out_of_network_para3: ' ',
      out_of_network_para2:
        'Si necesita ayuda para verificar su identidad para su <b>reclamo de desempleo de Hawái, número gratuito al (833) 901-2272 o (833) 901-2275;</b> Lunes a Viernes de 7:45 am a 4:30 pm CERRADO los días festivos estatales o programe una cita en:{br}<hi_link2>https://labor.hawaii.gov/ui/appointments/</hi_link2>',
      usps_radiobutton_doc_list_title: 'Documentos de identidad aceptados',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      hitawall_login_subheading:
        'La verificación de su identidad con Login.gov no está disponible actualmente.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      error404_more_info_text: ' ',
      online_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      online_radiobutton_doc_list_text_first: '<li>Licencia de conducir</li>',
      online_radiobutton_doc_list_text_second:
        '<li>Tarjeta de identificación emitida por su estado</li>',
      more_info_text1: ' ',
      more_info_text2: 'Más información',
      error_500_more_info_text1: ' ',
      uicp_more_info_text: 'Más información',
      confirmation_next_step_text: 'Recordatorio',
      logout_next_step_text: 'Recordatorio',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con División de Seguro de Desempleo de Hawái.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el División de Seguro de Desempleo de Hawái, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el División de Seguro de Desempleo de Hawái siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con División de Seguro de Desempleo de Hawái a través de USPS.',
      moreinfo_link1_500:
        '<li><hi_link1>Encuentre mi oficina local de seguro de desempleo en Hawái</hi_link1></li><li><hi_link3>División de Seguro de Desempleo de Hawái</hi_link3></li><li><hi_link4>Su manual de seguro de desempleo</hi_link4></li>',
      moreinfo_link2_500: ' ',
    },
  },
  ID: {
    name: 'Idaho',
    stateCode: 'ID',
  },
  IL: {
    name: 'Illinois',
    stateCode: 'IL',
  },
  IN: {
    name: 'Indiana',
    stateCode: 'IN',
    showLanguageDropdown: true,
    uicp_identity_footer_text: ' ',
    uicp_state_title: `Here's how to reach the Unemployment Insurance Division of the <b>Indiana Department of Workforce Development</b> if you have questions about unemployment benefits or other methods to verify your identity:{br}`,
    uicp_state_number:
      'Toll-Free: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Monday to Friday from 8:00 a.m. to 4:30 p.m. CLOSED on State Holidays.{br}Interpretation available upon request.{br}<in_link>unemployment.in.gov</in_link>{br}{br}',
    required_docs_heading: ' We use the address on your ID for verification.',
    required_docs_para:
      'If you live somewhere else or your ID has no address listed, you will also need proof of address:',
    usps_state_timing_para: `Here's how to reach the Unemployment Insurance Division of the <b>Indiana Department of Workforce Development</b> if you have questions about unemployment benefits or other methods to verify your identity:{br}Toll-Free: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Monday to Friday from 8:00 a.m. to 4:30 p.m. CLOSED on State Holidays.{br}Interpretation available upon request.{br}<in_link>unemployment.in.gov</in_link>{br}`,
    enrollment_description_para2: ' ',
    hitawall_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}',
    hitawall_para4: `Here's how to reach the Unemployment Insurance Division of the <b>Indiana Department of Workforce Development</b> if you have questions about unemployment benefits or other methods to verify your identity:{br}Toll-Free: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Monday to Friday from 8:00 a.m. to 4:30 p.m. CLOSED on State Holidays.{br}Interpretation available upon request.{br}<in_link>unemployment.in.gov</in_link>{br}`,
    hitawall_more_info_text: 'More Information',
    hitawall_para5:
      '<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>',
    hitawall_para6: ' ',
    out_of_network_para2: `Here's how to reach the Unemployment Insurance Division of the <b>Indiana Department of Workforce Development</b> if you have questions about unemployment benefits or other methods to verify your identity:{br}Toll-Free: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Monday to Friday from 8:00 a.m. to 4:30 p.m. CLOSED on State Holidays.{br}Interpretation available upon request.{br}<in_link>unemployment.in.gov</in_link>{br}`,
    out_of_network_more_info_text: 'More Information',
    out_of_network_para3:
      '<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>',
    out_of_network_para4: ' ',
    error_500_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}{br} Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para2: `Here's how to reach the Unemployment Insurance Division of the <b>Indiana Department of Workforce Development</b> if you have questions about unemployment benefits or other methods to verify your identity:{br}Toll-Free: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Monday to Friday from 8:00 a.m. to 4:30 p.m. CLOSED on State Holidays.{br}Interpretation available upon request.{br}<in_link>unemployment.in.gov</in_link>{br}`,
    error_500_more_info_text1: 'More Information',
    login_gov_help_txt: ' ',
    login_link: ' ',
    error_500_more_info_text2: ' ',
    moreinfo_link1_500:
      '<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>',
    error404_para2: `Here's how to reach the Unemployment Insurance Division of the <b>Indiana Department of Workforce Development</b> if you have questions about unemployment benefits or other methods to verify your identity:{br}Toll-Free: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Monday to Friday from 8:00 a.m. to 4:30 p.m. CLOSED on State Holidays.{br}Interpretation available upon request.{br}<in_link>unemployment.in.gov</in_link>{br}`,
    more_info_text_404: 'More Information',
    moreinfo_link1:
      '<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>',
    invalid_url_para3: `Here's how to reach the Unemployment Insurance Division of the <b>Indiana Department of Workforce Development</b> if you have questions about unemployment benefits or other methods to verify your identity:{br}Toll-Free: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Monday to Friday from 8:00 a.m. to 4:30 p.m. CLOSED on State Holidays.{br}Interpretation available upon request.{br}<in_link>unemployment.in.gov</in_link>{br}`,
    more_info_text_invalid: 'More Information',
    moreinfo_link1_invalid:
      '<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>',
    invalid_url_para4: ' ',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Indiana Department of Workforce Development.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Indiana Department of Workforce Development, <b>please file a report</b> of potential unemployment identity fraud with Indiana Department of Workforce Development by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to confirm your identity for your unemployment claim with <b>Indiana Department of Workforce Development</b> through USPS.',
    es: {
      uicp_identity_footer_text: ' ',
      uicp_state_title: `Así es como comunicarse con la División de Seguro de Desempleo del <b>Departartamento de Desarrollo Laboral de Indiana</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}`,
      unemployment_question2: ' ',
      uicp_state_number:
        'Llmada gratuita: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Lunes a Viernes de 8:00 a 16:30 hrs. CERRADO los días festivos estatales. {br}Interpretación disponible a pedido. {br}<in_link>unemployment.in.gov</in_link>{br}{br}',
      uicp_state_email: ' ',
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_docs_para:
        'Si no tiene dirección o su dirección es diferente de la que esta declarada en su documento de identificación, necesita presentar comprobante de domicilio, por ejemplo: ',
      usps_state_timing_para: `Así es como comunicarse con la División de Seguro de Desempleo del <b>Departartamento de Desarrollo Laboral de Indiana</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Llmada gratuita: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Lunes a Viernes de 8:00 a 16:30 hrs. CERRADO los días festivos estatales. {br}Interpretación disponible a pedido.{br}<in_link>unemployment.in.gov</in_link>{br}`,
      usps_state_email: ' ',
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2: 'Gracias por su paciencia y comprensión.',
      hitawall_para3: ' ',
      hitawall_para4: `Así es como comunicarse con la División de Seguro de Desempleo del <b>Departartamento de Desarrollo Laboral de Indiana</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Llmada gratuita: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Lunes a Viernes de 8:00 a 16:30 hrs. CERRADO los días festivos estatales. {br}Interpretación disponible a pedido.{br}<in_link>unemployment.in.gov</in_link>{br}`,
      hitawall_more_info_text: 'Más información',
      hitawall_para5:
        '<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>',
      hitawall_para6: ' ',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_para2: `Así es como comunicarse con la División de Seguro de Desempleo del <b>Departartamento de Desarrollo Laboral de Indiana</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Llmada gratuita: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Lunes a Viernes de 8:00 a 16:30 hrs. CERRADO los días festivos estatales. {br}Interpretación disponible a pedido.{br}<in_link>unemployment.in.gov</in_link>{br}`,
      out_of_network_more_info_text: 'Más información',
      out_of_network_para3:
        '<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>',
      out_of_network_para4: ' ',
      error_500_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}{br}Por favor, vuelva a intentarlo más tarde o elija un método de verificación diferente. {br}Gracias por su paciencia y comprensión.',
      error_500_para2: `Así es como comunicarse con la División de Seguro de Desempleo del <b>Departartamento de Desarrollo Laboral de Indiana</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Llmada gratuita: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Lunes a Viernes de 8:00 a 16:30 hrs. CERRADO los días festivos estatales. {br}Interpretación disponible a pedido.{br}<in_link>unemployment.in.gov</in_link>{br}`,
      error_500_more_info_text1: 'Más información',
      error_500_para3: ' ',
      login_gov_help_txt: ' ',
      login_link: ' ',
      error_500_more_info_text2: ' ',
      moreinfo_link1_500:
        '<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>',
      moreinfo_link2: ' ',
      error404_para2: `Así es como comunicarse con la División de Seguro de Desempleo del <b>Departartamento de Desarrollo Laboral de Indiana</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Llmada gratuita: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Lunes a Viernes de 8:00 a 16:30 hrs. CERRADO los días festivos estatales. {br}Interpretación disponible a pedido.{br}<in_link>unemployment.in.gov</in_link>{br}`,
      more_info_text_404: 'Más información',
      moreinfo_link1:
        '<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>',
      invalid_url_para3: `Así es como comunicarse con la División de Seguro de Desempleo del <b>Departartamento de Desarrollo Laboral de Indiana</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Llmada gratuita: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Lunes a Viernes de 8:00 a 16:30 hrs. CERRADO los días festivos estatales. {br}Interpretación disponible a pedido.{br}<in_link>unemployment.in.gov</in_link>{br}`,
      moreinfo_link2_invalid: ' ',
      more_info_text_invalid: 'Más información',
      moreinfo_link1_invalid:
        '<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>',
      invalid_url_para4: ' ',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departartamento de Desarrollo Laboral de Indiana.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Departartamento de Desarrollo Laboral de Indiana, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Departartamento de Desarrollo Laboral de Indiana siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departartamento de Desarrollo Laboral de Indiana a través de USPS',
    },
  },
  IA: {
    name: 'Iowa',
    stateCode: 'IA',
  },
  KS: {
    name: 'Kansas',
    stateCode: 'KS',
    showVerificationButton: false,
    chooseDifferentVerificationButton_USPS: true,
    showErrorPageButton_HitaWall: false,
    showLanguageDropdown: true,
    // Fraud Modal
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Kansas Unemployment Insurance Contact Center.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Kansas Unemployment Insurance Contact Center, <b>please file a report</b> of potential unemployment identity fraud with Kansas Unemployment Insurance Contact Center by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to confirm your identity for your unemployment claim with <b>Kansas Unemployment Insurance Contact Center</b> through USPS.',
    // UICP(Choice Page)
    uicp_identity_footer_text: ' ',
    uicp_state_title: `Here's how to reach the <b>Kansas Unemployment Insurance Contact Center</b>{br} if you have questions about unemployment benefits or other methods to verify your identity:{br}`,
    uicp_state_number:
      '(785) 575-1460 or  (800) 292-6333{br}Monday - 8:00 a.m. to 4:00 p.m.{br}Tuesday - 8:00 a.m. to 4:00 p.m.{br}Wednesday - 8:00 a.m. to 4:00 p.m.{br}Thursday - 8:00 a.m. to 3:15 p.m.{br}Friday - 8:00 a.m. to 4:00 p.m.{br}CLOSED on State Holidays{br}',
    uicp_state_email: '<u>www.GetKansasBenefits.gov</u>{br}{br}{br}',
    unemployment_question1: ' ',
    // usps-description component
    required_docs_heading: ' We use the address on your ID for verification.',
    redirect_email: 'https://www.getkansasbenefits.gov/',
    usps_state_title: ' ',
    usps_state_number:
      "Here's how to reach the <b>Kansas Unemployment Insurance Contact Center</b>{br} if you have questions about unemployment benefits or other methods to verify your identity:{br}(785) 575-1460 or  (800) 292-6333{br}",
    usps_state_timing_para:
      'Monday - 8:00 a.m. to 4:00 p.m.{br}Tuesday - 8:00 a.m. to 4:00 p.m.{br}Wednesday - 8:00 a.m. to 4:00 p.m.{br}Thursday - 8:00 a.m. to 3:15 p.m.{br}Friday - 8:00 a.m. to 4:00 p.m.{br}CLOSED on State Holidays',
    usps_state_email: '<u>www.GetKansasBenefits.gov</u>',
    postOffice_text: 'The Post Office',
    // error component
    // Error page(common key)
    login_gov_help_txt: ' ',
    login_link: ' ',
    // Invalid url
    invalid_url_para3: `Here's how to reach the <b>Kansas Unemployment Insurance Contact Center</b>{br} if you have questions about unemployment benefits or other methods to verify your identity:{br}(785) 575-1460 or  (800) 292-6333{br}Monday - 8:00 a.m. to 4:00 p.m.{br}Tuesday - 8:00 a.m. to 4:00 p.m.{br}Wednesday - 8:00 a.m. to 4:00 p.m.{br}Thursday - 8:00 a.m. to 3:15 p.m.{br}Friday - 8:00 a.m. to 4:00 p.m.{br}CLOSED on State Holidays{br}<ks_link>www.GetKansasBenefits.gov</ks_link>`,
    invalid_url_para4: ' ',
    more_info_text_invalid: 'More Information',
    moreinfo_link1_invalid:
      '<li><ks_link1>www.dol.ks.gov</ks_link1></li><li><ks_link>www.GetKansasBenefits.gov</ks_link></li>',
    // Hitawal component
    hitawall_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}{br} Please try again later or choose a different verification method.{br}Thank you for your patience and understanding.',
    hitawall_para2: ' ',
    hitawall_para4: `Here's how to reach the <b>Kansas Unemployment Insurance Contact Center</b>{br} if you have questions about unemployment benefits or other methods to verify your identity:{br}(785) 575-1460 or  (800) 292-6333{br}Monday - 8:00 a.m. to 4:00 p.m.{br}Tuesday - 8:00 a.m. to 4:00 p.m.{br}Wednesday - 8:00 a.m. to 4:00 p.m.{br}Thursday - 8:00 a.m. to 3:15 p.m.{br}Friday - 8:00 a.m. to 4:00 p.m.{br}CLOSED on State Holidays{br}<ks_link>www.GetKansasBenefits.gov</ks_link>{br}{br}`,
    hitawall_more_info_text: 'More Information',
    hitawall_para5:
      '<li><ks_link1>www.dol.ks.gov</ks_link1></li><li><ks_link>www.GetKansasBenefits.gov</ks_link></li>',
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    // Error-500
    error_500_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.',
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para3: `{br}Here's how to reach the <b>Kansas Unemployment Insurance Contact Center</b>{br} if you have questions about unemployment benefits or other methods to verify your identity:{br}(785) 575-1460 or  (800) 292-6333{br}Monday - 8:00 a.m. to 4:00 p.m.{br}Tuesday - 8:00 a.m. to 4:00 p.m.{br}Wednesday - 8:00 a.m. to 4:00 p.m.{br}Thursday - 8:00 a.m. to 3:15 p.m.{br}Friday - 8:00 a.m. to 4:00 p.m.{br}CLOSED on State Holidays{br}<ks_link>www.GetKansasBenefits.gov</ks_link>{br}{br}`,
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: 'More Information',
    moreinfo_link1_500:
      '<li><ks_link1>www.dol.ks.gov</ks_link1></li><li><ks_link>www.GetKansasBenefits.gov</ks_link></li>',
    // Page not found(404)
    error404_para1:
      '{br}We can’t find the page you are looking for. Please check your URL or return to the ',
    error404_para2: `Here's how to reach the <b>Kansas Unemployment Insurance Contact Center</b>{br} if you have questions about unemployment benefits or other methods to verify your identity:{br}(785) 575-1460 or  (800) 292-6333{br}Monday - 8:00 a.m. to 4:00 p.m.{br}Tuesday - 8:00 a.m. to 4:00 p.m.{br}Wednesday - 8:00 a.m. to 4:00 p.m.{br}Thursday - 8:00 a.m. to 3:15 p.m.{br}Friday - 8:00 a.m. to 4:00 p.m.{br}CLOSED on State Holidays{br}<ks_link>www.GetKansasBenefits.gov</ks_link>{br}{br}`,
    more_info_text_404: 'More Information',
    moreinfo_link1:
      '<li><ks_link1>www.dol.ks.gov</ks_link1></li><li><ks_link>www.GetKansasBenefits.gov</ks_link></li>',
    // Out of network(No post office found)
    out_of_network_para2: `{br}Here's how to reach the <b>Kansas Unemployment Insurance Contact Center</b>{br} if you have questions about unemployment benefits or other methods to verify your identity:{br}(785) 575-1460 or  (800) 292-6333{br}Monday - 8:00 a.m. to 4:00 p.m.{br}Tuesday - 8:00 a.m. to 4:00 p.m.{br}Wednesday - 8:00 a.m. to 4:00 p.m.{br}Thursday - 8:00 a.m. to 3:15 p.m.{br}Friday - 8:00 a.m. to 4:00 p.m.{br}CLOSED on State Holidays{br}<ks_link>www.GetKansasBenefits.gov</ks_link>{br}{br}`,
    out_of_network_more_info_text: 'More Information',
    out_of_network_para3:
      '<li><ks_link1>www.dol.ks.gov</ks_link1></li><li><ks_link>www.GetKansasBenefits.gov</ks_link></li>',
    out_of_network_para4: ' ',
    out_of_network_para_text1:
      'If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.{br}{br}',
    out_of_network_para1:
      '{br}If the information you have entered is correct and matches your government issued identification, you can choose a different verification method.',
    es: {
      uicp_identity_footer_text:
        'Puede comunicarse con el Centro de Contacto del Seguro de Desempleo de Kansas si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(785) 575-1460 o (800) 292-6333{br}Lunes - 8:00 am a 4:00 pm{br}Martes - 8:00 am a 4:00 pm{br}Miércoles - 8:00 am a 4:00 pm{br}Jueves - 8:00 am a 3:15 pm{br}Viernes - 8:00 am a 4:00 pm{br}CERRADO los días festivos estatales{br}<ks_link>www.GetKansasBenefits.gov</ks_link>',
      unemployment_question2: ' ',
      uicp_state_email: ' ',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Centro de Contacto del Seguro de Desempleo de Kansas.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Centro de Contacto del Seguro de Desempleo de Kansas, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Centro de Contacto del Seguro de Desempleo de Kansas siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      uicp_state_title: ' ',
      uicp_state_number: ' ',
      usps_state_number:
        'Puede comunicarse con el <b>Centro de Contacto del Seguro de Desempleo de Kansas</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}',
      usps_state_timing_para:
        '(785) 575-1460 o (800) 292-6333{br}Lunes - 8:00 am a 4:00 pm{br}Martes - 8:00 am a 4:00 pm{br}Miércoles - 8:00 am a 4:00 pm{br}Jueves - 8:00 am a 3:15 pm{br}Viernes - 8:00 am a 4:00 pm{br}CERRADO los días festivos estatales{br}',
      usps_state_email: '<u>www.GetKansasBenefits.gov</u>',
      postOffice_text: 'Post Office<sup>TM</sup>',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. {br}{br} Gracias por su paciencia y comprensión{br}{br}',
      hitawall_para2:
        'Puede comunicarse con el Centro de Contacto del Seguro de Desempleo de Kansas si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(785) 575-1460 o (800) 292-6333{br}Lunes - 8:00 am a 4:00 pm{br}Martes - 8:00 am a 4:00 pm{br}Miércoles - 8:00 am a 4:00 pm{br}Jueves - 8:00 am a 3:15 pm{br}Viernes - 8:00 am a 4:00 pm{br}CERRADO los días festivos estatales{br}<ks_link>www.GetKansasBenefits.gov</ks_link>',
      hitawall_para3: ' ',
      hitawall_para4: ' ',
      hitawall_more_info_text: 'Más información',
      hitawall_para5:
        '<li><ks_link1>www.dol.ks.gov</ks_link1></li><li><ks_link>www.GetKansasBenefits.gov</ks_link></li>',
      hitawall_para6: ' ',
      hitawall_para7: ' ',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_para2:
        '{br}Puede comunicarse con el Centro de Contacto del Seguro de Desempleo de Kansas si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(785) 575-1460 o (800) 292-6333{br}Lunes - 8:00 am a 4:00 pm{br}Martes - 8:00 am a 4:00 pm{br}Miércoles - 8:00 am a 4:00 pm{br}Jueves - 8:00 am a 3:15 pm{br}Viernes - 8:00 am a 4:00 pm{br}CERRADO los días festivos estatales{br}<ks_link>www.GetKansasBenefits.gov</ks_link>',
      out_of_network_more_info_text: 'Más información',
      error_500_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. ',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para3: `{br}Puede comunicarse con el Centro de Contacto del Seguro de Desempleo de Kansas si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(785) 575-1460 o (800) 292-6333{br}Lunes - 8:00 am a 4:00 pm{br}Martes - 8:00 am a 4:00 pm{br}Miércoles - 8:00 am a 4:00 pm{br}Jueves - 8:00 am a 3:15 pm{br}Viernes - 8:00 am a 4:00 pm{br}CERRADO los días festivos estatales{br}<ks_link>www.GetKansasBenefits.gov</ks_link>`,
      error_500_more_info_text1: ' ',
      error_500_more_info_text2: 'Más información',
      moreinfo_link2_invalid: ' ',
      error404_para1:
        'Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de ',
      choice_page_link: 'opciones de verificación de identificación.',
      error404_para2: `{br}Puede comunicarse con el Centro de Contacto del Seguro de Desempleo de Kansas si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(785) 575-1460 o (800) 292-6333{br}Lunes - 8:00 am a 4:00 pm{br}Martes - 8:00 am a 4:00 pm{br}Miércoles - 8:00 am a 4:00 pm{br}Jueves - 8:00 am a 3:15 pm{br}Viernes - 8:00 am a 4:00 pm{br}CERRADO los días festivos estatales{br}<ks_link>www.GetKansasBenefits.gov</ks_link>`,
      more_info_text_404: 'Más información',
      moreinfo_link2: ' ',
      invalid_url_para3:
        '{br}Puede comunicarse con el Centro de Contacto del Seguro de Desempleo de Kansas si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(785) 575-1460 o (800) 292-6333{br}Lunes - 8:00 am a 4:00 pm{br}Martes - 8:00 am a 4:00 pm{br}Miércoles - 8:00 am a 4:00 pm{br}Jueves - 8:00 am a 3:15 pm{br}Viernes - 8:00 am a 4:00 pm{br}CERRADO los días festivos estatales{br}<ks_link>www.GetKansasBenefits.gov</ks_link>',
      more_info_text_invalid: 'Más información',
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Centro de Contacto del Seguro de Desempleo de Kansas a través de USPS',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
    },
  },
  KY: {
    name: 'Kentucky',
    stateCode: 'KY',
    showVerificationButton: false,
    chooseDifferentVerificationButton_USPS: false,
    showErrorPageButton_HitaWall: false,
    showLanguageDropdown: true,
    // Fraud Modal
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Kentucky Office of Unemployment Insurance.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Kentucky Office of Unemployment Insurance, <b>please file a report</b> of potential unemployment identity fraud with Kentucky Office of Unemployment Insurance by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim</b> with Kentucky Office of Unemployment Insurance through USPS.',

    uicp_identity_footer_text:
      'Have questions about unemployment benefits? Need help with verifying your identity? Contact us for assistance:{br}',
    uicp_state_title: '<b>Kentucky Office of Unemployment Insurance</b>{br}',
    uicp_state_number:
      '(502) 564-2900 or toll-free at 1-800-648-6057 for telecommunications relay service{br}Monday to Friday, 9:00 a.m. – 5:00 p.m. Eastern Time',
    uicp_state_email: '{br}{br}',

    required_docs_heading: 'We use the address on your ID for verification.',
    required_docs_para:
      'If you live somewhere else or your ID has no address listed, you will also need proof of address:',
    usps_state_timing_para:
      '<b>Kentucky Office of Unemployment Insurance</b>{br}(502) 564-2900 or toll-free at 1-800-648-6057 for telecommunications relay service{br}Monday to Friday, 9:00 a.m. – 5:00 p.m. Eastern Time',
    // Out of network
    out_of_network_para2:
      'Have questions about unemployment benefits? Need help with verifying your identity? Contact us for assistance:{br}{br}<b>Kentucky Office of Unemployment Insurance</b>{br}(502) 564-2900 or toll-free at 1-800-648-6057 for telecommunications relay service{br}Monday to Friday, 9:00 a.m. – 5:00 p.m. Eastern Time',
    out_of_network_more_info_text: 'More Information',
    out_of_network_para3:
      '<li><ky_link1>Find my local Kentucky Career Center office</ky_link1></li><li><ky_link2>UI Claimant Guide (Rights and Responsibilities)</ky_link2></li>{br}',
    // Hitawal component
    hitawall_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}{br} Please try again later.{br}Thank you for your patience and understanding.',
    hitawall_para2: ' ',
    hitawall_para4:
      'Have questions about unemployment benefits? Need help with verifying your identity? Contact us for assistance:{br}{br}<b>Kentucky Office of Unemployment Insurance</b>{br}(502) 564-2900 or toll-free at 1-800-648-6057 for telecommunications relay service{br}Monday to Friday, 9:00 a.m. – 5:00 p.m. Eastern Time',
    hitawall_more_info_text: 'More Information',
    hitawall_para5:
      '<li><ky_link1>Find my local Kentucky Career Center office</ky_link1></li><li><ky_link2>UI Claimant Guide (Rights and Responsibilities)</ky_link2></li>{br}',
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    // Error-500
    error_500_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.',
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para3:
      'Have questions about unemployment benefits? Need help with verifying your identity? Contact us for assistance:{br}{br}<b>Kentucky Office of Unemployment Insurance</b>{br}(502) 564-2900 or toll-free at 1-800-648-6057 for telecommunications relay service{br}Monday to Friday, 9:00 a.m. – 5:00 p.m. Eastern Time',
    login_gov_help_txt: ' ',
    login_link: ' ',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: 'More Information',
    moreinfo_link1_500:
      '<li><ky_link1>Find my local Kentucky Career Center office</ky_link1></li><li><ky_link2>UI Claimant Guide (Rights and Responsibilities)</ky_link2></li>',
    // Page not found(404)
    error404_para1:
      '{br}We can’t find the page you are looking for. Please check your URL or return to the ',
    error404_para2:
      'Have questions about unemployment benefits? Need help with verifying your identity? Contact us for assistance:{br}{br}<b>Kentucky Office of Unemployment Insurance</b>{br}(502) 564-2900 or toll-free at 1-800-648-6057 for telecommunications relay service{br}Monday to Friday, 9:00 a.m. – 5:00 p.m. Eastern Time',
    more_info_text_404: 'More Information',
    moreinfo_link1:
      '<li><ky_link1>Find my local Kentucky Career Center office</ky_link1></li><li><ky_link2>UI Claimant Guide (Rights and Responsibilities)</ky_link2></li>',
    // Invalid
    invalid_url_para3:
      'Have questions about unemployment benefits? Need help with verifying your identity? Contact us for assistance:{br}{br}<b>Kentucky Office of Unemployment Insurance</b>{br}(502) 564-2900 or toll-free at 1-800-648-6057 for telecommunications relay service{br}Monday to Friday, 9:00 a.m. – 5:00 p.m. Eastern Time',
    invalid_url_para4: ' ',
    more_info_text_invalid: 'More Information',
    moreinfo_link1_invalid:
      '<li><ky_link1>Find my local Kentucky Career Center office</ky_link1></li><li><ky_link2>UI Claimant Guide (Rights and Responsibilities)</ky_link2></li>',
    es: {
      // Fraud Modal
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Oficina de Seguro de Desempleo de Kentucky.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Oficina de Seguro de Desempleo de Kentucky, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Oficina de Seguro de Desempleo de Kentucky siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Oficina de Seguro de Desempleo de Kentucky a través de USPS',
      uicp_identity_footer_text:
        '¿Tiene preguntas sobre las prestaciones por desempleo? ¿Necesita ayuda para verificar su identidad? Contáctenos para obtener ayuda:{br}',
      uicp_state_title: '<b>Oficina de Seguro de Desempleo de Kentucky</b>{br}',
      uicp_state_number:
        '(502) 564-2900 o gratis al 1-800-648-6057 para servicio de retransmisión de telecomunicaciones{br}Lunes a viernes, 9:00 a.m. – 5:00 p.m. hora del este',
      uicp_state_email: '{br}{br}',
      unemployment_question2: ' ',
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_docs_para:
        'Si no tiene dirección o su dirección es diferente de la que esta declarada en su documento de identificación, necesita presentar comprobante de domicilio, por ejemplo: ',
      postOffice_text: 'The Post Office<sup>TM</sup>',
      postOffice_span: 'no aceptará:',
      usps_state_timing_para:
        '<b>Oficina de Seguro de Desempleo de Kentucky</b>{br}(502) 564-2900 o gratis al 1-800-648-6057 para servicio de retransmisión de telecomunicaciones{br}Lunes a viernes, 9:00 a.m. – 5:00 p.m. hora del este',
      // Out of network
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.',
      out_of_network_para2:
        '¿Tiene preguntas sobre las prestaciones por desempleo? ¿Necesita ayuda para verificar su identidad? Contáctenos para obtener ayuda:{br}<b>Oficina de Seguro de Desempleo de Kentucky</b>{br}(502) 564-2900 o gratis al 1-800-648-6057 para servicio de retransmisión de telecomunicaciones{br}Lunes a viernes, 9:00 a.m. – 5:00 p.m. hora del este',
      out_of_network_more_info_text: 'Más información',
      out_of_network_para3:
        '<li><ky_link1>Encuentre mi oficina local del Centro de Carreras de Kentucky</ky_link1></li><li><ky_link2>Guía del Reclamante (Derechos y Responsabilidades)</ky_link2></li>{br}',
      // Hitawal component
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br} {br}Por favor, vuelva a intentarlo más tarde. {br} Gracias por su paciencia y comprensión.',
      hitawall_para2: ' ',
      hitawall_para4:
        '¿Tiene preguntas sobre las prestaciones por desempleo? ¿Necesita ayuda para verificar su identidad? Contáctenos para obtener ayuda:{br}{br}<b>Oficina de Seguro de Desempleo de Kentucky</b>{br}(502) 564-2900 o gratis al 1-800-648-6057 para servicio de retransmisión de telecomunicaciones{br}Lunes a viernes, 9:00 a.m. – 5:00 p.m. hora del este',
      hitawall_more_info_text: 'Más información',
      hitawall_para5:
        '<li><ky_link1>Encuentre mi oficina local del Centro de Carreras de Kentucky</ky_link1></li><li><ky_link2>Guía del Reclamante (Derechos y Responsabilidades)</ky_link2></li>{br}',
      hitawall_para6: ' ',
      hitawall_para7: ' ',
      // Error-500
      error_500_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde. {br} Gracias por su paciencia y comprensión.',
      error_500_para3:
        '¿Tiene preguntas sobre las prestaciones por desempleo? ¿Necesita ayuda para verificar su identidad? Contáctenos para obtener ayuda:{br}{br}<b>Oficina de Seguro de Desempleo de Kentucky</b>{br}(502) 564-2900 o gratis al 1-800-648-6057 para servicio de retransmisión de telecomunicaciones{br}Lunes a viernes, 9:00 a.m. – 5:00 p.m. hora del este',
      login_gov_help_txt: ' ',
      login_link: ' ',
      error_500_more_info_text1: ' ',
      error_500_more_info_text2: 'Más información',
      moreinfo_link1_500:
        '<li><ky_link1>Encuentre mi oficina local del Centro de Carreras de Kentucky</ky_link1></li><li><ky_link2>Guía del Reclamante (Derechos y Responsabilidades)</ky_link2></li>',
      // Page not found(404)
      error404_para1:
        '{br}Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de ',
      error404_para2:
        '¿Tiene preguntas sobre las prestaciones por desempleo? ¿Necesita ayuda para verificar su identidad? Contáctenos para obtener ayuda:{br}{br}<b>Oficina de Seguro de Desempleo de Kentucky</b>{br}(502) 564-2900 o gratis al 1-800-648-6057 para servicio de retransmisión de telecomunicaciones{br}Lunes a viernes, 9:00 a.m. – 5:00 p.m. hora del este',
      more_info_text_404: 'Más información',
      moreinfo_link1:
        '<li><ky_link1>Encuentre mi oficina local del Centro de Carreras de Kentucky</ky_link1></li><li><ky_link2>Guía del Reclamante (Derechos y Responsabilidades)</ky_link2></li>',
      moreinfo_link2: ' ',
      // Invalid
      invalid_url_para3:
        '¿Tiene preguntas sobre las prestaciones por desempleo? ¿Necesita ayuda para verificar su identidad? Contáctenos para obtener ayuda:{br}{br}<b>Oficina de Seguro de Desempleo de Kentucky</b>{br}(502) 564-2900 o gratis al 1-800-648-6057 para servicio de retransmisión de telecomunicaciones{br}Lunes a viernes, 9:00 a.m. – 5:00 p.m. hora del este',
      invalid_url_para4: ' ',
      more_info_text_invalid: 'Más información',
      moreinfo_link1_invalid:
        '<li><ky_link1>Encuentre mi oficina local del Centro de Carreras de Kentucky</ky_link1></li><li><ky_link2>Guía del Reclamante (Derechos y Responsabilidades)</ky_link2></li>',
    },
  },
  LA: {
    name: 'Louisiana',
    stateCode: 'LA',
  },
  ME: {
    name: 'Maine',
    stateCode: 'ME',
    showVerificationButton: false,
    chooseDifferentVerificationButton_USPS: false,
    showErrorPageButton_HitaWall: false,
    showLanguageDropdown: true,
    // english
    // uicp component
    // Fraud Modal
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim</b> with State of Maine Bureau of Unemployment Compensation.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with State of Maine Bureau of Unemployment Compensation, <b>please file a report</b> of potential unemployment identity fraud with State of Maine Bureau of Unemployment Compensation by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim</b> with State of Maine Bureau of Unemployment Compensation through USPS.',
    // usps-description component
    required_docs_heading: ' We use the address on your ID for verification.',
    redirect_email: 'https://assist.reemployme.maine.gov/cp/landing',
    usps_state_title:
      '<b>State of Maine Bureau of Unemployment Compensation</b>',
    usps_state_number: 'Toll-Free: 1-800-593-7660{br}',
    usps_state_timing_para:
      '(Customer service available Mon-Fri, 8:00 a.m. to 3:00 p.m.){br}TTY/Relay for Deaf and hard of hearing: Maine relay 711',
    uicp_identity_footer_text:
      'Contact the Maine Department of Labor for any questions.{br}',
    uicp_state_title:
      '<b>State of Maine Bureau of Unemployment Compensation</b>{br}',
    uicp_state_number:
      'Toll-Free: 1-800-593-7660{br}(Customer service available Mon-Fri, 8:00 a.m. to 3:00 p.m.){br}TTY/Relay for Deaf and hard of hearing: Maine relay 711{br}',
    uicp_state_email: '<u>Submit an Online Inquiry</u>{br}{br}{br}',
    usps_state_email: '<u>Submit an Online Inquiry</u>',
    enrollment_description_para1:
      'You’re about to confirm your identity for your <b>unemployment claim</b> with USPS. You will receive a copy of this from unemploymentverify-donotreply@dol.gov. The subject line will be "Verify your identity for your Maine Unemployment Claim." If you do not receive this email, please check your spam or junk mail folder. Bring this page or the email with you to the Post Office<sup>TM</sup>. You can also show a screenshot of the barcode.',
    enrollment_description_para2: `If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.`,
    unemployment_question1: ' ',
    login_gov_help_txt: ' ',
    login_link: ' ',
    postOffice_text: 'The Post Office',
    // error component
    // Hitawal component
    hitawall_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}{br} Please try again later.{br}Thank you for your patience and understanding.',
    hitawall_para2: ' ',
    hitawall_para4:
      'Contact the Maine Department of Labor for any questions.{br}{br}<b>State of Maine Bureau of Unemployment Compensation</b>{br}Toll-Free: 1-800-593-7660{br}(Customer service available Mon-Fri, 8:00 a.m. to 3:00 p.m.){br}TTY/Relay for Deaf and hard of hearing: Maine relay 711{br}<me_link>Submit an Online Inquiry</me_link>{br}',
    hitawall_more_info_text: 'More Information',
    hitawall_para5:
      '<li><me_link1>State of Maine Unemployment Website</me_link1></li><li><me_link2>Fraud Information</me_link2></li><li><me_link3>Find Your Local CareerCenter</me_link3></li>',
    // error-500
    error_500_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.',
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para3:
      '{br}Contact the Maine Department of Labor for any questions.{br}{br}<b>State of Maine Bureau of Unemployment Compensation</b>{br}Toll-Free: 1-800-593-7660{br}(Customer service available Mon-Fri, 8:00 a.m. to 3:00 p.m.){br}TTY/Relay for Deaf and hard of hearing: Maine relay 711{br}<me_link>Submit an Online Inquiry</me_link>{br}{br}',
    error_500_more_info_text2: 'More Information',
    moreinfo_link1_500:
      '<li><me_link1>State of Maine Unemployment Website</me_link1></li><li><me_link2>Fraud Information</me_link2></li><li><me_link3>Find Your Local CareerCenter</me_link3></li>',
    // page not found
    error404_para1:
      '{br}We can’t find the page you are looking for. Please check your URL or return to the ',
    error404_para2:
      'Contact the Maine Department of Labor for any questions.{br}{br}<b>State of Maine Bureau of Unemployment Compensation</b>{br}Toll-Free: 1-800-593-7660{br}(Customer service available Mon-Fri, 8:00 a.m. to 3:00 p.m.){br}TTY/Relay for Deaf and hard of hearing: Maine relay 711{br}<me_link>Submit an Online Inquiry</me_link>{br}{br}',
    more_info_text_404: 'More Information',
    moreinfo_link1:
      '<li><me_link1>State of Maine Unemployment Website</me_link1></li><li><me_link2>Fraud Information</me_link2></li><li><me_link3>Find Your Local CareerCenter</me_link3></li>',
    // invalid url
    invalid_url_para3:
      'Contact the Maine Department of Labor for any questions.{br}{br}<b>State of Maine Bureau of Unemployment Compensation</b>{br}Toll-Free: 1-800-593-7660{br}(Customer service available Mon-Fri, 8:00 a.m. to 3:00 p.m.){br}TTY/Relay for Deaf and hard of hearing: Maine relay 711{br}<me_link>Submit an Online Inquiry</me_link>',
    more_info_text_invalid: 'More Information',
    moreinfo_link1_invalid:
      '<li><me_link1>State of Maine Unemployment Website</me_link1></li><li><me_link2>Fraud Information</me_link2></li><li><me_link3>Find Your Local CareerCenter</me_link3></li>',
    // out of network
    out_of_network_para2:
      '{br}Contact the Maine Department of Labor for any questions.{br}{br}<b>State of Maine Bureau of Unemployment Compensation</b>{br}Toll-Free: 1-800-593-7660{br}(Customer service available Mon-Fri, 8:00 a.m. to 3:00 p.m.){br}TTY/Relay for Deaf and hard of hearing: Maine relay 711{br}<me_link>Submit an Online Inquiry</me_link>{br}{br}',
    out_of_network_more_info_text: 'More Information',
    out_of_network_para3:
      '<li><me_link1>State of Maine Unemployment Website</me_link1></li><li><me_link2>Fraud Information</me_link2></li><li><me_link3>Find Your Local CareerCenter</me_link3></li>{br}{br}',
    invalid_url_para4: ' ',
    error_500_more_info_text1: ' ',
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    out_of_network_para4: ' ',
    out_of_network_para_text1:
      'If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.{br}{br}',
    out_of_network_para1:
      '{br}If the information you have entered is correct and matches your government issued identification, you can choose a different verification method.',
    heading_helper_text:
      'Enter your name and address exactly as you entered it in ReEmployME.',
    es: {
      // Fraud Modal
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Oficina de Compensación de Desempleo del Estado de Maine.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Oficina de Compensación de Desempleo del Estado de Maine, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Oficina de Compensación de Desempleo del Estado de Maine siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Oficina de Compensación de Desempleo del Estado de Maine a través de USPS.',
      // UICP page
      identity_text: ' ',
      unemployment_question1: ' ',
      unemployment_question2: ' ',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      uicp_identity_footer_text: `Comuníquese con el Departamento de Trabajo de Maine si tiene alguna pregunta.{br}`,
      uicp_state_title:
        '<b>Oficina de Compensación por Desempleo del Estado de Maine</b>{br}',
      uicp_state_number: `Llamada gratuita: 1-800-593-7660{br}(Servicio al cliente disponible de lunes a viernes de 8:00 a.m. a 3:00 p.m.){br}TTY/Relé para personas sordas y con problemas de audición: Relé 711 de Maine{br}<me_link><u>Enviar una consulta en línea</u></me_link>{br}{br}{br}`,
      uicp_state_email: ' ',
      // AddressSearch Component
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      // Enrollment component
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      enrollment_description_para1: `Estás a punto de confirmar tu identidad para tu <b>reclamo de desempleo</b> con USPS. Recibirás una copia de este correo electrónico de unemploymentverify-donotreply@dol.gov. La línea de asunto será "Verifique su identidad para su reclamo de desempleo de Maine". Si no recibes este correo electrónico, revise su carpeta de spam o correo no deseado. Trae esta página o el correo electrónico contigo a la oficina de Post Office<sup>TM</sup>. También puedes mostrar una captura de pantalla del código de barras.`,
      enrollment_description_para2: `Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.`,
      // usps-description component
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      postOffice_text: 'Post Office<sup>TM</sup> ',
      usps_state_title:
        '<b>Oficina de Compensación por Desempleo del Estado de Maine</b>',
      usps_state_number: 'Llamada gratuita: 1-800-593-7660{br}',
      usps_state_timing_para:
        '(Servicio al cliente disponible de lunes a viernes de 8:00 a.m. a 3:00 p.m.){br}TTY/Relé para personas sordas y con problemas de audición: Relé 711 de Maine{br}',
      usps_state_email: 'Enviar una consulta en línea',
      redirect_email: 'https://assist.reemployme.maine.gov/cp/landing',
      // error component
      // Error page(common key)
      login_gov_help_txt: ' ',
      login_link: ' ',
      // Invalid url
      invalid_url_para3:
        'Comuníquese con el Departamento de Trabajo de Maine si tiene alguna pregunta.{br}{br}<b>Oficina de Compensación por Desempleo del Estado de Maine</b>{br}Llamada gratuita: 1-800-593-7660{br}(Servicio al cliente disponible de lunes a viernes de 8:00 a.m. a 3:00 p.m.){br}TTY/Relé para personas sordas y con problemas de audición: Relé 711 de Maine{br}<me_link><u>Enviar una consulta en línea</u></me_link>',
      invalid_url_para4: ' ',
      more_info_text_invalid: 'Más información',
      moreinfo_link1_invalid:
        '<li><me_link1>Sitio web de desempleo del estado de Maine</me_link1></li><li><me_link2>Información sobre fraudes</me_link2></li><li><me_link3>Encuentre su CareerCenter local</me_link3></li>',
      moreinfo_link2_invalid: ' ',
      // Hitawal component
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      hitawall_para4:
        'Comuníquese con el Departamento de Trabajo de Maine si tiene alguna pregunta.{br}{br}<b>Oficina de Compensación por Desempleo del Estado de Maine</b>{br}Llamada gratuita: 1-800-593-7660{br}(Servicio al cliente disponible de lunes a viernes de 8:00 a.m. a 3:00 p.m.){br}TTY/Relé para personas sordas y con problemas de audición: Relé 711 de Maine{br}<me_link><u>Enviar una consulta en línea</u></me_link>{br}',
      hitawall_para3: ' ',
      hitawall_more_info_text: 'Más información',
      hitawall_para5:
        '<li><me_link1>Sitio web de desempleo del estado de Maine</me_link1></li><li><me_link2>Información sobre fraudes</me_link2></li><li><me_link3>Encuentre su CareerCenter local</me_link3></li>',
      // Out of network(No post office found)
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_no_participate_para1:
        'No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_no_participate_para2: ' ',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para2:
        'Comuníquese con el Departamento de Trabajo de Maine si tiene alguna pregunta.{br}{br}<b>Oficina de Compensación por Desempleo del Estado de Maine</b>{br}Llamada gratuita: 1-800-593-7660{br}(Servicio al cliente disponible de lunes a viernes de 8:00 a.m. a 3:00 p.m.){br}TTY/Relé para personas sordas y con problemas de audición: Relé 711 de Maine{br}<me_link><u>Enviar una consulta en línea</u></me_link>{br}',
      out_of_network_more_info_text: 'Más información',
      out_of_network_para3:
        '<li><me_link1>Sitio web de desempleo del estado de Maine</me_link1></li><li><me_link2>Información sobre fraudes</me_link2></li><li><me_link3>Encuentre su CareerCenter local</me_link3></li>{br}{br}',
      // Error-500
      error_500_more_info_text1: ' ',
      error_500_more_info_text2: 'Más información',
      error_500_title: 'Tenemos problemas para conectar ',
      error_500_heading:
        'La verificación de su identidad no está disponible actualmente.',
      error_500_para1:
        '{br}Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. ',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para3:
        'Comuníquese con el Departamento de Trabajo de Maine si tiene alguna pregunta.{br}{br}<b>Oficina de Compensación por Desempleo del Estado de Maine</b>{br}Llamada gratuita: 1-800-593-7660{br}(Servicio al cliente disponible de lunes a viernes de 8:00 a.m. a 3:00 p.m.){br}TTY/Relé para personas sordas y con problemas de audición: Relé 711 de Maine{br}<me_link><u>Enviar una consulta en línea</u></me_link>{br}{br}',
      moreinfo_link1_500:
        '<li><me_link1>Sitio web de desempleo del estado de Maine</me_link1></li><li><me_link2>Información sobre fraudes</me_link2></li><li><me_link3>Encuentre su CareerCenter local</me_link3></li>',
      // Page not found(404)
      error404_para1:
        '{br}Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de ',
      choice_page_link: 'opciones de verificación de identificación.',
      error404_para2: `Comuníquese con el Departamento de Trabajo de Maine si tiene alguna pregunta.{br}{br}<b>Oficina de Compensación por Desempleo del Estado de Maine</b>{br}Llamada gratuita: 1-800-593-7660{br}(Servicio al cliente disponible de lunes a viernes de 8:00 a.m. a 3:00 p.m.){br}TTY/Relé para personas sordas y con problemas de audición: Relé 711 de Maine{br}<me_link><u>Enviar una consulta en línea</u></me_link>{br}{br}`,
      more_info_text_404: 'Más información',
      more_info_text1: ' ',
      moreinfo_link1:
        '<li><me_link1>Sitio web de desempleo del estado de Maine</me_link1></li><li><me_link2>Información sobre fraudes</me_link2></li><li><me_link3>Encuentre su CareerCenter local</me_link3></li>',
      moreinfo_link2: ' ',
      moreinfo_link3: ' ',
      heading_helper_text:
        'Ingrese su nombre y dirección exactamente como los ingresó en ReEmployME.',
    },
  },
  MH: {
    name: 'Marshall Islands',
    stateCode: 'MH',
  },
  MD: {
    name: 'Maryland',
    stateCode: 'MD',
    showLanguageDropdown: true,
    showSameStateContent: true,
    showIdDetailsAtUICP: true,
    // Fraud Modal:
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Maryland Department of Labor.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Maryland Department of Labor, <b>please file a report</b> of potential unemployment identity fraud with Maryland Department of Labor by following the steps at <md_fraud_link>https://www.labor.md.gov/employment/uistopfraud.shtml.</md_fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Maryland Department of Labor through USPS.',
    // Choice Screen:
    uicp_identity_footer_text: ' ',
    required_id_details: `{br}<b>Required identity documents (Expired IDs are not accepted)</b>{br}{br}<b>Accepted ID documents</b>{br}<li>State driver’s license</li><li>State ID card</li><li>U.S. Armed Forces or uniformed service ID card (requires secondary ID)</li><li>U.S. Passport (requires secondary ID)</li>{br}<b>We use the address on your ID for verification.</b> If you live somewhere else or your ID has no address listed, you will also need proof of address:{br}<li>Lease, mortgage, or deed of trust</li><li>Voter registration card</li><li>Vehicle registration card</li>{br}<b>The Post Office<sup>TM</sup><u> will not accept:</u></b>{br}<li>Social security cards</li><li>Credit cards or utility bills</li><li>State School IDs</li><li>Medical marijuana cards</li><li>Concealed carry licenses</li><li>Expired forms of ID</li>{br}`,
    // State Content:
    state_content_para_header: `Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}{br}`,
    state_content_para_info1:
      '<b>Maryland Department of Labor, Division of Unemployment Insurance</b>{br}667-207-6520{br}Monday to Friday, 8:00 a.m. - 4:00 p.m. Eastern Time{br}',
    state_content_para_info2: ' ',
    state_more_information_header: 'More Information',
    state_more_information_para:
      '<li><md_link1>Resources for new claimants</md_link1></li><li><md_link2>Claimant FAQs</md_link2></li><li><md_link3>Your Unemployment Insurance Handbook</md_link3></li>{br}',
    // Login Confirmation: No keys needed for MD(login.gov option unavailable)
    // Login Unsuccessful: No keys needed for MD(login.gov option unavailable)
    // Logout Page: No keys needed for MD(login.gov option unavailable)
    // usps-description component
    required_Identity_documents:
      'Required identity documents (Expired IDs are not accepted)',
    required_docs_heading: ' We use the address on your ID for verification.',
    online_radiobutton_doc_list_text_second: '<li>State ID card</li>',
    online_radiobutton_doc_list_text_first: '<li>State driver’s license</li>',
    reqired_docs_list_home: ' ',
    // Error Pages:
    // Invalid url-No Keys needed with shared component:
    // HitaWall:
    hitawall_para2:
      '{br}Please try again later or choose a different verification method.{br}Thank you for your patience and understanding.',
    // OutOfNetwork(No Post office)-No Keys needed with shared component:
    // 404:
    error404_para1:
      '{br}We can’t find the page you are looking for. Please check your URL or return to the ',
    // 500:
    error_500_para2:
      'Please try again later. {br}Thank you for your patience and understanding.',
    login_gov_help_txt: ' ',
    login_link: ' ',
    es: {
      // Fraud Modal
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Trabajo de Maryland.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Departamento de Trabajo de Maryland, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Departamento de Trabajo de Maryland siguiendo los pasos en <md_fraud_link>https://www.labor.md.gov/employment/uistopfraud.shtml</md_fraud_link>.',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Trabajo de Maryland a través de USPS.',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      required_id_details: `{br}<b>Documentos de Identificación aceptables (No se aceptan identificaciones vencidas)</b>{br}{br}<b>Formas aceptables de identificación:</b>{br}<li>Licencia de conducir</li><li>Tarjeta de identificación emitida por su estado</li><li>Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera {br}de Estados Unidos (necesita otra identificación)</li><li>Pasaporté estadunidense (necesita otra identificación)</li>{br}<b>Utilizamos la dirección declarada en su identificación para verificar su identidad.</b> Si no tiene dirección o su dirección es diferente de la que esta declarada en su documento de identificación, necesita presentar comprobante de domicilio, por ejemplo: {br}<li>Contrato de arrendamiento, hipoteca, escritura del domicilio o el fideicomiso</li><li>Tarjeta de registro de votantes</li><li>Tarjeta de registro del vehículo</li>{br}<b>Post Office<sup>TM</sup><u> no aceptará::</u></b>{br}<li>Tarjetas de seguro social</li><li>Tarjetas de crédito o facturas "de utilidades"</li><li>Identificación de escuelas</li><li>Tarjetas de marihuana medicinal</li><li>Licencias de portacion de armas ocultas</li><li>Formas de identificación caducadas (expiradas)</li>{br}`,
      state_content_para_header: `Si tiene preguntas sobre los beneficios de desempleo o necesita ayuda para verificar su identidad, puede comunicarse con nosotros de la siguiente manera:{br}{br}`,
      state_content_para_info1:
        '<b>Departamento de Trabajo de Maryland, División de Seguro de Desempleo</b>{br}667-207-6520{br}Lunes a Viernes, 8:00 a.m. - 4:00 p.m., hora del Este{br}',
      state_content_para_info2: ' ',
      state_more_information_header: 'Más información',
      state_more_information_para:
        '<li><md_link1>Recursos Para Nuevos Reclamante</md_link1></li><li><md_link2>Preguntas Frecuentes de Reclamantes</md_link2></li><li><md_link3>Su Manual del Seguro de Desempleo</md_link3></li>{br}',
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      required_Identity_documents:
        'Documentos de Identificación aceptables (No se aceptan identificaciones vencidas)',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde. {br} Gracias por su paciencia y comprensión.',
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      out_of_network_para1: ' ',
      out_of_network_para2: ' ',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde. {br} Gracias por su paciencia y comprensión.',
      error404_para1:
        'Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de ',
      error404_para2: ' ',
      moreinfo_link1: ' ',
      moreinfo_link2: ' ',
    },
  },
  MA: {
    name: 'Massachusetts',
    stateCode: 'MA',
    chooseDifferentVerificationButton_USPS: true,
    showErrorPageButton_HitaWall: true,
    showVerificationButton: true,
    showLanguageDropdown: true,
    unemployment_question1:
      '{br}For more information regarding your unemployment claim, please visit: {br}<ma_link>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link>',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Department of Unemployment Assistance (DUA).',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Department of Unemployment Assistance (DUA), <b>please file a report</b> of potential unemployment identity fraud with Department of Unemployment Assistance (DUA) by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Massachusetts Department of Unemployment Assistance (DUA) through USPS.',
    confirmation_checkmark:
      ' We shared your identity verification with the state of Massachusetts Department of Unemployment Assistance (DUA) ',
    uicp_more_info_text: ' ',
    confirmation_next_step_text: 'More information',
    confirmation_steps_content_line_first: ' ',
    confirmation_steps_content_line_second:
      '<li>To log into your UI Online account, please visit{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Regarding your unemployment claim, please visit{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>{br}{br}',
    logout_intro: ' You may close this window.',
    logout_next_step_text: 'More information',
    required_docs_heading: ' We use the address on your ID for verification.',
    required_docs_para:
      'If you live somewhere else or your ID has no address listed, you will also need proof of address:',
    usps_state_timing_para:
      'For more information regarding your unemployment claim, please visit: {br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>',
    more_info_text2: 'More Information',
    error_invalid_login_moreinfo_link1: ' ',
    error_invalid_login_moreinfo_link2:
      '<li>To log into your UI Online account, please visit{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Regarding your unemployment claim, please visit{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>',
    error_500_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused. {br}{br}Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para2:
      'For more information regarding your unemployment claim, please visit: {br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>{br}',
    error_500_more_info_text2: 'More Information',
    moreinfo_link1_500: ' ',
    moreinfo_link2_500:
      '<li>To log into your UI Online account, please visit{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Regarding your unemployment claim, please visit{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>',
    error_500_more_info_text1: ' ',
    hitawall_para4:
      '{br}For more information regarding your unemployment claim, please visit: {br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>{br}',
    hitawall_more_info_text: 'More Information',
    hitawall_para5: ' ',
    out_of_network_para2:
      'For more information regarding your unemployment claim, please visit: {br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>{br}',
    out_of_network_more_info_text: 'More Information',
    error404_para2:
      'For more information regarding your unemployment claim, please visit: {br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>{br}',
    more_info_text_404: 'More Information',
    moreinfo_link1: ' ',
    moreinfo_link2:
      '<li>To log into your UI Online account, please visit{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Regarding your unemployment claim, please visit{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>',
    hitawall_para2:
      '{br}Please try again later or choose a different verification method. {br}Thank you for your patience and understanding. ',
    invalid_url_para3:
      'For more information regarding your unemployment claim, please visit: {br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
    more_info_text_invalid: 'More Information',
    moreinfo_link1_invalid: ' ',
    moreinfo_link2_invalid:
      '<li>To log into your UI Online account, please visit{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Regarding your unemployment claim, please visit{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>',
    invalid_url_para4: ' ',
    scroll_to_top_text: 'Return to top',
    hitawall_para6:
      '<li>To log into your UI Online account, please visit{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Regarding your unemployment claim, please visit{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>',
    out_of_network_para3: ' ',
    out_of_network_para4:
      '<li>To log into your UI Online account, please visit{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Regarding your unemployment claim, please visit{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>',
    moreinfo_confirmation_link1: ' ',
    moreinfo_logout_link: ' ',
    confirmation_scroll_to_top_text: 'Return to top',
    logout_scroll_to_top_text: 'Return to top{br}{br}',
    error_invalid_login_para3: ' ',
    postOffice_text: 'The Post Office',
    error404_subheading: '404 - Page not found{br}',
    heading_helper_text:
      'Enter your name and residential address exactly as you’ve entered them on your unemployment insurance online application. If your government-issued ID does not have the same residential address entered on your application, you will need to bring proof of your residential address to the participating post office location which you have selected. Please see the list of documents that can be used for proof of address ',
    heading_helper_text1: 'below',
    heading_helper_text2: '.',
    es: {
      online_radiobutton_label_text: 'En línea - Login.gov',
      online_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      online_radiobutton_doc_list_text_first:
        '<li>Documento de identidad estadual</li>',
      online_radiobutton_doc_list_text_second: ' ',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      unemployment_question1:
        '{br}Para obtener más información sobre su reclamo de seguro  de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>',
      unemployment_question2: ' ',
      uicp_state_email: ' ',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Asistencia al Desempleo (DUA).',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Departamento de Asistencia al Desempleo (DUA), <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Departamento de Asistencia al Desempleo (DUA) siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      confirmation_checkmark:
        'Compartimos su verificación de identidad con el Departamento de Asistencia de Desempleo (DUA)',
      confirmation_next_step_text: 'Más información',
      confirmation_steps_content_line_first: ' ',
      confirmation_steps_content_line_second:
        '<li>Para iniciar sesión en su cuenta de UI Online, por favor visite:{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Con respecto a su reclamo de seguro de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>{br}{br}',
      confirmation_state_title: ' ',
      logout_intro: 'Puede cerrar esta ventana.',
      logout_next_step_text: '<h3>Más información</h3>',
      scroll_to_top_text: 'Volver al principio',
      error_invalid_login_para3: ' ',
      error_invalid_login_para4: ' ',
      error_invalid_login_para5: ' ',
      error_invalid_login_moreinfo_link1: ' ',
      error_invalid_login_moreinfo_link2:
        '<li>Para iniciar sesión en su cuenta de UI Online, por favor visite:{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Con respecto a su reclamo de seguro de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>',
      more_info_text1: ' ',
      more_info_text2: 'Más información',
      error_invalid_login_para6: ' ',
      hitawall_login_subheading:
        'La verificación de su identidad con Login.gov no está disponible actualmente.',
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde o elija un método de verificación diferente. {br}Gracias por su paciencia y comprensión.',
      hitawall_para3: ' ',
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      hitawall_para4:
        '{br}Para obtener más información sobre su reclamo de seguro  de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>',
      hitawall_more_info_text: 'Más información',
      hitawall_para6:
        '<li>Para iniciar sesión en su cuenta de UI Online, por favor visite:{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Con respecto a su reclamo de seguro de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      usps_state_email: ' ',
      usps_state_timing_para:
        'Para obtener más información sobre su reclamo de seguro  de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>',
      postOffice_text: 'Post Office<sup>TM</sup>',
      postOffice_span: 'no aceptará:',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.',
      out_of_network_para1:
        'Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para2:
        'Para obtener más información sobre su reclamo de seguro  de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>{br}',
      out_of_network_more_info_text: 'Más información',
      out_of_network_para3: ' ',
      out_of_network_para4:
        '<li>Para iniciar sesión en su cuenta de UI Online, por favor visite:{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Con respecto a su reclamo de seguro de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>',
      error_500_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br} {br}Por favor, vuelva a intentarlo más tarde. {br} Gracias por su paciencia y comprensión.',
      error_500_para2:
        'Para obtener más información sobre su reclamo de seguro  de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>',
      error_500_para3:
        '<b>Para obtener ayuda con Login.gov:</b> {br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
      error_500_more_info_text2: 'Más información',
      moreinfo_link1_500: ' ',
      moreinfo_link2_500:
        '<li>Para iniciar sesión en su cuenta de UI Online, por favor visite:{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Con respecto a su reclamo de seguro de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>',
      error404_subheading: '404 - Pagina no encontrada{br}',
      error404_para2:
        'Para obtener más información sobre su reclamo de seguro  de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>{br}{br}<b>Para obtener ayuda con Login.gov:</b> {br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
      more_info_text_404: 'Más información',
      moreinfo_link1: ' ',
      moreinfo_link2:
        '<li>Para iniciar sesión en su cuenta de UI Online, por favor visite:{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Con respecto a su reclamo de seguro de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>{br}',
      invalid_url_para3:
        'Para obtener más información sobre su reclamo de seguro  de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2>{br}{br}<b>Para obtener ayuda con Login.gov:</b> {br}<login_link>https://www.login.gov/contact/</login_link>',
      more_info_text_invalid: 'Más información',
      moreinfo_link1_invalid: ' ',
      moreinfo_link2_invalid:
        '<li>Para iniciar sesión en su cuenta de UI Online, por favor visite:{br}<ma_link1>https://uionline.detma.org/Claimant/Core/Login.ASPX</ma_link1></li><li>Con respecto a su reclamo de seguro de desempleo, visite:{br}<ma_link2>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link2></li>{br}',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Asistencia al Desempleo (DUA) a través de USPS.',
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_docs_para:
        'Si no tiene dirección o su dirección es diferente de la que esta declarada en su documento de identificación, necesita presentar comprobante de domicilio, por ejemplo: ',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      heading_helper_text:
        'Ingrese su nombre y dirección residencial exactamente como los ingresó en su seguro de desempleo en línea solicitud. Si su identificación emitida por el gobierno no tiene la misma dirección residencial ingresada en su solicitud, deberá llevar un comprobante de su dirección residencial a la oficina postal participante que haya seleccionado. Consulte la lista de documentos que se pueden utilizar como comprobante de domicilio ',
      heading_helper_text1: 'abajo',
      heading_helper_text2: '.',
    },
  },
  MI: {
    name: 'Michigan',
    stateCode: 'MI',
  },
  MN: {
    name: 'Minnesota',
    stateCode: 'MN',
  },
  MS: {
    name: 'Mississippi',
    stateCode: 'MS',
  },
  MO: {
    name: 'Missouri',
    stateCode: 'MO',
  },
  MT: {
    name: 'Montana',
    stateCode: 'MT',
  },
  NE: {
    name: 'Nebraska',
    stateCode: 'NE',
    showVerificationButton: false,
    chooseDifferentVerificationButton_USPS: false,
    showErrorPageButton_HitaWall: false,
    showLanguageDropdown: true,
    workforceRedirect: true,
    // english
    // uicp component
    workforce_radiobutton_label_text: ' ',
    workforce_radiobutton_des_text: ' ',
    workforce_radiobutton_doc_list_title: ' ',
    workforce_radiobutton_doc_list_text_first: ' ',
    workforce_radiobutton_doc_list_text_second: ' ',
    workforce_radiobutton_doc_list_text_third: ' ',
    workforce_radiobutton_doc_list_text_fourth: ' ',
    workforce_radiobutton_doc_list_text_fifth: ' ',
    workforce_radiobutton_doc_list_text_sixth: ' ',
    workforce_radiobutton_doc_list_text_seven: ' ',
    workforce_radiobutton_doc_list_text_eight: ' ',
    // usps-description component
    required_docs_heading: ' We use the address on your ID for verification.',
    redirect_email: 'https://dol.nebraska.gov/UIBenefits',
    usps_state_title: '<b>Nebraska Department of Labor</b>',
    usps_state_number: '402-458-2500{br}',
    usps_state_timing_para:
      'Monday to Friday, 8:00 a.m. - 5:00 p.m. Central Time{br}File for Unemployment Insurance Benefits at <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Email: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>',
    uicp_identity_footer_text: `Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}`,
    uicp_state_title: '<b>Nebraska Department of Labor</b>{br}',
    uicp_state_number: `402-458-2500{br}Monday to Friday, 8:00 a.m. - 5:00 p.m. Central Time{br}File for Unemployment Insurance Benefits at <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Email: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>{br}{br}{br}`,
    uicp_state_email: ' ',
    usps_state_email: ' ',
    unemployment_question1: ' ',
    login_gov_help_txt: ' ',
    login_link: ' ',
    postOffice_text: 'The Post Office',
    // error component
    // Hitawal component
    hitawall_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}{br} Please try again later.{br}Thank you for your patience and understanding.',
    hitawall_para2: ' ',
    hitawall_para4: `Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}{br}<b>Nebraska Department of Labor</b>{br}402-458-2500{br}Monday to Friday, 8:00 a.m. - 5:00 p.m. Central Time{br}File for Unemployment Insurance Benefits at <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Email: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>{br}{br}`,
    hitawall_more_info_text: 'More Information',
    hitawall_para5: '<li><ne_link2>Find my local job center</ne_link2></li>',
    // error-500
    error_500_para1:
      '{br}We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.',
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para3: `Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}{br}<b>Nebraska Department of Labor</b>{br}402-458-2500{br}Monday to Friday, 8:00 a.m. - 5:00 p.m. Central Time{br}File for Unemployment Insurance Benefits at <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Email: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>{br}{br}`,
    error_500_more_info_text2: 'More Information',
    moreinfo_link1_500:
      '<li><ne_link2>Find my local job center</ne_link2></li>',
    // page not found
    error404_para1: `{br}We can’t find the page you are looking for. Please check your URL or return to the `,
    error404_para2: `Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity{br}{br}<b>Nebraska Department of Labor</b>{br}402-458-2500{br}Monday to Friday, 8:00 a.m. - 5:00 p.m. Central Time{br}File for Unemployment Insurance Benefits at <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Email: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>{br}{br}`,
    more_info_text_404: 'More Information',
    moreinfo_link1: '<li><ne_link2>Find my local job center</ne_link2></li>',
    // invalid url
    invalid_url_para3:
      '<b>Nebraska Department of Labor</b>{br}402-458-2500{br}Monday to Friday, 8:00 a.m. - 5:00 p.m. Central Time{br}File for Unemployment Insurance Benefits at <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Email: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>',
    more_info_text_invalid: 'More Information',
    moreinfo_link1_invalid:
      '<li><ne_link2>Find my local job center</ne_link2></li>',
    // out of network
    out_of_network_para2: `Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}{br}<b>Nebraska Department of Labor</b>{br}402-458-2500{br}Monday to Friday, 8:00 a.m. - 5:00 p.m. Central Time{br}File for Unemployment Insurance Benefits at <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Email: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>{br}{br}`,
    out_of_network_more_info_text: 'More Information',
    out_of_network_para3:
      '<li><ne_link2>Find my local job center</ne_link2></li>{br}{br}',
    invalid_url_para4: ' ',
    error_500_more_info_text1: ' ',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Nebraska Department of Labor.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Nebraska Department of Labor, <b>please file a report</b> of potential unemployment identity fraud with Nebraska Department of Labor by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to confirm your identity for your unemployment claim with <b>Nebraska Department of Labor</b> through USPS.',
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    out_of_network_para4: ' ',
    out_of_network_para_text1:
      'If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.{br}{br}',
    enrollment_description_para2: `If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.`,
    es: {
      // Fraud Modal
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Labor de Nebraska.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Departamento de Labor de Nebraska, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Departamento de Labor de Nebraska siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Labor de Nebraska a través de USPS.',
      // workforce component
      workforce_radiobutton_label_text:
        'Quiero elegir un método de verificación diferente.',
      workforce_radiobutton_des_text: `Esta opción lo redirigirá al portal 'MyBenefits' del SCDEW.`,
      workforce_radiobutton_doc_list_title: ' ',
      workforce_radiobutton_doc_list_text_first: ' ',
      workforce_radiobutton_doc_list_text_second: ' ',
      workforce_radiobutton_doc_list_text_third: ' ',
      workforce_radiobutton_doc_list_text_fourth: ' ',
      workforce_radiobutton_doc_list_text_fifth: ' ',
      workforce_radiobutton_doc_list_text_sixth: ' ',
      workforce_radiobutton_doc_list_text_seven: ' ',
      workforce_radiobutton_doc_list_text_eight: ' ',
      // UICP page
      identity_text: ' ',
      unemployment_question1: ' ',
      unemployment_question2: ' ',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      uicp_identity_footer_text: `A continuación le indicamos cómo comunicarse con nosotros si tiene preguntas sobre sus beneficios de desempleo o si necesita ayuda verificando su identidad:{br}`,
      uicp_state_title: '<b>Departamento de Labor de Nebraska</b>{br}',
      uicp_state_number: `402-458-2500{br}De lunes a viernes, de 8:00 am a 5:00 pm Hora Central{br}Solicite Beneficios de Seguro de Desempleo en <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Correo electrónico: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>{br}{br}{br}`,
      uicp_state_email: ' ',
      // AddressSearch Component
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      // Enrollment component
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      enrollment_description_para2: `Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.`,
      // usps-description component
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      postOffice_text: 'Post Office<sup>TM</sup> ',
      usps_state_title: '<b>Departamento de Labor de Nebraska</b>',
      usps_state_number: '402-458-2500{br}',
      usps_state_timing_para:
        'De lunes a viernes, de 8:00 am a 5:00 pm Hora Central{br}Solicite Beneficios de Seguro de Desempleo en <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Correo electrónico: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>',
      usps_state_email: ' ',
      redirect_email: 'https://dol.nebraska.gov/UIBenefits',
      // error component
      // Error page(common key)
      login_gov_help_txt: ' ',
      login_link: ' ',
      // Invalid url
      invalid_url_para3:
        'A continuación le indicamos cómo comunicarse con nosotros si tiene preguntas sobre sus beneficios de desempleo o si necesita ayuda verificando su identidad:{br}{br}<b>Departamento de Labor de Nebraska</b>{br}402-458-2500{br}De lunes a viernes, de 8:00 am a 5:00 pm Hora Central{br}Solicite Beneficios de Seguro de Desempleo en <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Correo electrónico: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>',
      invalid_url_para4: ' ',
      more_info_text_invalid: 'Más información',
      moreinfo_link1_invalid:
        '<li><ne_link2>Encuentre su centro de empleo local</ne_link2></li>',
      moreinfo_link2_invalid: ' ',
      // Hitawal component
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      hitawall_para4:
        '{br}A continuación le indicamos cómo comunicarse con nosotros si tiene preguntas sobre sus beneficios de desempleo o si necesita ayuda verificando su identidad:{br}{br}<b>Departamento de Labor de Nebraska</b>{br}402-458-2500{br}De lunes a viernes, de 8:00 am a 5:00 pm Hora Central{br}Solicite Beneficios de Seguro de Desempleo en <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Correo electrónico: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>{br}',
      hitawall_para3: ' ',
      hitawall_more_info_text: 'Más información',
      hitawall_para5:
        '<li><ne_link2>Encuentre su centro de empleo local</ne_link2></li>',
      // Out of network(No post office found)
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_no_participate_para1:
        'No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_no_participate_para2: ' ',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para2:
        'A continuación le indicamos cómo comunicarse con nosotros si tiene preguntas sobre sus beneficios de desempleo o si necesita ayuda verificando su identidad:{br}{br}<b>Departamento de Labor de Nebraska</b>{br}402-458-2500{br}De lunes a viernes, de 8:00 am a 5:00 pm Hora Central{br}Solicite Beneficios de Seguro de Desempleo en <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Correo electrónico: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>{br}',
      out_of_network_more_info_text: 'Más información',
      out_of_network_para3:
        '<li><ne_link2>Encuentre su centro de empleo local</ne_link2></li>{br}{br}',
      // Error-500
      error_500_more_info_text1: ' ',
      error_500_more_info_text2: 'Más información',
      error_500_title: 'Tenemos problemas para conectar ',
      error_500_heading:
        'La verificación de su identidad no está disponible actualmente.',
      error_500_para1:
        '{br}Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. ',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para3:
        'A continuación le indicamos cómo comunicarse con nosotros si tiene preguntas sobre sus beneficios de desempleo o si necesita ayuda verificando su identidad:{br}{br}<b>Departamento de Labor de Nebraska</b>{br}402-458-2500{br}De lunes a viernes, de 8:00 am a 5:00 pm Hora Central{br}Solicite Beneficios de Seguro de Desempleo en <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Correo electrónico: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>{br}{br}',
      moreinfo_link1_500:
        '<li><ne_link2>Encuentre su centro de empleo local</ne_link2></li>',
      // Page not found(404)
      error404_para1:
        '{br}Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de ',
      choice_page_link: 'opciones de verificación de identificación.',
      error404_para2: `A continuación le indicamos cómo comunicarse con nosotros si tiene preguntas sobre sus beneficios de desempleo o si necesita ayuda verificando su identidad:{br}{br}<b>Departamento de Labor de Nebraska</b>{br}402-458-2500{br}De lunes a viernes, de 8:00 am a 5:00 pm Hora Central{br}Solicite Beneficios de Seguro de Desempleo en <ne_link1><u>NEworks.nebraska.gov</u></ne_link1>{br}Correo electrónico: <ne_link><u>NDOL.UnemploymentHelp@nebraska.gov</u></ne_link>{br}{br}`,
      more_info_text_404: 'Más información',
      more_info_text1: ' ',
      moreinfo_link1:
        '<li><ne_link2>Encuentre su centro de empleo local</ne_link2></li>',
      moreinfo_link2: ' ',
      moreinfo_link3: ' ',
    },
  },
  NV: {
    name: 'Nevada',
    stateCode: 'NV',
    showVerificationButton: false,
    chooseDifferentVerificationButton_USPS: false,
    showErrorPageButton_HitaWall: false,
    showLanguageDropdown: true,
    // english
    // uicp component
    // Fraud Modal
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim</b> with Nevada Department of Employment, Training, and Rehabilitation.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Nevada Department of Employment, Training, and Rehabilitation, <b>please file a report</b> of potential unemployment identity fraud with Nevada Department of Employment, Training, and Rehabilitation by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim</b> with Nevada Department of Employment, Training, and Rehabilitation through USPS.',
    // usps-description component
    required_docs_heading: ' We use the address on your ID for verification.',
    redirect_email: ' ',
    usps_state_title:
      '<b>Nevada Department of Employment, Training, and Rehabilitation</b>',
    usps_state_number: ' ',
    usps_state_timing_para: `Northern Nevada - (775) 684-0350{br}Southern Nevada - (702) 486-0350{br}Rural Nevada & Out-of-State - (888) 890-8211{br}Monday through Friday from 8:00am to 5:00pm{br}Nevada Relay: 711, or (800) 326-6868{br}Auxiliary aids and services are available on request by individuals with disabilities.{br}{br}<b>Technical Assistance ONLY with online UI claim filing</b>{br}Email: <nv_link>INTERNETHELP@detr.nv.gov</nv_link>{br}When reporting a technical problem with your UI claim via email, please include your full name and your claimant ID and/or the last four digits of your social security number.{br}Tel: (775) 684-0427  (8:00 am to 5:00 pm PST){br}{br}`,
    uicp_identity_footer_text: `Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}`,
    uicp_state_title:
      '<b>Nevada Department of Employment, Training, and Rehabilitation</b>{br}',
    uicp_state_number: `Northern Nevada - (775) 684-0350{br}Southern Nevada - (702) 486-0350{br}Rural Nevada & Out-of-State - (888) 890-8211{br}Monday through Friday from 8:00am to 5:00pm{br}Nevada Relay: 711, or (800) 326-6868{br}Auxiliary aids and services are available on request by individuals with disabilities.{br}{br}<b>Technical Assistance ONLY with online UI claim filing</b>{br}Email: <nv_link>INTERNETHELP@detr.nv.gov</nv_link>{br}When reporting a technical problem with your UI claim via email, please include your full name and your claimant ID and/or the last four digits of your social security number.{br}Tel: (775) 684-0427  (8:00 am to 5:00 pm PST){br}{br}`,
    uicp_state_email: ' ',
    usps_state_email: ' ',
    enrollment_description_para2: `If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.`,
    unemployment_question1: ' ',
    login_gov_help_txt: ' ',
    login_link: ' ',
    // error component
    // Hitawal component
    hitawall_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}{br} Please try again later.{br}Thank you for your patience and understanding.',
    hitawall_para2: ' ',
    hitawall_para4: `Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}{br}<b>Nevada Department of Employment, Training, and Rehabilitation</b>{br}Northern Nevada - (775) 684-0350{br}Southern Nevada - (702) 486-0350{br}Rural Nevada & Out-of-State - (888) 890-8211{br}Monday through Friday from 8:00am to 5:00pm{br}Nevada Relay: 711, or (800) 326-6868{br}Auxiliary aids and services are available on request by individuals with disabilities.{br}{br}<b>Technical Assistance ONLY with online UI claim filing</b>{br}Email: <nv_link>INTERNETHELP@detr.nv.gov</nv_link>{br}When reporting a technical problem with your UI claim via email, please include your full name and your claimant ID and/or the last four digits of your social security number.{br}Tel: (775) 684-0427  (8:00 am to 5:00 pm PST){br}`,
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    // error-500
    error_500_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.',
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para3: `{br}Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}{br}<b>Nevada Department of Employment, Training, and Rehabilitation</b>{br}Northern Nevada - (775) 684-0350{br}Southern Nevada - (702) 486-0350{br}Rural Nevada & Out-of-State - (888) 890-8211{br}Monday through Friday from 8:00am to 5:00pm{br}Nevada Relay: 711, or (800) 326-6868{br}Auxiliary aids and services are available on request by individuals with disabilities.{br}{br}<b>Technical Assistance ONLY with online UI claim filing</b>{br}Email: <nv_link>INTERNETHELP@detr.nv.gov</nv_link>{br}When reporting a technical problem with your UI claim via email, please include your full name and your claimant ID and/or the last four digits of your social security number.{br}Tel: (775) 684-0427  (8:00 am to 5:00 pm PST){br}{br}`,
    error_500_more_info_text1: ' ',
    // page not found
    error404_para1:
      '{br}We can’t find the page you are looking for. Please check your URL or return to the ',
    error404_para2: `Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}{br}<b>Nevada Department of Employment, Training, and Rehabilitation</b>{br}Northern Nevada - (775) 684-0350{br}Southern Nevada - (702) 486-0350{br}Rural Nevada & Out-of-State - (888) 890-8211{br}Monday through Friday from 8:00am to 5:00pm{br}Nevada Relay: 711, or (800) 326-6868{br}Auxiliary aids and services are available on request by individuals with disabilities.{br}{br}<b>Technical Assistance ONLY with online UI claim filing</b>{br}Email: <nv_link>INTERNETHELP@detr.nv.gov</nv_link>{br}When reporting a technical problem with your UI claim via email, please include your full name and your claimant ID and/or the last four digits of your social security number.{br}Tel: (775) 684-0427  (8:00 am to 5:00 pm PST){br}{br}`,
    // invalid url
    invalid_url_para3: `Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}{br}<b>Nevada Department of Employment, Training, and Rehabilitation</b>{br}Northern Nevada - (775) 684-0350{br}Southern Nevada - (702) 486-0350{br}Rural Nevada & Out-of-State - (888) 890-8211{br}Monday through Friday from 8:00am to 5:00pm{br}Nevada Relay: 711, or (800) 326-6868{br}Auxiliary aids and services are available on request by individuals with disabilities.{br}{br}<b>Technical Assistance ONLY with online UI claim filing</b>{br}Email: <nv_link>INTERNETHELP@detr.nv.gov</nv_link>{br}When reporting a technical problem with your UI claim via email, please include your full name and your claimant ID and/or the last four digits of your social security number.{br}Tel: (775) 684-0427  (8:00 am to 5:00 pm PST){br}`,
    invalid_url_para4: ' ',
    // out of network
    out_of_network_para2: `{br}Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}{br}<b>Nevada Department of Employment, Training, and Rehabilitation</b>{br}Northern Nevada - (775) 684-0350{br}Southern Nevada - (702) 486-0350{br}Rural Nevada & Out-of-State - (888) 890-8211{br}Monday through Friday from 8:00am to 5:00pm{br}Nevada Relay: 711, or (800) 326-6868{br}Auxiliary aids and services are available on request by individuals with disabilities.{br}{br}<b>Technical Assistance ONLY with online UI claim filing</b>{br}Email: <nv_link>INTERNETHELP@detr.nv.gov</nv_link>{br}When reporting a technical problem with your UI claim via email, please include your full name and your claimant ID and/or the last four digits of your social security number.{br}Tel: (775) 684-0427  (8:00 am to 5:00 pm PST){br}{br}`,
    out_of_network_para4: ' ',
    out_of_network_para_text1:
      'If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.{br}{br}',
    out_of_network_para1:
      '{br}If the information you have entered is correct and matches your government issued identification, you can choose a different verification method.',
  },
  NH: {
    name: 'New Hampshire',
    stateCode: 'NH',
    chooseDifferentVerificationButton_USPS: true,
    showVerificationButton: true,
    showErrorPageButton_HitaWall: true,
    showLanguageDropdown: true,
    workforce_radiobutton_label_text:
      'I want to verify my identity at my local New Hampshire Works Office.',
    workforceRedirect: true,
    workforce_direct_navigation: 'https://www.nhes.nh.gov/locations/index.htm',
    workforce_radiobutton_des_text:
      'Bring a copy of your valid State or Government issued picture ID to any one of the New Hampshire Works Offices. Go to <nh_link>www.nhes.nh.gov</nh_link> to find an office close to you.  Consider the United State Postal Service option for greater convenience.',
    workforce_radiobutton_doc_list_title: ' ',
    workforce_radiobutton_doc_list_text_first: ' ',
    workforce_radiobutton_doc_list_text_second: ' ',
    workforce_radiobutton_doc_list_text_third: ' ',
    workforce_radiobutton_doc_list_text_fourth: ' ',
    workforce_radiobutton_doc_list_text_fifth: ' ',
    workforce_radiobutton_doc_list_text_sixth: ' ',
    workforce_radiobutton_doc_list_text_seven: ' ',
    workforce_radiobutton_doc_list_text_eight: ' ',
    unemployment_question1:
      '{br}Do you have questions regarding your unemployment claim or identify verification?  Call or visit you local <b>New Hampshire Works Office.</b>{br}Our Offices are open from 8:00 am until 4:30pm Monday through Friday.{br}CLOSED on State Holidays.{br}(603) 224-3311 Toll Free at (800) 852-3400  TTY/TDD (800) 735-2964{br}<nh_link>www.nhes.nh.gov</nh_link>',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with New Hampshire Employment Security.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with New Hampshire Employment Security, <b>please file a report</b> of potential unemployment identity fraud with New Hampshire Employment Security by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim</b> with New Hampshire Employment Security through USPS.',
    confirmation_state_title: 'New Hampshire Employment Security',
    confirmation_next_step_text: 'Reminder',
    moreinfo_confirmation_link1:
      '<li><nh_link2>https://www.nhes.nh.gov/locations/index.htm</nh_link2></li><li><nh_link>https://www.nhes.nh.gov/index.htm</nh_link></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>{br}',
    confirmation_steps_content_line_first: `1. If you haven't yet registered for work online please register at <nh_link1>https://nhworksjobmatch.nhes.nh.gov.</nh_link1>  Visit your local New Hampshire Works office for assistance.`,
    logout_intro: 'You may close this window.',
    logout_next_step_text: 'Reminder',
    moreinfo_logout_link:
      '<li><nh_link2>https://www.nhes.nh.gov/locations/index.htm</nh_link2></li><li><nh_link>https://www.nhes.nh.gov/index.htm</nh_link></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>{br}',
    required_docs_heading: ' We use the address on your ID for verification.',
    required_docs_para:
      'If you live somewhere else or your ID has no address listed, you will also need proof of address:',
    postOffice_text: 'The Post Office',
    usps_state_title: 'New Hampshire Employment Security {br}',
    usps_state_number:
      '(603) 224-3311 Toll Free (800) 852-3400  TYY/TDD (800) 735-2964 {br}',
    usps_state_timing_para:
      'Monday through Friday 8:00am to 4:30pm. {br}CLOSED on State Holidays.',
    out_of_network_para2:
      'If you need help verifying your identification <b>please call (603) 228-4019.</b>{br}Monday through Friday 8:00am to 4:30pm.{br}CLOSED on State Holidays.',
    out_of_network_para4: ' ',
    error404_para2:
      'If you need help verifying your identification <b>please call (603) 228-4019.</b>{br}Monday through Friday 8:00am to 4:30pm.{br}CLOSED on State Holidays.',
    more_info_text_404: 'More Information',
    moreinfo_link1:
      '<li><nh_link2>https://www.nhes.nh.gov/locations/index.htm</nh_link2></li><li><nh_link>https://www.nhes.nh.gov/index.htm</nh_link></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>{br}',
    invalid_url_para3:
      'If you need help verifying your identification <b>please call (603) 228-4019.</b>{br}Monday through Friday 8:00am to 4:30pm.{br}CLOSED on State Holidays.',
    invalid_url_para4: ' ',
    more_info_text_invalid: 'More Information',
    moreinfo_link1_invalid:
      '<li><nh_link1>https://www.nhes.nh.gov/locations/index.htm</nh_link1></li><li><nh_link2>https://www.nhes.nh.gov/index.htm</nh_link2></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>',
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_more_info_text1: ' ',
    error_500_para3:
      'If you need help verifying your identification <b>please call (603) 228-4019.</b>{br}Monday through Friday 8:00am to 4:30pm.{br}CLOSED on State Holidays.',
    error_500_more_info_text2: 'More Information',
    moreinfo_link1_500:
      '<li><nh_link2>https://www.nhes.nh.gov/locations/index.htm</nh_link2></li><li><nh_link>https://www.nhes.nh.gov/index.htm</nh_link></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>{br}',
    hitawall_para2:
      '{br}Please try again later or choose a different verification method.{br}Thank you for your patience and understanding.',
    hitawall_para4:
      'If you need help verifying your identification <b>please call (603) 228-4019.</b>{br}Monday through Friday 8:00am to 4:30pm.{br}CLOSED on State Holidays.{br}',
    hitawall_para6: ' ',
    error_invalid_login_para3:
      'Do you have questions regarding your unemployment claim or identify verification?{br}{br}Call or visit you local <b>New Hampshire Works Offiice.</b>{br}Our Offices are open from 8:00am until 4:30pm Monday through Friday.{br}CLOSED on State Holidays.{br}(603) 224-3311 Toll Free at (800) 852-3400  TTY/TDD (800) 735-2964{br}<nh_link>www.nhes.nh.gov</nh_link>',
    more_info_text2: 'More Information',
    error_invalid_login_moreinfo_link1:
      '<li><nh_link2>https://www.nhes.nh.gov/locations/index.htm</nh_link2></li><li><nh_link>https://www.nhes.nh.gov/index.htm</nh_link></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>{br}',
    // spanish
    es: {
      // Search page
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      usps_state_title: 'Seguros del desmpleo de New Hampshire',
      usps_state_number:
        '(603) 224-3311 Número gratuito (800) 852-3400 TYY/TDD (800) 735-2964',
      usps_state_timing_para:
        '{br}De lunes a viernes, de 8:00 a. m. a 4:30 p. m.',
      usps_contact: '{br}CERRADO los días festivos estatales.',
      usps_state_email: ' ',
      // Enrollment component
      enrollment_description_para2:
        '  Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.',
      // ErrorPage component
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Vuelva a intentarlo más tarde o elija un método de verificación diferente.{br}Gracias por su paciencia y comprensión.',
      hitawall_para3: ' ',
      hitawall_para4:
        'Si necesita ayuda para verificar su identificación, <b>llame al (603) 228-4019</b>.{br}Lunes a viernes de 8:00 am a 4:30 pm.{br}CERRADO los días festivos estatales.{br}',
      hitawall_para5: ' ',
      hitawall_para6: ' ',
      // workforce component
      workforce_radiobutton_label_text:
        'Quiero verificar mi identidad en persona visitando uno de los centros de WorkSource más cercano',
      workforce_radiobutton_des_text:
        'Lleve una copia de su identificación con fotografía válida emitida por el estado o el gobierno a cualquiera de las oficinas de trabajo de New Hampshire. Vaya a <nh_link>www.nhes.nh.gov</nh_link> para encontrar una oficina cerca de usted. Considere la opción del Servicio Postal de los Estados Unidos para mayor comodidad.',
      workforce_radiobutton_doc_list_title: ' ',
      workforce_radiobutton_doc_list_text_first: ' ',
      workforce_radiobutton_doc_list_text_second: ' ',
      workforce_radiobutton_doc_list_text_third: ' ',
      workforce_radiobutton_doc_list_text_fourth: ' ',
      workforce_radiobutton_doc_list_text_fifth: ' ',
      workforce_radiobutton_doc_list_text_sixth: ' ',
      workforce_radiobutton_doc_list_text_seven: ' ',
      workforce_radiobutton_doc_list_text_eight: ' ',
      moreinfo_link1:
        '<li><nh_link2>https://www.nhes.nh.gov/locations/index.htm</nh_link2></li><li><nh_link>https://www.nhes.nh.gov/index.htm</nh_link></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>{br}',
      moreinfo_link2: ' ',
      online_radiobutton_doc_list_text_second: ' ',
      unemployment_question1: ' ',
      unemployment_question2:
        '{br}¿Tiene preguntas sobre su reclamo de desempleo o verificación de identidad? Llame o visite su oficina local del <b>desempleo de New Hampshire</b>. Nuestras oficinas están abiertas de 8:00 am a 4:30 pm de lunes a viernes.{br}',
      uicp_state_title: 'CERRADO los días festivos estatales.{br}',
      uicp_state_number:
        '(603) 224-3311 Línea gratuita al (800) 852-3400 TTY/TDD (800) 735-2964{br}',
      uicp_state_email: '<u>www.nhes.nh.gov</u>{br}{br}',
      redirect_email: 'https://www.nhes.nh.gov/',
      scroll_to_top_text: 'Volver al principio',
      confirmation_state_title: 'Seguros del desempleo de New Hampshire',
      confirmation_next_step_text: 'Recordatorio',
      confirmation_steps_content_line_first:
        '1. Si aún no se ha registrado para trabajar en línea, regístrese en <nh_link1>https://nhworksjobmatch.nhes.nh.gov</nh_link1>. Visite su oficina local de New Hampshire Works para obtener ayuda.',
      confirmation_steps_content_line_second: ' ',
      uicp_more_info_text: 'Más información',
      moreinfo_confirmation_link1:
        '<li><nh_link2>https://www.nhes.nh.gov/locations/index.htm</nh_link2></li><li><nh_link>https://www.nhes.nh.gov/index.htm</nh_link></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>{br}',
      confirmation_button_text: ' ',
      logout_intro: ' Puede cerrar esta ventana.',
      moreinfo_logout_link:
        '<li><nh_link2>https://www.nhes.nh.gov/locations/index.htm</nh_link2></li><li><nh_link>https://www.nhes.nh.gov/index.htm</nh_link></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>{br}',
      hitawall_more_info_text: ' ',
      error_invalid_login_para3: ' ',
      error_invalid_login_para4:
        '¿Tiene preguntas sobre su reclamo de desempleo o verificación de identidad?{br}{br}Llame o visite su oficina local del <b>desempleo de New Hampshire</b>. Nuestras oficinas están abiertas de 8:00 am a 4:30 pm de lunes a viernes.{br}CERRADO los días festivos estatales.{br}(603) 224-3311 Línea gratuita al (800) 852-3400 TTY/TDD (800) 735-2964{br}<nh_link>www.nhes.nh.gov</nh_link>{br}{br}',
      error_invalid_login_para5: ' ',
      error_invalid_login_para6: ' ',
      login_gov_help_txt: 'Para obtener ayuda con Login.gov:',
      login_link: 'https://www.login.gov/contact/',
      more_info_text1: ' ',
      more_info_text2: 'Más información',
      error_invalid_login_moreinfo_link1:
        '<li><nh_link2>https://www.nhes.nh.gov/locations/index.htm</nh_link2></li><li><nh_link>https://www.nhes.nh.gov/index.htm</nh_link></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>{br}',
      error_invalid_login_moreinfo_link2: ' ',
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      error_500_para1:
        '{br}Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. {br}{br}Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para2:
        'Si necesita ayuda para verificar su identificación, <b>llame al (603) 228-4019</b>.{br}Lunes a viernes de 8:00 am a 4:30 pm.{br}CERRADO los días festivos estatales.{br}',
      error_500_para3: ' ',
      error_500_more_info_text1: ' ',
      error_500_more_info_text2: 'Más información',
      moreinfo_link1_500:
        '<li><nh_link2>https://www.nhes.nh.gov/locations/index.htm</nh_link2></li><li><nh_link>https://www.nhes.nh.gov/index.htm</nh_link></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>{br}',
      moreinfo_link2_500: ' ',
      moreinfo_link3_500: ' ',
      choice_page_link: ' opciones de verificación de identificación.',
      error404_para1:
        '{br}Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de ',
      error404_para2:
        'Si necesita ayuda para verificar su identificación, <b>llame al (603) 228-4019</b>.{br}Lunes a viernes de 8:00 am a 4:30 pm.{br}CERRADO los días festivos estatales.{br}{br}',
      more_info_text_404: 'Más información',
      invalid_url_para1:
        'No podemos ubicar su número de verificación de identificación asociado con su solicitud de desempleo.{br}',
      invalid_url_para3:
        'Si necesita ayuda para verificar su identificación, <b>llame al (603) 228-4019</b>.{br}Lunes a viernes de 8:00 am a 4:30 pm.{br}CERRADO los días festivos estatales.{br}{br}<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
      invalid_url_para4: ' ',
      more_info_text_invalid: 'Más información',
      moreinfo_link1_invalid:
        '<li><nh_link2>https://www.nhes.nh.gov/locations/index.htm</nh_link2></li><li><nh_link>https://www.nhes.nh.gov/index.htm</nh_link></li><li><nh_link3>https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf</nh_link3></li>{br}',
      moreinfo_link2_invalid: ' ',
      out_of_network_para3: ' ',
      out_of_network_para4: ' ',
      out_of_network_para2:
        'Si necesita ayuda para verificar su identificación, <b>llame al (603) 228-4019</b>.{br}Lunes a viernes de 8:00 am a 4:30 pm.{br}CERRADO los días festivos estatales.{br}{br}<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      hitawall_login_subheading:
        'La verificación de su identidad con Login.gov no está disponible actualmente.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      error404_more_info_text: ' ',
      online_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      online_radiobutton_doc_list_text_first:
        '<li>Documento de identidad estadual</li>',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      workforce_more_info_text: 'Más información',
      logout_next_step_text: 'Recordatorio',
      postOffice_text: 'Post Office<sup>TM</sup>',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Seguros del desmpleo de New Hampshire.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Seguros del desmpleo de New Hampshire, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Seguros del desmpleo de New Hampshire siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Seguros del desmpleo de New Hampshire a través de USPS.',
      // uicp
      online_radiobutton_label_text: 'En línea - Login.gov',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
    },
  },
  NJ: {
    name: 'New Jersey',
    stateCode: 'NJ',
  },
  NM: {
    name: 'New Mexico',
    stateCode: 'NM',
    showVerificationButton: true,
    showErrorPageButton_HitaWall: true,
    chooseDifferentVerificationButton_USPS: true,
    showLanguageDropdown: true,
    workforce_radiobutton_label_text:
      'I want to verify my identity in-person at a America’s Job Center New Mexico.',
    workforce_radiobutton_des_text: 'Bring your identity documents with you.',
    workforce_radiobutton_doc_list_text_first: '<li>Social Security Card</li>',
    workforce_radiobutton_doc_list_text_second:
      '<li>Past years W2 form with full Social Security Number</li>',
    workforce_radiobutton_doc_list_text_third:
      '<li>Paycheck stubs with last 4 of Social Security Number</li>',
    workforce_radiobutton_doc_list_text_fourth: ' ',
    workforce_radiobutton_doc_list_text_fifth: ' ',
    workforce_radiobutton_doc_list_text_sixth: ' ',
    workforce_radiobutton_doc_list_text_seven: ' ',
    workforce_radiobutton_doc_list_text_eight: ' ',
    workforce_radiobutton_doc_list_extra_title:
      '<b>And one of the following:</b>',
    workforce_radiobutton_doc_list_extra_text:
      '<li>Birth Certificate</li><li>State issued Driver’s License</li><li>State issued ID Card</li><li>Passports and Passport Cards</li><li>Permanent Residence Card</li><li>Military ID</li><li>Military DD-214</li><li>Tribal Identification</li>',
    uicp_identity_footer_text:
      'The method you choose to verify your identity will not affect your ability to receive unemployment benefits. {br}State staff will process your application following all applicable laws and regulations.{br}',
    unemployment_question1:
      'Here’s how to reach us if you have questions about unemployment benefits or need help with verifying your identity: {br}{br}<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center{br}1-877-664-6984{br}Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
    confirmation_state_title: 'NM Department of Workforce Solutions',
    confirmation_next_step_text: 'Reminder',
    confirmation_steps_content_line_first:
      '1. If you haven’t yet registered for work online, go to the <nm_link>America’s Job Center New Mexico" Online System</nm_link>, New Mexico’s official online portal to virtual job matching services.  You may also visit  your local America’s Job Center New Mexico for in-person assistance.',
    uicp_more_info_text: 'More Information',
    moreinfo_confirmation_link1:
      '<li><nm_link1>Find my local America’s Job Center New Mexico</nm_link1></li><li><nm_link2>Information about Unemployment Insurance</nm_link2></li>{br}{br}',
    logout_intro: 'You may close this window.',
    logout_next_step_text: 'Reminder',
    moreinfo_logout_link:
      '<li><nm_link1>Find my local America’s Job Center New Mexico</nm_link1></li><li><nm_link2>Information about Unemployment Insurance</nm_link2></li>{br}{br}',
    error_invalid_login_para3:
      'Here’s how to reach us if you have questions about unemployment benefits or need help with verifying your identity: {br}{br}<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center{br}1-877-664-6984{br}Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time{br}',
    more_info_text2: 'More Information',
    error_invalid_login_moreinfo_link1:
      '<li><nm_link1>Find my local America’s Job Center New Mexico</nm_link1></li><li><nm_link2>Information about Unemployment Insurance</nm_link2></li>{br}',
    hitawall_para2:
      '{br}Please try again later or choose a different verification method.{br}Thank you for your patience and understanding.',
    hitawall_para4:
      'Here’s how to reach us if you have questions about unemployment benefits or need help with verifying your identity: {br}{br}<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center{br}1-877-664-6984{br}Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}',
    hitawall_more_info_text: 'More Information',
    hitawall_para5:
      '<li><nm_link1>Find my local America’s Job Center New Mexico</nm_link1></li><li><nm_link2>Information about Unemployment Insurance</nm_link2></li>{br}',
    different_verification_method_link:
      'Choose a different verification method',
    error_500_para2:
      'Please try again later. {br}Thank you for your patience and understanding.',
    error_500_para3:
      'Here’s how to reach us if you have questions about unemployment benefits or need help with verifying your identity: {br}{br}<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center{br}1-877-664-6984{br}Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time{br}{br}',
    error_500_more_info_text2: 'Resources',
    moreinfo_link1_500:
      '<li><nm_link1>Find my local America’s Job Center New Mexico</nm_link1></li><li><nm_link2>Information about Unemployment Insurance</nm_link2></li>{br}',
    error404_para2:
      'Here’s how to reach us if you have questions about unemployment benefits or need help with verifying your identity: {br}{br}<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center{br}1-877-664-6984{br}Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time{br}{br}',
    more_info_text_404: 'More Information',
    moreinfo_link1:
      '<li><nm_link1>Find my local America’s Job Center New Mexico</nm_link1></li><li><nm_link2>Information about Unemployment Insurance</nm_link2></li>{br}',
    required_docs_heading: ' We use the address on your ID for verification.',
    required_docs_para:
      'If you live somewhere else or your ID has no address listed, you will also need proof of address:',
    usps_state_timing_para:
      '<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center{br}1-877-664-6984{br}Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time{br}',
    out_of_network_para2:
      'Here’s how to reach us if you have questions about unemployment benefits or need help with verifying your identity: {br}{br}<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center{br}1-877-664-6984{br}Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}',
    out_of_network_more_info_text: 'More Information',
    out_of_network_para3:
      '<li><nm_link1>Find my local America’s Job Center New Mexico</nm_link1></li><li><nm_link2>Information about Unemployment Insurance</nm_link2></li>{br}',
    invalid_url_para3:
      'Here’s how to reach us if you have questions about unemployment benefits or need help with verifying your identity: {br}{br}<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center{br}1-877-664-6984{br}Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}',
    more_info_text_invalid: 'Resources',
    moreinfo_link1_invalid:
      '<li><nm_link1>Find my local America’s Job Center New Mexico</nm_link1></li><li><nm_link2>Information about Unemployment Insurance</nm_link2></li>{br}',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with NM Department of Workforce Solutions.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with NM Department of Workforce Solutions, <b>please file a report</b> of potential unemployment identity fraud with NM Department of Workforce Solutions by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim</b> with NM Department of Workforce Solutions through USPS.',
    workforce_appointment_text:
      'Visit <nm_link1>https://www.dws.state.nm.us/Office-Locations</nm_link1> to find a America’s Job Center New Mexico near you and/or to schedule an appointment.',
    workforce_para1:
      'You will need to verify your identity in person at a local America’s Job Center New Mexico.',
    workfore_para2:
      'The types of documents required by USPS may be different than the documents accepted at your local America’s Job Center New Mexico.',
    workfore_para3:
      '<b>The list of acceptable documents to verify your identify are:</b><li>Social Security Card</li><li>Past Years W2 form with full Social Security Number and full name and last name</li><li>Paycheck Stubs with last 4 of Social Security Number and full name and last name</li>{br}<b>And one of the following:</b><li>Birth Certificate</li><li>State issued Driver’s License</li><li>State issued ID Card</li><li>Passports and Passport Cards</li><li>Permanent Residence Card</li><li>Military ID</li><li>Military DD-214</li><li>Tribal Identification</li>{br}The method you choose to verify your identity will not affect your ability to receive benefits.',
    workforce_more_info_text: 'More Information',
    workforce_moreinfo_link1:
      '<li><nm_link1>Find my local America’s Job Center New Mexico</nm_link1></li><li><nm_link2>Information about Unemployment Insurance</nm_link2></li>{br}',
    es: {
      online_radiobutton_label_text: 'En línea - Login.gov',
      online_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      online_radiobutton_doc_list_text_first:
        '<li>Documento de identidad estadual</li>',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      workforce_radiobutton_label_text:
        'Quiero verificar mi identidad en persona en Centros Americanos de Empleos Nuevo México.{br}',
      workforce_radiobutton_des_text:
        'Traiga sus documentos de identidad con usted.{br}',
      workforce_radiobutton_doc_list_title:
        '<b>Documentos de identificación aceptados:</b>',
      workforce_radiobutton_doc_list_text_first:
        '<li>Tarjeta de Seguro Social</li>',
      workforce_radiobutton_doc_list_text_second:
        '<li>Formulario W2 de años anteriores con Número de Seguro Social completo</li>',
      workforce_radiobutton_doc_list_text_third:
        '<li>Talones de cheque de pago con los últimos 4 Números de Seguro Social</li>',
      workforce_radiobutton_doc_list_extra_title:
        '<b>Y uno de los siguientes:</b>',
      workforce_radiobutton_doc_list_extra_text:
        '<li>Acta de Nacimiento</li><li>Licencia de Conducir emitida por el Estado</li><li>Tarjeta de Identificación emitida por el Estado</li><li>Pasaporte o Tarjeta de Pasaporte</li><li>Tarjeta de Residente Permanente</li><li>Identificación Militar</li><li>Formulario Militar DD-214</li><li>Tarjeta de Identificación Tribal</li>',
      uicp_identity_footer_text:
        'El método escogido para verificar su identidad no afectará sus posibilidades de recibir beneficios de desempleo. El personal estatal procesará su solicitud siguiendo todas las leyes y reglamentos aplicables.{br}',
      unemployment_question1:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:{br}{br}<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo{br}1-877-664-6984{br}Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña{br}{br}<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}{br}',
      unemployment_question2: ' ',
      confirmation_header:
        'Próximos pasos para la verificación de identidad de las solicitudes de desempleo',
      confirmation_checkmark: 'Compartimos su verificación de identidad con',
      confirmation_state_title:
        'Departamento de Soluciones de Fuerza Laboral de Nuevo México',
      confirmation_next_step_text: 'Recordatorio',
      confirmation_steps_content_line_first:
        '1. Si aún no se ha registrado para trabajar en línea, vaya al Sistema en Línea del <nm_link>Centro Americano de Empleos Nuevo México</nm_link>, el portal en línea oficial de Nuevo México para servicios virtuales de búsqueda de empleo. También puede visitar el Centro Americano de Empleos de su localidad en Nuevo México para obtener asistencia en persona.',
      confirmation_steps_content_line_second: ' ',
      moreinfo_confirmation_link1:
        '<li><nm_link1>Encontrar su Centro Americano de Empleos Nuevo México</nm_link1></li><li><nm_link2>Información sobre el Seguro de Desempleo</nm_link2></li>{br}{br}',
      uicp_more_info_text: 'Más información',
      logout_intro: ' Puede cerrar esta ventana.',
      logout_next_step_text: 'Recordatorio',

      error_invalid_login_para3:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:{br}{br}<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo{br}1-877-664-6984{br}Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña{br}',

      moreinfo_logout_link:
        '<li><nm_link1>Encontrar su Centro Americano de Empleos Nuevo México</nm_link1></li><li><nm_link2>Información sobre el Seguro de Desempleo</nm_link2></li>{br}{br}',
      more_info_text1: ' ',
      more_info_text2: 'Más información',
      error_invalid_login_moreinfo_link1:
        '<li><nm_link1>Encontrar su Centro Americano de Empleos Nuevo México</nm_link1></li><li><nm_link2>Información sobre el Seguro de Desempleo</nm_link2></li>{br}',
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde o elija un método de verificación diferente. {br}Gracias por su paciencia y comprensión.',
      hitawall_para4:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:{br}{br}<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo{br}1-877-664-6984{br}Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña{br}{br}<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}',
      hitawall_more_info_text: 'Más información',
      hitawall_para5:
        '<li><nm_link1>Encontrar su Centro Americano de Empleos Nuevo México</nm_link1></li><li><nm_link2>Información sobre el Seguro de Desempleo</nm_link2></li>{br}',

      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para3:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:{br}{br}<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo{br}1-877-664-6984{br}Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña{br}',
      error_500_more_info_text2: 'Más información',
      moreinfo_link1_500:
        '<li><nm_link1>Encontrar su Centro Americano de Empleos Nuevo México</nm_link1></li><li><nm_link2>Información sobre el Seguro de Desempleo</nm_link2></li>{br}',
      error404_para2:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:{br}{br}<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo{br}1-877-664-6984{br}Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña{br}',
      more_info_text_404: 'Más información',
      moreinfo_link1:
        '<li><nm_link1>Encontrar su Centro Americano de Empleos Nuevo México</nm_link1></li><li><nm_link2>Información sobre el Seguro de Desempleo</nm_link2></li>{br}',
      moreinfo_link2: ' ',
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_docs_para:
        'Si no tiene dirección o su dirección es diferente de la que esta declarada en su documento de identificación, necesita presentar comprobante de domicilio, por ejemplo: ',
      usps_state_timing_para:
        '<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo{br}1-877-664-6984{br}Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña{br}',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para2:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:{br}{br}<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo{br}1-877-664-6984{br}Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña{br}{br}<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}',
      out_of_network_more_info_text: 'Más información',
      out_of_network_para3:
        '<li><nm_link1>Encontrar su Centro Americano de Empleos Nuevo México</nm_link1></li><li><nm_link2>Información sobre el Seguro de Desempleo</nm_link2></li>{br}',
      invalid_url_para3:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:{br}{br}<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo{br}1-877-664-6984{br}Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña{br}{br}<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
      more_info_text_invalid: 'Más información',
      moreinfo_link1_invalid:
        '<li><nm_link1>Encontrar su Centro Americano de Empleos Nuevo México</nm_link1></li><li><nm_link2>Información sobre el Seguro de Desempleo</nm_link2></li>{br}',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Soluciones de Fuerza Laboral de Nuevo México.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Departamento de Soluciones de Fuerza Laboral de Nuevo México, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Departamento de Soluciones de Fuerza Laboral de Nuevo México siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Soluciones de Fuerza Laboral de Nuevo México a través de USPS.',
      workforce_appointment_text:
        'Visite <nm_link1>https://www.dws.state.nm.us/Office-Locations</nm_link1> para encontrar un Centro Americano de Empleos Nuevo México cerca de usted y/o para agendar una cita.',
      workforce_para1:
        'Deberá verificar su identidad en persona en un Centro Americano de Empleos Nuevo México cerca de usted.',
      workfore_para2:
        'Los tipos de documentos requeridos por USPS pueden ser diferentes a los documentos aceptados en su Centro Americano de Empleos Nuevo México.',
      workfore_para3:
        '<b>La lista de documentos aceptables para verificar su identidad incluye:</b><li>Tarjeta de Seguro Social</li><li>Formulario W2 de años anteriores con el Número de Seguro Social completo</li><li>Talones de Cheque con los últimos 4 números del Número de Seguro Social</li>{br}<b>Y uno de los siguientes:</b><li>Acta de Nacimiento</li><li>Licencia de Conducir emitida por el estado</li><li>Tarjets de Identificación emitida por el estado</li><li>Pasaportes y Tarjetas de Pasaportes</li><li>Tarjeta de Residencia Permanente</li><li>Identificación Militar</li><li>Fomulario Militar DD-214</li><li>Identificación Tribal</li>{br}El método que elija para verificar su identidad no afectará su capacidad para recibir beneficios.',
      workforce_more_info_text: 'Más información',
      workforce_moreinfo_link1:
        '<li><nm_link1>Encontrar su Centro Americano de Empleos Nuevo México</nm_link1></li><li><nm_link2>Información sobre el Seguro de Desempleo</nm_link2></li>{br}',
    },
  },
  NY: {
    name: 'New York',
    stateCode: 'NY',
  },
  NC: {
    name: 'North Carolina',
    stateCode: 'NC',
    showVerificationButton: true,
    chooseDifferentVerificationButton_USPS: true,
    showErrorPageButton_HitaWall: true,
    showLanguageDropdown: true,
    workforceRedirect: true,
    workforce_direct_navigation: 'https://www.des.nc.gov/',
    // usps-description component
    usps_state_title: 'N.C. Division of Employment Security',
    usps_state_email: 'des.nc.gov',
    redirect_email: 'https://des.nc.gov',
    usps_state_number: 'Toll Free at 888-737-0259.{br}',
    usps_state_timing_para:
      'Monday - Friday between 8 a.m. and 5 p.m. {br}Closed on State Holidays.{br}',
    uicp_state_title: ' ',
    uicp_state_number: ' ',
    uicp_state_timing_para: ' ',
    uicp_state_email: ' ',
    unemployment_question1:
      '{br}<b>N.C. Division of Employment Security</b> staff are available to assist you with questions about unemployment insurance benefits. {br}You may reach us at 888-737-0259. {br}Monday - Friday between 8 a.m. and 5 p.m. {br}Closed on State Holidays{br}<nc_link>des.nc.gov</nc_link>{br}{br}',
    unemployment_question2: ' ',
    workforce_radiobutton_label_text:
      'I want to choose a different verification method.',
    workforce_radiobutton_des_text:
      'Choosing this option will redirect you to your DES claimant portal.',
    workforce_radiobutton_doc_list_title: ' ',
    workforce_radiobutton_doc_list_text_first: ' ',
    workforce_radiobutton_doc_list_text_second: ' ',
    workforce_radiobutton_doc_list_text_third: ' ',
    workforce_radiobutton_doc_list_text_fourth: ' ',
    workforce_radiobutton_doc_list_text_fifth: ' ',
    workforce_radiobutton_doc_list_text_sixth: ' ',
    workforce_radiobutton_doc_list_text_seven: ' ',
    workforce_radiobutton_doc_list_text_eight: ' ',
    postOffice_text: 'The Post Office',
    hitawall_para2:
      '{br}Please try again later or choose a different verification method.{br}Thank you for your patience and understanding.',
    hitawall_para4: ' ',
    hitawall_para5: ' ',
    out_of_network_para2:
      '{br}<b>N.C. Division of Employment Security </b> staff are available to assist you with questions about unemployment insurance benefits. {br}You may reach us at 888-737-0259.{br}Monday - Friday between 8 a.m. and 5 p.m. {br}Closed on State Holidays{br}<nc_link>des.nc.gov</nc_link>',
    hitawall_para3: ' ',
    error_500_para3:
      '{br}<b>N.C. Division of Employment Security </b> staff are available to assist you with questions about unemployment insurance benefits. {br}You may reach us at 888-737-0259.{br}Monday - Friday between 8 a.m. and 5 p.m. {br}Closed on State Holidays{br}<nc_link>des.nc.gov</nc_link>',
    login_gov_help_txt: ' ',
    login_link: ' ',
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error404_para2:
      '<b>N.C. Division of Employment Security</b> staff are available to assist you with questions about unemployment insurance benefits. {br}You may reach us at 888-737-0259.{br}Monday - Friday between 8 a.m. and 5 p.m. {br}Closed on State Holidays{br}<nc_link>des.nc.gov</nc_link>',
    more_info_text1: ' ',
    moreinfo_link1: ' ',
    moreinfo_link2: ' ',
    moreinfo_link3: ' ',
    invalid_url_para3:
      '<b>N.C. Division of Employment Security </b> staff are available to assist you with questions about unemployment insurance benefits. {br}You may reach us at 888-737-0259.{br}Monday - Friday between 8 a.m. and 5 p.m. {br}Closed on State Holidays{br}<nc_link>des.nc.gov</nc_link>',
    moreinfo_link1_invalid: ' ',
    moreinfo_link2_invalid: ' ',
    moreinfo_link3_invalid: ' ',
    uicp_identity_footer_text:
      'The method you choose to verify your identity will not affect your ability to receive unemployment benefits. {br}State staff will process your application following all applicable laws and regulations.',
    choice_page_link: 'ID verification choice page. {br}',
    invalid_url_para4: ' ',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: ' ',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with N.C. Division of Employment Security.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with N.C. Division of Employment Security, <b>please file a report</b> of potential unemployment identity fraud with N.C. Division of Employment Security by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with N.C. Division of Employment Security through USPS.',
    hitawall_para6: ' ',
    hitawall_para7:
      '{br}{br}<b>N.C. Division of Employment Security </b> staff are available to assist you with questions about unemployment insurance benefits. {br}You may reach us at 888-737-0259.{br}Monday - Friday between 8 a.m. and 5 p.m. {br}Closed on State Holidays{br}<nc_link>des.nc.gov</nc_link>{br}',
    out_of_network_para4: ' ',
    // spanish
    es: {
      // App level components texts
      heading_helper_text:
        'Escriba su nombre y dirección exactamente como aparecen en su documento de identidad emitido por el gobierno estatal o federal.',
      // AddressSearch component
      personal_info_heading_text: 'Información personal',
      // Enrollment component
      enrollment_heading_text:
        'Visite Post Office<sup>TM</sup> antes de o para el dia',
      enrollment_code_bold_text: '<b>Código de inscripción</b>',
      print_this_pageBtn_text: 'Imprime esta página',
      enrollment_id_details_name_bold_text: '<b>Nombre:</b>',
      enrollment_id_details_email_bold_text:
        '<b>Dirección de correo electrónico:</b>',
      // Workforce Component
      workforce_radiobutton_label_text:
        'Quiero elegir un método de verificación diferente.',
      workforce_radiobutton_des_text:
        'Al elegir esta opción, se le redirigirá a su portal de solicitante del DES.',
      // usps-description component
      redirect_email: 'https://des.nc.gov',
      usps_state_title:
        'División de Seguridad del Empleo de Carolina del Norte',
      usps_state_number: 'Línea gratuita al 888-737-0259.{br}',
      usps_state_timing_para:
        'Lunes a viernes de 8 a.m. a 5 p.m. {br}Cerrado los días feriados estatales.{br}',
      uicp_state_title: ' ',
      uicp_state_number: ' ',
      uicp_state_timing_para: ' ',
      uicp_identity_footer_text:
        'El método escogido para verificar su identidad no afectará sus posibilidades de recibir beneficios de desempleo. El personal estatal procesará su solicitud siguiendo todas las leyes y reglamentos aplicables.',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      unemployment_question1:
        '{br}El personal de la <b>División de Seguridad de Empleo de Carolina del Norte </b>está disponible para ayudarlo con sus preguntas sobre los beneficios por desempleo.{br}Puede comunicarse con nosotros al 888-737-0259.{br} Lunes a viernes de 8 a.m. a 5 p.m. {br}Cerrado los días feriados estatales. {br}<nc_link>des.nc.gov</nc_link>{br}{br}',
      input_state_name_placeholder: 'Seleccione su estado',
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      usps_state_email: ' ',
      usps_contact: '<nc_link>des.nc.gov</nc_link>',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para2:
        'El personal de la <b>División de Seguridad de Empleo de Carolina del Norte</b> está disponible para ayudarlo con sus preguntas sobre los beneficios por desempleo. {br}Puede comunicarse con nosotros al 888-737-0259. {br}Lunes a viernes de 8 a.m. a 5 p.m. {br}Cerrado los días feriados estatales. {br}<nc_link>des.nc.gov</nc_link>',
      out_of_network_para3: ' ',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para3:
        'El personal de la <b>División de Seguridad de Empleo de Carolina del Norte</b> está disponible para ayudarlo con sus preguntas sobre los beneficios por desempleo. {br}Puede comunicarse con nosotros al 888-737-0259. {br}Lunes a viernes de 8 a.m. a 5 p.m. {br}Cerrado los días feriados estatales. {br}<nc_link>des.nc.gov</nc_link>{br}',
      invalid_url_heading: 'URL invalida',
      invalid_url_subheading:
        'Parece que la URL que ingresó no es válida o ya no existe.',
      invalid_url_para1:
        'No podemos ubicar su número de verificación de identificación asociado con su solicitud de desempleo.{br}',
      invalid_url_para2:
        'Es posible que la URL en su confirmación se haya ingresado incorrectamente. Vuelva a verificar la URL y vuelva a intentarlo.{br}',
      invalid_url_para3:
        'El personal de la <b>División de Seguridad de Empleo de Carolina del Norte</b> está disponible para ayudarlo con sus preguntas sobre los beneficios por desempleo. {br}Puede comunicarse con nosotros al 888-737-0259. {br}Lunes a viernes de 8 a.m. a 5 p.m. {br}Cerrado los días feriados estatales. {br}<nc_link>des.nc.gov</nc_link>',
      error404_para2:
        'El personal de la <b>División de Seguridad de Empleo de Carolina del Norte</b> está disponible para ayudarlo con sus preguntas sobre los beneficios por desempleo. {br}Puede comunicarse con nosotros al 888-737-0259. {br}Lunes a viernes de 8 a.m. a 5 p.m. {br}Cerrado los días feriados estatales. {br}<nc_link>des.nc.gov</nc_link>{br}{br}',
      postOffice_text: 'Post Office<sup>TM</sup>',
      hitawall_usps_heading: 'Tenemos problemas para conectar con USPS',
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde o elija un método de verificación diferente. {br}Gracias por su paciencia y comprensión.',
      hitawall_more_info_text: ' ',
      hitawall_para5: ' ',
      hitawall_para6: ' ',
      choice_page_link: 'opciones de verificación de identificación.',
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con División de Seguridad del Empleo de Carolina del Norte.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el División de Seguridad del Empleo de Carolina del Norte, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el División de Seguridad del Empleo de Carolina del Norte siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con División de Seguridad del Empleo de Carolina del Norte a través de USPS.',
      hitawall_para7:
        'El personal de la <b>División de Seguridad de Empleo de Carolina del Norte</b> está disponible para ayudarlo con sus preguntas sobre los beneficios por desempleo. {br}Puede comunicarse con nosotros al 888-737-0259. {br}Lunes a viernes de 8 a.m. a 5 p.m. {br}Cerrado los días feriados estatales. {br}<nc_link>des.nc.gov</nc_link>{br}{br}',
    },
  },
  ND: {
    name: 'North Dakota',
    stateCode: 'ND',
  },
  MP: {
    name: 'Northern Mariana Islands',
    stateCode: 'MP',
  },
  OH: {
    name: 'Ohio',
    stateCode: 'OH',
    showLanguageDropdown: false,
    showVerificationButton: false,
    showErrorPageButton_HitaWall: false,
    chooseDifferentVerificationButton_USPS: false,
    // usps-description component
    required_docs_heading: ' We use the address on your ID for verification.',
    required_docs_para:
      'If you live somewhere else or your ID has no address listed, you will also need proof of address:',
    usps_state_title: ' ',
    usps_state_number:
      '{br}You can contact the <b>Ohio Office of Unemployment Insurance</b> Operations by calling 1-877-644-6562 (or TTY 1-614-387-8408) between 8 a.m. and 5 p.m. Monday through Friday (except holidays). {br}You may also visit our website at <oh_link>unemployment.ohio.gov</oh_link>',
    usps_state_timing_para: ' ',
    uicp_state_title: ' ',
    uicp_state_number: ' ',
    uicp_state_timing_para: ' ',
    uicp_state_email: ' ',
    unemployment_question1:
      '{br}You can contact the <b>Ohio Office of Unemployment Insurance</b> Operations by calling 1-877-644-6562 (or TTY 1-614-387-8408) between 8 a.m. and 5 p.m. Monday through Friday (except holidays). {br}You may also visit our website at <oh_link>unemployment.ohio.gov</oh_link>{br}{br}',
    unemployment_question2: ' ',
    postOffice_text: 'The Post Office',
    uicp_identity_footer_text:
      'State staff will process your application following all applicable laws and regulations.',
    choice_page_link: 'ID verification choice page. {br}',
    login_gov_help_txt: ' ',
    login_link: ' ',
    // Error Pages:
    // Hit a wall
    hitawall_para2:
      '{br}Please try again later.{br}Thank you for your patience and understanding.',
    hitawall_para3: ' ',
    hitawall_para4:
      'You can contact the <b>Ohio Office of Unemployment Insurance</b> Operations by calling 1-877-644-6562 (or TTY 1-614-387-8408) between 8 a.m. and 5 p.m. Monday through Friday (except holidays). {br}You may also visit our website at <oh_link>unemployment.ohio.gov</oh_link>',
    hitawall_more_info_text: 'More Information',
    hitawall_para5:
      '<li><oh_link1>Unemployment Insurance | Job and Family Services</oh_link1></li><li><oh_link2>For additional information regarding required documentation please refer to The Worker’s Guide to Unemployment Insurance</oh_link2></li>',
    // No PostOffice Found
    out_of_network_para2:
      'If you believe the information you have entered is correct and matches your government issued identification, you can contact the <b>Ohio Office of Unemployment Insurance</b> Operations by calling 1-877-644-6562 (or TTY 1-614-387-8408) between 8 a.m. and 5 p.m. Monday through Friday (except holidays). {br}You may also visit our website at <oh_link>unemployment.ohio.gov</oh_link>{br}',
    out_of_network_more_info_text: 'More Information',
    out_of_network_para3:
      '<li><oh_link1>Unemployment Insurance | Job and Family Services</oh_link1></li><li><oh_link2>For additional information regarding required documentation please refer to The Worker’s Guide to Unemployment Insurance</oh_link2></li>',
    // Invalid URL Page:
    invalid_url_para3:
      'You can contact the <b>Ohio Office of Unemployment Insurance</b> Operations by calling 1-877-644-6562 (or TTY 1-614-387-8408) between 8 a.m. and 5 p.m. Monday through Friday (except holidays). {br}You may also visit our website at <oh_link>unemployment.ohio.gov</oh_link>',
    invalid_url_para4: ' ',
    more_info_text_invalid: 'More Information',
    moreinfo_link1_invalid:
      '<li><oh_link1>Unemployment Insurance | Job and Family Services</oh_link1></li><li><oh_link2>For additional information regarding required documentation please refer to The Worker’s Guide to Unemployment Insurance</oh_link2></li>',
    moreinfo_link2_invalid: ' ',

    // 404-Error Page:
    error404_para2:
      'You can contact the <b>Ohio Office of Unemployment Insurance</b> Operations by calling 1-877-644-6562 (or TTY 1-614-387-8408) between 8 a.m. and 5 p.m. Monday through Friday (except holidays). {br}You may also visit our website at <oh_link>unemployment.ohio.gov</oh_link>{br}{br}',
    more_info_text_404: 'More Information',
    moreinfo_link1:
      '<li><oh_link1>Unemployment Insurance | Job and Family Services</oh_link1></li><li><oh_link2>For additional information regarding required documentation please refer to The Worker’s Guide to Unemployment Insurance</oh_link2></li>',
    moreinfo_link2: ' ',
    moreinfo_link3: ' ',
    // 500-Error Page:
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para3:
      'You can contact the <b>Ohio Office of Unemployment Insurance</b> Operations by calling 1-877-644-6562 (or TTY 1-614-387-8408) between 8 a.m. and 5 p.m. Monday through Friday (except holidays). {br}You may also visit our website at <oh_link>unemployment.ohio.gov</oh_link>{br}{br}',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: 'More Information',
    moreinfo_link1_500:
      '<li><oh_link1>Unemployment Insurance | Job and Family Services</oh_link1></li><li><oh_link2>For additional information regarding required documentation please refer to The Worker’s Guide to Unemployment Insurance</oh_link2></li>',
    moreinfo_link2_500: ' ',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Ohio Office of Unemployment Insurance.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Ohio Office of Unemployment Insurance, <b>please file a report</b> of potential unemployment identity fraud with Ohio Office of Unemployment Insurance by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Ohio Office of Unemployment Insurance through USPS.',
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    out_of_network_para4: ' ',
  },
  OK: {
    name: 'Oklahoma',
    stateCode: 'OK',
  },
  OR: {
    name: 'Oregon',
    stateCode: 'OR',
    swaRegex: '^[0-9]{8}-[0-9]{6}-[0-9]{3}-[0-9]{9}$',
    showVerificationButton: false,
    chooseDifferentVerificationButton_USPS: false,
    showErrorPageButton_HitaWall: false,
    showLanguageDropdown: true,
    // english
    // uicp component
    workforce_radiobutton_label_text:
      '   I want to verify my identity in-person at an WorkSource Oregon office.',
    workforce_radiobutton_des_text:
      '  Bring your identity documents to the nearest WorkSource Oregon location or schedule an appointment at worksourceoregon.org/contact.',
    workforce_radiobutton_doc_list_title:
      ' A complete list of acceptable documents that can be used at a WorkSource Oregon location can be found at unemployment.oregon.gov/ID',
    workforce_radiobutton_doc_list_text_first: ' ',
    workforce_radiobutton_doc_list_text_second: ' ',
    workforce_radiobutton_doc_list_text_third: ' ',
    workforce_radiobutton_doc_list_text_fourth: ' ',
    workforce_radiobutton_doc_list_text_fifth: ' ',
    workforce_radiobutton_doc_list_text_sixth: ' ',
    workforce_radiobutton_doc_list_text_seven: ' ',
    workforce_radiobutton_doc_list_text_eight: ' ',
    uicp_identity_footer_text: ' ',
    unemployment_question2:
      'Here’s how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}{br}',
    // Enrollment component
    enrollment_description_para2:
      "If you fail to respond, your benefits may be delayed or denied and OED may require you to repay any benefits you've received from the Unemployment Insurance Program.",
    // usps-description component
    redirect_email: 'https://unemployment.oregon.gov/contact',
    usps_state_title: '<b>Oregon Employment Department</b>',
    usps_state_number: '877-345-3484 (Monday - Friday, 9 a.m. - 4 p.m.)',
    usps_state_timing_para: ' ',
    uicp_state_title: '<b>Oregon Employment Department</b>{br}',
    uicp_state_number: '877-345-3484 (Monday - Friday, 9 a.m. - 4 p.m.){br}',
    uicp_state_email: 'https://worksourceoregon.org/contact',
    usps_state_email: 'unemployment.oregon.gov/contact',
    unemployment_question1: ' ',

    // workforce component
    workforce_appointment_text:
      'Visit WorkSourceOregon.org to schedule an appointment.',
    workforce_para1:
      'The types of documents required by USPS may be different than the documents accepted at your WorkSource Oregon orientation. The acceptable documents for WorkSource can be found at ',
    para1_link_text: 'unemployment.oregon.gov/ID.',
    para1_link: 'https://unemployment.oregon.gov/verify-identity#section-2',
    workfore_para2:
      'The method you choose to verify your identity will not affect your ability to receive benefits.',
    workfore_para3:
      'If you have questions, please call the Unemployment Insurance Contact Center at 877-345-3484 or ',
    para3_link_text: 'submit a ticket.',
    para3_link: 'https://oedcontactus.oregon.gov/hc/en-us/requests/new',
    moreinfo_link1: ' ',
    moreinfo_link2: ' ',
    moreinfo_link3: ' ',
    href_link1: 'https://worksourceoregon.org/contact',
    moreinfo_link1_invalid: ' ',
    moreinfo_link2_invalid: ' ',
    moreinfo_link3_invalid: ' ',
    login_gov_help_txt: ' ',
    login_link: ' ',
    more_info_text1: ' ',
    workforce_more_info_text: 'More Information',
    workforce_moreinfo_link1: 'Find my local Oregon Employment Department',
    workforce_moreinfo_link2: ' ',
    workforce_moreinfo_link3: ' ',
    // error component
    // Hitawal component
    hitawall_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}{br}Please try again later.{br}Thank you for your patience and understanding.',
    hitawall_para2: ' ',
    hitawall_para3:
      'If you need help verifying your identity for your Oregon unemployment claim, please call us at 877-345-3484 (Monday - Friday, 9 a.m. - 4 p.m.) or send us an online message at <or_link>unemployment.oregon.gov/contact</or_link>{br}{br}',
    // error-500
    error_500_para1:
      '{br}We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.',
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para3:
      '{br}If you need help verifying your identity for your Oregon unemployment claim, please call us at 877-345-3484 (Monday - Friday, 9 a.m. - 4 p.m.) or send us an online message at <or_link>unemployment.oregon.gov/contact</or_link>',
    // page not found
    error404_para2:
      'If you need help verifying your identity for your Oregon unemployment claim, please call us at 877-345-3484 (Monday - Friday, 9 a.m. - 4 p.m.) or send us an online message at <or_link>unemployment.oregon.gov/contact</or_link>',
    // invalid url
    invalid_url_para3:
      'If you need help verifying your identity for your Oregon unemployment claim, please call us at 877-345-3484 (Monday - Friday, 9 a.m. - 4 p.m.) or send us an online message at <or_link>unemployment.oregon.gov/contact</or_link>',
    // out of network
    out_of_network_para2:
      'If you need help verifying your identity for your Oregon unemployment claim, please call us at 877-345-3484 (Monday - Friday, 9 a.m. - 4 p.m.) or send us an online message at <or_link>unemployment.oregon.gov/contact</or_link>',
    invalid_url_para4: ' ',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: ' ',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Oregon Employment Department.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Oregon Employment Department, <b>please file a report</b> of potential unemployment identity fraud with Oregon Employment Department by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Oregon Employment Department through USPS.',
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    out_of_network_para4: ' ',
    // spanish
    es: {
      // AddressSearch Component
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      // Enrollment component
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      enrollment_description_para2:
        'Si no responde, es posible que sus beneficios se retrasen o se nieguen y el DEO puede exigirle que pague los beneficios que haya recibido del Programa de Seguro de Desempleo.',
      // UICP Component
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      workforce_radiobutton_doc_list_title: ' ',
      unemployment_question2:
        'Si usted tiene preguntas sobre los beneficios de desempleo o si necesita ayuda para verificar su identidad puede contactarnos a través de:{br}{br}',
      // usps-description component
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      postOffice_text: 'Post Office<sup>TM</sup> ',
      usps_state_title: '<b>Departamento de Empleo de Oregon</b>',
      usps_state_number: '877-345-3484 (Lunes a viernes, 9 a.m. - 4 p.m.)',
      uicp_state_title: '<b>Departamento de Empleo de Oregon</b>{br}',
      uicp_state_number: '877-345-3484 (Lunes a viernes, 9 a.m. - 4 p.m.){br}',
      usps_state_timing_para: ' ',
      redirect_email: 'https://worksourceoregon.org/contact',
      // workforce component
      workforce_radiobutton_label_text:
        'Quiero verificar mi identidad en persona visitando uno de los centros de WorkSource más cercano.',
      workforce_radiobutton_des_text:
        'Lleve sus documentos de identidad a uno de los centros de WorkSource más cercano, o programe una cita en worksourceoregon.org/contact.{br}Puede encontrar una lista completa de documentos aceptables que sepueden usar en una ubicación de WorkSource Oregon en unemployment.oregon.gov/ID',
      workforce_appointment_text:
        'Visite WorkSourceOregon.org para completar una cita.',
      workforce_para1:
        'Los documentos requeridos por USPS pueden ser diferentes a los documentos aceptados en su orientación de WorkSource Oregon. Puede encontrar una lista completa de documentos aceptables que se pueden usar en una ubicación de WorkSource Oregon en ',
      workfore_para2:
        'El método escogido para verificar su identidad no afectará sus posibilidades de recibir beneficios de desempleo. El personal estatal procesará su solicitud siguiendo todas las leyes y reglamentos aplicables.',
      workfore_para3:
        'Si tiene preguntas, llame al Centro de Contacto del Seguro de Desempleo al 877-345-3484 o ',
      para3_link_text: 'envíe un ticket.',
      para3_link: 'https://oedcontactus.oregon.gov/hc/en-us/requests/new',
      workforce_more_info_text: 'Más información',
      moreinfo_link1: ' ',
      workforce_moreinfo_link1:
        'Visite su oficina local Departamento de Empleo de Oregon.',
      moreinfo_link2: ' ',
      moreinfo_link3: ' ',
      // error component
      invalid_url_para3:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Oregon, llámenos al 877-345-3484 (Lunes a viernes, 9 a.m. - 4 p.m.) o envíenos un mensaje en línea a <or_link>unemployment.oregon.gov/contact</or_link>{br}',
      // Hitawal component
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      hitawall_para3:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Oregon, llámenos al 877-345-3484 (Lunes a viernes, 9 a.m. - 4 p.m.) o envíenos un mensaje en línea a <or_link>unemployment.oregon.gov/contact</or_link>{br}{br}{br}',
      hitawall_para4: ' ',
      hitawall_more_info_text: ' ',
      hitawall_para5: ' ',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_no_participate_para1:
        'No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_no_participate_para2: ' ',
      out_of_network_para1:
        'Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para2:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Oregon, llámenos al 877-345-3484 (Lunes a viernes, 9 a.m. - 4 p.m.) o envíenos un mensaje en línea a <or_link>unemployment.oregon.gov/contact</or_link>{br}',
      out_of_network_para3: ' ',
      error_500_more_info_text1: ' ',
      error_500_more_info_text2: ' ',
      error_500_title: 'Tenemos problemas para conectar ',
      error_500_heading:
        'La verificación de su identidad no está disponible actualmente.',
      error_500_para1:
        '{br}Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. ',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para3:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Oregon, llámenos al 877-345-3484 (Lunes a viernes, 9 a.m. - 4 p.m.) o envíenos un mensaje en línea a <or_link>unemployment.oregon.gov/contact</or_link>{br}',
      // page not found
      error404_para2:
        'Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Oregon, llámenos al 877-345-3484 (Lunes a viernes, 9 a.m. - 4 p.m.) o envíenos un mensaje en línea a <or_link>unemployment.oregon.gov/contact</or_link>{br}',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Empleo de Oregon.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Departamento de Empleo de Oregon, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Departamento de Empleo de Oregon siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Empleo de Oregon a través de USPS.',
    },
  },
  PW: {
    name: 'Palau',
    stateCode: 'PW',
  },
  PA: {
    name: 'Pennsylvania',
    stateCode: 'PA',
  },
  PR: {
    name: 'Puerto Rico',
    stateCode: 'PR',
  },
  RI: {
    name: 'Rhode Island',
    stateCode: 'RI',
  },
  SC: {
    name: 'South Carolina',
    stateCode: 'SC',
    showVerificationButton: false,
    chooseDifferentVerificationButton_USPS: false,
    showErrorPageButton_HitaWall: false,
    workforceRedirect: true,
    showLanguageDropdown: true,
    workforce_direct_navigation: 'https://dew.sc.gov/',
    // english
    // uicp component
    workforce_radiobutton_label_text:
      'I want to choose a different verification method.',
    workforce_radiobutton_des_text:
      'Choosing this option will redirect you to your SCDEW MyBenefits Portal.',
    workforce_radiobutton_doc_list_title: ' ',
    workforce_radiobutton_doc_list_text_first: ' ',
    workforce_radiobutton_doc_list_text_second: ' ',
    workforce_radiobutton_doc_list_text_third: ' ',
    workforce_radiobutton_doc_list_text_fourth: ' ',
    workforce_radiobutton_doc_list_text_fifth: ' ',
    workforce_radiobutton_doc_list_text_sixth: ' ',
    workforce_radiobutton_doc_list_text_seven: ' ',
    workforce_radiobutton_doc_list_text_eight: ' ',
    // usps-description component
    required_docs_heading: ' We use the address on your ID for verification.',
    redirect_email: 'https://dew.sc.gov/',
    usps_state_title:
      '<b>South Carolina Department of Employment and Workforce</b>',
    usps_state_number: 'Toll Free at 1-866-831-1724{br}',
    usps_state_timing_para:
      'Monday - Friday between 8 a.m. and 4:30 p.m.{br}Closed on State Holidays',
    uicp_identity_footer_text: ' ',
    uicp_state_title:
      '<b>South Carolina Department of Employment and Workforce</b> staff are available to assist with questions about unemployment insurance benefits.{br}',
    uicp_state_number:
      'Staff can be reached at 1-866-831-1724.{br}Monday - Friday between 8 a.m. and 4:30 p.m.{br}Closed on State Holidays.{br}',
    uicp_state_email: '<u>dew.sc.gov</u>{br}{br}{br}',
    usps_state_email: '<u>dew.sc.gov</u>',
    unemployment_question1: ' ',
    login_gov_help_txt: ' ',
    login_link: ' ',
    postOffice_text: 'The Post Office',
    // error component
    // Hitawal component
    hitawall_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}',
    hitawall_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    hitawall_para4:
      '<b>South Carolina Department of Employment and Workforce</b> staff are available to assist with questions about unemployment insurance benefits.{br}{br}Staff can be reached at 1-866-831-1724.{br}Monday - Friday between 8 a.m. and 4:30 p.m.{br}Closed on State Holidays.{br}<sc_link>dew.sc.gov</sc_link>{br}{br}',
    // error-500
    error_500_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.',
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para3:
      '<b>South Carolina Department of Employment and Workforce</b> staff are available to assist with questions about unemployment insurance benefits.{br}{br}Staff can be reached at 1-866-831-1724.{br}Monday - Friday between 8 a.m. and 4:30 p.m.{br}Closed on State Holidays.{br}<sc_link>dew.sc.gov</sc_link>{br}{br}',
    // page not found
    error404_para1:
      '{br}We can’t find the page you are looking for. Please check your URL or return to the ',
    error404_para2:
      '<b>South Carolina Department of Employment and Workforce</b> staff are available to assist with questions about unemployment insurance benefits.{br}{br}Staff can be reached at 1-866-831-1724.{br}Monday - Friday between 8 a.m. and 4:30 p.m.{br}Closed on State Holidays.{br}<sc_link>dew.sc.gov</sc_link>',
    // invalid url
    invalid_url_para3:
      '<b>South Carolina Department of Employment and Workforce</b> staff are available to assist with questions about unemployment insurance benefits.{br}{br}Staff can be reached at 1-866-831-1724.{br}Monday - Friday between 8 a.m. and 4:30 p.m.{br}Closed on State Holidays.{br}<sc_link>dew.sc.gov</sc_link>',
    // out of network
    out_of_network_para2:
      '<b>South Carolina Department of Employment and Workforce</b> staff are available to assist with questions about unemployment insurance benefits.{br}{br}Staff can be reached at 1-866-831-1724.{br}Monday - Friday between 8 a.m. and 4:30 p.m.{br}Closed on State Holidays.{br}<sc_link>dew.sc.gov</sc_link>{br}{br}',
    invalid_url_para4: ' ',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: ' ',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with South Carolina Department of Employment and Workforce.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with South Carolina Department of Employment and Workforce, <b>please file a report</b> of potential unemployment identity fraud with South Carolina Department of Employment and Workforce by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to confirm your identity for your unemployment claim with <b>South Carolina Department of Employment and Workforce</b> through USPS.',
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    out_of_network_para4: ' ',
    out_of_network_para_text1:
      'If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.{br}{br}',
    out_of_network_para1:
      '{br}If the information you have entered is correct and matches your government issued identification, you can choose a different verification method.',
    enrollment_description_para2: ' ',
    // spanish
    es: {
      // Fraud Modal
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Empleo y Fuerza Laboral de Carolina del Sur.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Departamento de Empleo y Fuerza Laboral de Carolina del Sur, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Departamento de Empleo y Fuerza Laboral de Carolina del Sur siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Empleo y Fuerza Laboral de Carolina del Sur a través de USPS.',
      // workforce component
      workforce_radiobutton_label_text:
        'Quiero elegir un método de verificación diferente.',
      workforce_radiobutton_des_text: `Esta opción lo redirigirá al portal 'MyBenefits' del SCDEW.`,
      workforce_radiobutton_doc_list_title: ' ',
      workforce_radiobutton_doc_list_text_first: ' ',
      workforce_radiobutton_doc_list_text_second: ' ',
      workforce_radiobutton_doc_list_text_third: ' ',
      workforce_radiobutton_doc_list_text_fourth: ' ',
      workforce_radiobutton_doc_list_text_fifth: ' ',
      workforce_radiobutton_doc_list_text_sixth: ' ',
      workforce_radiobutton_doc_list_text_seven: ' ',
      workforce_radiobutton_doc_list_text_eight: ' ',
      // UICP page
      unemployment_question1: ' ',
      unemployment_question2: ' ',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      uicp_identity_footer_text: ' ',
      uicp_state_title:
        'El personal del <b>Departamento de Empleo y Fuerza Laboral de Carolina del Sur</b> está disponible para responder sus preguntas sobre los beneficios por desempleo.{br}',
      uicp_state_number:
        'Comuníquese con nosotros al 1-866-831-1724.{br}De lunes a viernes, de 8 a.m. a 4:30 p.m.{br}Cerrado los días feriados estatales.{br}',
      uicp_state_email: '<u>dew.sc.gov</u>{br}{br}{br}',
      // AddressSearch Component
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      // Enrollment component
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      // usps-description component
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      postOffice_text: 'Post Office<sup>TM</sup> ',
      usps_state_title:
        '<b>Departamento de Empleo y Fuerza Laboral de Carolina del Sur</b>',
      usps_state_number: 'Línea gratuita: 1-866-831-1724{br}',
      usps_state_timing_para:
        'De lunes a viernes, de 8 a.m. a 4:30 p.m.{br}Cerrado los días feriados estatales.',
      usps_state_email: '<u>dew.sc.gov</u>',
      redirect_email: 'https://dew.sc.gov/',
      // error component
      // Error page(common key)
      login_gov_help_txt: ' ',
      login_link: ' ',
      // Invalid url
      invalid_url_para3:
        'El personal del <b>Departamento de Empleo y Fuerza Laboral de Carolina del Sur</b> está disponible para responder sus preguntas sobre los beneficios por desempleo.{br}{br}Comuníquese con nosotros al 1-866-831-1724.{br} De lunes a viernes, de 8 a.m. a 4:30 p.m.{br} Cerrado los días feriados estatales.{br}<sc_link>dew.sc.gov</sc_link>',
      invalid_url_para4: ' ',
      more_info_text_invalid: ' ',
      moreinfo_link1_invalid: ' ',
      moreinfo_link2_invalid: ' ',
      // Hitawal component
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      hitawall_para4:
        'El personal del <b>Departamento de Empleo y Fuerza Laboral de Carolina del Sur</b> está disponible para responder sus preguntas sobre los beneficios por desempleo.{br}{br}Comuníquese con nosotros al 1-866-831-1724.{br} De lunes a viernes, de 8 a.m. a 4:30 p.m.{br} Cerrado los días feriados estatales.{br}<sc_link>dew.sc.gov</sc_link>{br}{br}{br}',
      hitawall_para3: ' ',
      hitawall_more_info_text: ' ',
      hitawall_para5: ' ',
      // Out of network(No post office found)
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_no_participate_para1:
        'No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_no_participate_para2: ' ',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para2:
        'El personal del <b>Departamento de Empleo y Fuerza Laboral de Carolina del Sur</b> está disponible para responder sus preguntas sobre los beneficios por desempleo.{br}{br}Comuníquese con nosotros al 1-866-831-1724.{br} De lunes a viernes, de 8 a.m. a 4:30 p.m.{br} Cerrado los días feriados estatales.{br}<sc_link>dew.sc.gov</sc_link>{br}{br}{br}',
      out_of_network_para3: ' ',
      // Error-500
      error_500_more_info_text1: ' ',
      error_500_more_info_text2: ' ',
      error_500_title: 'Tenemos problemas para conectar ',
      error_500_heading:
        'La verificación de su identidad no está disponible actualmente.',
      error_500_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. ',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para3:
        'El personal del <b>Departamento de Empleo y Fuerza Laboral de Carolina del Sur</b> está disponible para responder sus preguntas sobre los beneficios por desempleo.{br}{br}Comuníquese con nosotros al 1-866-831-1724.{br} De lunes a viernes, de 8 a.m. a 4:30 p.m.{br} Cerrado los días feriados estatales.{br}<sc_link>dew.sc.gov</sc_link>{br}',
      // Page not found(404)
      error404_para1:
        '{br}Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de ',
      choice_page_link: 'opciones de verificación de identificación.',
      error404_para2:
        'El personal del <b>Departamento de Empleo y Fuerza Laboral de Carolina del Sur</b> está disponible para responder sus preguntas sobre los beneficios por desempleo.{br}{br}Comuníquese con nosotros al 1-866-831-1724.{br} De lunes a viernes, de 8 a.m. a 4:30 p.m.{br} Cerrado los días feriados estatales.{br}<sc_link>dew.sc.gov</sc_link>{br}{br}',
      more_info_text1: ' ',
      moreinfo_link1: ' ',
      moreinfo_link2: ' ',
      moreinfo_link3: ' ',
      enrollment_description_para2: ' ',
    },
  },
  SD: {
    name: 'South Dakota',
    stateCode: 'SD',
  },
  TN: {
    name: 'Tennessee',
    stateCode: 'TN',
  },
  TX: {
    name: 'Texas',
    stateCode: 'TX',
    showVerificationButton: false,
    chooseDifferentVerificationButton_USPS: false,
    showErrorPageButton: false,
    showLanguageDropdown: true,
    identity_text: ' ',
    // english
    // usps-description component
    required_docs_heading: ' We use the address on your ID for verification.',
    redirect_email: 'https://www.twc.texas.gov/',
    usps_state_title: ' ',
    usps_state_number: ' ',
    usps_state_timing_para:
      '<b>Texas Workforce Commission</b> Unemployment Benefits staff are available to assist you with questions about your claim.{br}You may reach us at 800-939-6631.{br}Monday - Friday, except for state holidays.',
    uicp_identity_footer_text:
      '<b>Texas Workforce Commission</b> Unemployment Benefits staff are available to assist you with questions about your claim.{br}You may reach us at 800-939-6631.{br}Monday - Friday, except for state holidays.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>{br}',
    uicp_state_title: ' ',
    uicp_state_number: ' ',
    uicp_state_email: ' ',
    usps_state_email: '<u>www.twc.texas.gov</u>',
    unemployment_question1: ' ',
    login_gov_help_txt: ' ',
    login_link: ' ',
    postOffice_text: 'The Post Office',
    // error component
    // Hitawal component
    hitawall_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}{br} Please try again later.{br}Thank you for your patience and understanding.',
    hitawall_para2: ' ',
    hitawall_para4:
      '{br}<b>Texas Workforce Commission</b> Unemployment Benefits staff are available to assist you with questions about your claim.{br}You may reach us at 800-939-6631.{br}Monday - Friday, except for state holidays.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>{br}{br}',
    // error-500
    error_500_para1:
      '{br}We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.',
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para3:
      '{br}<b>Texas Workforce Commission</b> Unemployment Benefits staff are available to assist you with questions about your claim.{br}You may reach us at 800-939-6631.{br}Monday - Friday, except for state holidays.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>{br}{br}',
    // page not found
    error404_para1:
      '{br}We can’t find the page you are looking for. Please check your URL or return to the ',
    error404_para2:
      '<b>Texas Workforce Commission</b> Unemployment Benefits staff are available to assist you with questions about your claim.{br}You may reach us at 800-939-6631.{br}Monday - Friday, except for state holidays.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>',
    // invalid url
    invalid_url_para3:
      '<b>Texas Workforce Commission</b> Unemployment Benefits staff are available to assist you with questions about your claim.{br}You may reach us at 800-939-6631.{br}Monday - Friday, except for state holidays.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>',
    // out of network
    out_of_network_para2:
      '{br}<b>Texas Workforce Commission</b> Unemployment Benefits staff are available to assist you with questions about your claim.{br}You may reach us at 800-939-6631.{br}Monday - Friday, except for state holidays.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>{br}{br}',
    invalid_url_para4: ' ',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: ' ',
    fraud_modal_para1:
      'You are about to <b>register to use USPS to confirm your identity for your unemployment claim</b> with Texas Workforce Commission.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Texas Workforce Commission, <b>please file a report</b> of potential unemployment identity fraud with Texas Workforce Commission by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You are about to <b>register to use USPS to confirm your identity for your unemployment claim</b> with Texas Workforce Commission.',
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    out_of_network_para4: ' ',
    out_of_network_para_text1:
      'If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.{br}{br}',
    out_of_network_para1:
      '{br}If the information you have entered is correct and matches your government issued identification, you can choose a different verification method.',
    es: {
      identity_text: ' ',
      redirect_email: 'https://www.twc.texas.gov/',
      usps_state_title: ' ',
      usps_state_number: ' ',
      usps_state_timing_para:
        'El personal de Beneficios de Desempleo de la <b>Comisión de la Fuerza Laboral de Texas</b> está disponible para ayudarlo con preguntas sobre su reclamo. Puede comunicarse con nosotros al 800-939-6631.{br}De lunes a viernes, excepto los días festivos estatales.',
      uicp_identity_footer_text:
        'El personal de Beneficios de Desempleo de la <b>Comisión de la Fuerza Laboral de Texas</b> está disponible para ayudarlo con preguntas sobre su reclamo. Puede comunicarse con nosotros al 800-939-6631.{br}De lunes a viernes, excepto los días festivos estatales.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>{br}',
      uicp_state_title: ' ',
      uicp_state_number: ' ',
      uicp_state_email: ' ',
      usps_state_email: '<u>www.twc.texas.gov</u>',
      unemployment_question1: ' ',
      login_gov_help_txt: ' ',
      login_link: ' ',
      input_zipcode: 'Código Postal',
      hitawall_para4:
        '{br}El personal de Beneficios de Desempleo de la <b>Comisión de la Fuerza Laboral de Texas</b> está disponible para ayudarlo con preguntas sobre su reclamo. Puede comunicarse con nosotros al 800-939-6631.{br}De lunes a viernes, excepto los días festivos estatales.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>{br}{br}',
      error_500_para3:
        '{br}El personal de Beneficios de Desempleo de la <b>Comisión de la Fuerza Laboral de Texas</b> está disponible para ayudarlo con preguntas sobre su reclamo. Puede comunicarse con nosotros al 800-939-6631.{br}De lunes a viernes, excepto los días festivos estatales.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>{br}{br}',
      error404_para2:
        'El personal de Beneficios de Desempleo de la <b>Comisión de la Fuerza Laboral de Texas</b> está disponible para ayudarlo con preguntas sobre su reclamo. Puede comunicarse con nosotros al 800-939-6631.{br}De lunes a viernes, excepto los días festivos estatales.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>',
      invalid_url_para3:
        'El personal de Beneficios de Desempleo de la <b>Comisión de la Fuerza Laboral de Texas</b> está disponible para ayudarlo con preguntas sobre su reclamo. Puede comunicarse con nosotros al 800-939-6631.{br}De lunes a viernes, excepto los días festivos estatales.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>',
      out_of_network_para2:
        '{br}El personal de Beneficios de Desempleo de la <b>Comisión de la Fuerza Laboral de Texas</b> está disponible para ayudarlo con preguntas sobre su reclamo. Puede comunicarse con nosotros al 800-939-6631.{br}De lunes a viernes, excepto los días festivos estatales.{br}<tx_link><u>www.twc.texas.gov</u></tx_link>{br}{br}',
      invalid_url_subheading:
        'Parece que el enlace URL que ingresó ya no es válido o ya no existe.',
      invalid_url_para2:
        'Es posible que el enlace URL en su confirmación se haya ingresado incorrectamente. <b>Revise el enlace URL y vuelva a intentarlo.</b>{br}',
      invalid_url_para4: ' ',
      error_500_more_info_text1: ' ',
      error_500_more_info_text2: ' ',
      fraud_modal_header:
        'Bienvenido a la página de verificación de identidad del Departamento de Trabajo de los Estados Unidos.',
      fraud_modal_para1:
        'Está a punto de <b>registrarse para utilizar USPS para confirmar su identidad para su reclamo de desempleo</b> ante la Comisión de la Fuerza Laboral de Texas.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con la Comisión de la Fuerza Laboral de Texas, <b>presente un informe</b> de posible fraude de identidad por desempleo ante la Comisión de la Fuerza Laboral de Texas siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>registrarse para utilizar USPS para confirmar su identidad para su reclamo de desempleo</b> ante la Comisión de la Fuerza Laboral de Texas.',
      hitawall_para6: ' ',
      hitawall_para7: ' ',
      out_of_network_para4: ' ',
      unemployment_question2: ' ',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      usps_radiobutton_doc_list_text_third:
        'Identificación de las Fuerzas Armadas de los EE. UU. o del servicio uniformado (requiere una identificación adicional)',
      usps_radiobutton_doc_list_text_fourth:
        'Pasaporté estadunidense (requiere una identificación adicional)',
      post_office_visit_text:
        'Confirmación de visita a la Oficina del Correo Postal (Post Office<sup>TM</sup>)',
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      postOffice_text:
        'La Oficina del Correo Postal (Post Office<sup>TM</sup>) ',
      uniformed_id_cards:
        'Identificación de las Fuerzas Armadas de los EE. UU. o del servicio uniformado (requiere una identificación adicional)',
      secondary_id:
        'Pasaporté estadunidense (requiere una identificación adicional)',
      enrollment_heading_text:
        'Visite la Oficina del Correo Postal (Post Office<sup>TM</sup>) antes de o para el dia ',
      moreinfo_link1_invalid: ' ',
      moreinfo_link2_invalid: ' ',
      hitawall_usps_subheading:
        'La verificación de su identidad con USPS no está disponible en este momento.',
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      hitawall_para3: ' ',
      hitawall_more_info_text: ' ',
      hitawall_para5: ' ',
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_subheading:
        'No hay una Oficina de Correo Postal Oficina (Post Office<sup>TM</sup>) participante detro de 50 millas de su direccion para completar la verificacion de identidad en persona con USPS en este momento.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para3: ' ',
      error_500_para1:
        '{br}Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. ',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error404_para1:
        '{br}Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de ',
      moreinfo_link1: ' ',
      moreinfo_link2: ' ',
    },
  },
  UT: {
    name: 'Utah',
    stateCode: 'UT',
    showFAQButton: 'false',
    showVerificationButton: false,
    showSwaXid: true,
    chooseDifferentVerificationButton_USPS: true,
    showLanguageDropdown: true,
    // english
    online_radiobutton_label_text: 'Online - Login.gov',
    // usps-description component
    redirect_email: 'https://jobs.utah.gov/ui/home/',
    // Confirmation & logout component
    confirmation_next_step_text: 'More information',
    logout_next_step_text: '<h3>More Information</h3>',
    more_info_text0: 'More Information',
    confirmation_content_extra:
      'If you have questions about unemployment benefits, here’s how to reach{br}<b>Utah Department of Workforce Services:</b>{br}Unemployment Insurance Division{br}801-526-4400 or 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>{br}{br}',
    confirmation_steps_content_line_first: ' ',
    confirmation_steps_content_line_second: ' ',
    questions_para2:
      '{br}If you have questions about unemployment benefits, here’s how to reach',
    department_workforce: ' <b>Utah Department of Workforce Services:</b>{br}',
    uicp_identity_footer_text: ' ',
    unemployment_question1:
      'If you have questions about the Login.gov verification process, contact the Login.gov team: <login_link>https://www.login.gov/contact/</login_link>{br}',
    more_information: '<h3>More information</h3>',
    unemployment_question2:
      'If you have questions about unemployment benefits, here’s how to reach{br} ',
    uicp_state_title: 'Unemployment Insurance Division{br}',
    uicp_state_number: '801-526-4400 or 1-888-848-0688{br}',
    uicp_state_timing_para:
      '<ut_link>https://jobs.utah.gov/ui/home/</ut_link>{br}{br}',
    uicp_state_email: ' ',
    href_link1: ' ',
    moreinfo_link1_500: ' ',
    moreinfo_link2_500: ' ',
    moreinfo_link3_500: ' ',
    moreinfo_link1_invalid: ' ',
    moreinfo_link2_invalid: ' ',
    moreinfo_link3_invalid: ' ',
    login_gov_help_txt: ' ',
    login_link: ' ',
    error_invalid_login_para4:
      'If you have questions about unemployment benefits, here’s how to reach{br}<b>Utah Department of Workforce Services:</b>{br}Unemployment Insurance Division{br}801-526-4400 or 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>',
    hitawall_para1:
      'We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.{br}',
    hitawall_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    hitawall_para3: ' ',
    hitawall_para4: ' ',
    hitawall_para5: ' ',
    hitawall_para6: ' ',
    // error-500
    error_500_para2:
      'Please try again later.{br}Thank you for your patience and understanding.',
    error_500_para3:
      'If you have questions about unemployment benefits, here’s how to reach{br}<b>Utah Department of Workforce Services:</b>{br}Unemployment Insurance Division{br}801-526-4400 or 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>',
    // page not found
    error404_para2:
      'If you have questions about unemployment benefits, here’s how to reach{br}<b>Utah Department of Workforce Services:</b>{br}Unemployment Insurance Division{br}801-526-4400 or 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>',
    // invalid url
    invalid_url_para3: ' ',
    invalid_url_para4:
      'If you have questions about unemployment benefits, here’s how to reach{br}<b>Utah Department of Workforce Services:</b>{br}Unemployment Insurance Division{br}801-526-4400 or 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>{br}',
    confirmation_checkmark: 'Your identity verification was completed',
    confirmation_state_title: ' ',
    confirmation_swa_id_text:
      'The results will be updated with DWS Unemployment within one business day.',
    error_scroll_to_top_text: '{br}Return to top{br}{br}',
    login_link_invalid: ' ',
    error_invalid_login_para3:
      'If you have questions about the Login.gov verification process, contact the Login.gov team <login_link>https://www.login.gov/contact/</login_link>',
    moreinfo_link1: ' ',
    moreinfo_link2: ' ',
    moreinfo_link3: ' ',
    error404_para1:
      'We can’t find the page you are looking for. Please check your URL or return to the ',
    error404_state_title: ' ',
    uicp_more_info_text: ' ',
    logout_intro: ' You may close this window.',
    invalid_url_para2:
      'The URL in your confirmation may have been entered incorrectly. Please double-check the URL and try again.',
    more_info_text1: ' ',
    hitawall_more_info_text: 'More Information',
    error_500_more_info_text1: 'More Information',
    error_500_more_info_text2: ' ',
    more_info_text_invalid: 'More Information',
    error404_more_info_text: 'More Information',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Utah Department of Workforce Services.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with Utah Department of Workforce Services, <b>please file a report</b> of potential unemployment identity fraud with Utah Department of Workforce Services by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with Utah Department of Workforce Services through USPS.',
    moreinfo_confirmation_link1: ' ',
    moreinfo_logout_link: ' ',
    hitawall_para7:
      'If you have questions about unemployment benefits, here’s how to reach{br}<b>Utah Department of Workforce Services:</b>{br}Unemployment Insurance Division{br}801-526-4400 or 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>{br}',
    es: {
      online_radiobutton_label_text: 'Login.gov',
      // usps-description component
      usps_state_number: '801-526-4400 o 1-888-848-0688',
      usps_state_name: 'División de Seguro de Desempleo',
      usps_state_timing_para: '  ',
      online_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      online_radiobutton_doc_list_text_first:
        '<li>Documento de identidad estadual</li>',
      online_radiobutton_doc_list_text_second: ' ',
      uicp_identity_footer_text: ' ',
      unemployment_question1:
        'Si tiene preguntas sobre el proceso de verificación de Login.gov, comuníquese con el equipo de Login.gov: <login_link>https://www.login.gov/contact/</login_link>',
      more_information: '<h3>Más información</h3>',
      unemployment_question2:
        'Si tiene preguntas sobre los beneficios de desempleo, así es como puede comunicarse con{br}',
      uicp_state_title:
        '<b>Departamento de Servicios Laborales de Utah:</b>{br}División de Seguro de Desempleo{br}',
      uicp_state_number: ' ',
      uicp_state_timing_para:
        '801-526-4400 o 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>{br}{br}',
      uicp_state_email: ' ',
      more_info_text0: 'Más información',
      confirmation_checkmark: 'Su verificación de identidad se completó',
      confirmation_date_seperator: 'el',
      confirmation_swa_id_text:
        'Los resultados se actualizarán con DWS Unemployment dentro de un día hábil.',
      confirmation_next_step_text: 'Más información',
      confirmation_content_extra:
        'Si tiene preguntas sobre los beneficios de desempleo, así es como puede comunicarse con {br}<b>Departamento de Servicios Laborales de Utah:</b>{br} División de Seguro de Desempleo {br}801-526-4400 o 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>{br}{br}',
      confirmation_steps_content_line_first: ' ',
      logout_intro: 'Puede cerrar esta ventana.',
      logout_next_step_text: '<h3>Más información</h3>',
      error_invalid_login_para3:
        'Si tiene preguntas sobre el proceso de verificación de Login.gov, comuníquese con el equipo de Login.gov: <login_link>https://www.login.gov/contact/</login_link>',
      more_info_text1: ' ',
      error_invalid_login_para4:
        'Si tiene preguntas sobre los beneficios de desempleo, así es como puede comunicarse con{br}<b>Departamento de Servicios Laborales de Utah:</b>{br} División de Seguro de Desempleo {br}801-526-4400 o 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>',
      error_invalid_login_para5: ' ',
      error_invalid_login_para6: ' ',
      more_info_text2: ' ',
      error_invalid_login_moreinfo_link1: ' ',
      error_invalid_login_moreinfo_link2: ' ',
      error_invalid_login_moreinfo_link3: ' ',
      hitawall_login_subheading:
        'La verificación de su identidad con Login.gov no está disponible actualmente.',
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde. {br} Gracias por su paciencia y comprensión.',
      hitawall_more_info_text: 'Más información',
      hitawall_para5: ' ',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde. {br}Gracias por su paciencia y comprensión. ',
      error_500_more_info_text1: 'Más información',
      error_500_para3:
        'Si tiene preguntas sobre los beneficios de desempleo, así es como puede comunicarse con{br}<b>Departamento de Servicios Laborales de Utah:</b>{br} División de Seguro de Desempleo {br}801-526-4400 o 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>{br}{br}',
      error404_para1:
        'Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de opciones de ',
      choice_page_link: 'opciones de verificación de identificación.',
      error404_more_info_text: 'Más información',
      error404_para2:
        'Si tiene preguntas sobre los beneficios de desempleo, así es como puede comunicarse con{br}<b>Departamento de Servicios Laborales de Utah:</b>{br} División de Seguro de Desempleo {br}801-526-4400 o 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>{br}{br}',
      more_info_text_invalid: 'Más información',
      invalid_url_para4:
        'Si tiene preguntas sobre los beneficios de desempleo, así es como puede comunicarse con{br}<b>Departamento de Servicios Laborales de Utah:</b>{br} División de Seguro de Desempleo {br}801-526-4400 o 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>{br}{br}',
      error_scroll_to_top_text: 'Volver al principio',
      department_workforce: ' ',
      invalid_url_para2:
        'Es posible que la URL en su confirmación se haya ingresado incorrectamente. Vuelva a verificar la URL y vuelva a intentarlo.',
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Servicios Laborales de Utah.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el Departamento de Servicios Laborales de Utah, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Departamento de Servicios Laborales de Utah siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Servicios Laborales de Utah a través de USPS.',
      hitawall_para7:
        'Si tiene preguntas sobre los beneficios de desempleo, así es como puede comunicarse con{br}<b>Departamento de Servicios Laborales de Utah:</b>{br} División de Seguro de Desempleo {br}801-526-4400 o 1-888-848-0688{br}<ut_link>https://jobs.utah.gov/ui/home/</ut_link>{br}{br}',
    },
  },
  VT: {
    name: 'Vermont',
    stateCode: 'VT',
  },
  VI: {
    name: 'Virgin Islands',
    stateCode: 'VI',
  },
  VA: {
    name: 'Virginia',
    stateCode: 'VA',
  },
  WA: {
    name: 'Washington',
    stateCode: 'WA',
  },
  WV: {
    name: 'West Virginia',
    stateCode: 'WV',
    showFAQButton: 'false',
    showErrorPageButton_HitaWall: true,
    showVerificationButton: true,
    chooseDifferentVerificationButton_USPS: true,
    showLanguageDropdown: true,
    // Confirmation & logout component
    logout_next_step_text: 'Reminder',
    confirmation_steps_content_line_first:
      "1. If you have not registered for work with WorkForce West Virginia's Employment Services, register at: <wv_link>https://macc.workforcewv.org/.</wv_link>  If you need assistance registering for work, contact WorkForce West Virginia at 1-800-252-JOBS (1-800-252-5627) or visit your local office.",
    confirmation_steps_content_line_second: ' ',
    // usps-description component
    redirect_email:
      'https://workforcewv.org/about-workforce-wv/local-office-locations/',
    usps_state_title: ' ',
    usps_state_number:
      'If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS{br} (1-800-252-5627);{br}Monday to Friday from 8:00am to 4:00pm{br}',
    usps_state_timing_para: 'CLOSED on State Holidays',
    usps_state_email:
      'https://workforcewv.org/about-workforce-wv/local-office-locations/ {br}{br}',
    moreinfo_link1:
      '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>{br}',
    href_link1:
      'https://workforcewv.org/about-workforce-wv/local-office-locations/',
    href_link2: 'https://workforcewv.org/unemployment-insurance-benefits/',
    href_link3:
      'https://workforcewv.org/unemployment-insurance-benefits/unemployment-resources/',
    uicp_identity_footer_text:
      'The method you choose to verify your identity will not affect your ability to receive unemployment benefits. State staff will process your application following all applicable laws and regulations.',
    uicp_state_title:
      'If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS (1-800-252-5627);{br}',
    uicp_state_number: 'Monday to Friday from 8:00am to 4:00pm{br}',
    uicp_state_timing_para:
      'CLOSED on State Holidays{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br} <b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
    uicp_state_email: ' ',
    unemployment_question1: ' ',
    unemployment_question2: ' ',
    online_radiobutton_doc_list_text_second: ' ',
    confirmation_state_title: 'WorkForce West Virginia',
    confirmation_next_step_text: 'Reminder',
    error_invalid_login_para3: ' ',
    out_of_network_para2:
      '{br}If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS (1-800-252-5627);{br}Monday to Friday from 8:00am to 4:00pm{br}CLOSED on State Holidays{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
    hitawall_para2:
      '{br}Please try again later or choose a different verification method.{br}Thank you for your patience and understanding.',
    hitawall_para3: ' ',
    error_500_para2:
      'Please try again later. {br}Thank you for your patience and understanding.',
    error_500_para3:
      '{br}If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS (1-800-252-5627);{br}Monday to Friday from 8:00am to 4:00pm{br}CLOSED on State Holidays{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
    login_gov_help_txt: ' ',
    login_link: ' ',
    more_info_text1: ' ',
    more_info_text2: 'More Information',
    moreinfo_link1_500:
      '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>',
    moreinfo_link2_500: ' ',
    error404_para2:
      'If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS (1-800-252-5627);{br}Monday to Friday from 8:00am to 4:00pm{br}CLOSED on State Holidays{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
    more_info_text_invalid: 'More Information',
    moreinfo_link1_invalid:
      '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>',
    moreinfo_link2_invalid: ' ',
    moreinfo_link3_invalid: ' ',
    error_invalid_login_para4: ' ',
    error_invalid_login_para5: ' ',
    error_invalid_login: ' ',
    error_invalid_login_moreinfo_link2:
      '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>',
    error_invalid_login_para6:
      'If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS (1-800-252-5627);{br}Monday to Friday from 8:00am to 4:00pm{br}CLOSED on State Holidays{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
    more_info_text_404: 'More Information',
    invalid_url_para3:
      'If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS (1-800-252-5627);{br}Monday to Friday from 8:00am to 4:00pm{br}CLOSED on State Holidays{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
    online_radiobutton_doc_list_text_first: '<li>State government ID</li>',
    hitawall_para4:
      '{br}If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS (1-800-252-5627);{br}Monday to Friday from 8:00am to 4:00pm{br}CLOSED on State Holidays{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
    hitawall_para5:
      '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>',
    hitawall_more_info_text: 'More Information',
    invalid_url_para4: ' ',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: '{br}More Information',
    logout_intro: ' You may close this window.',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with WorkForce West Virginia.',
    fraud_modal_para2:
      '<b>If you did not file for unemployment</b> with WorkForce West Virginia, <b>please file a report</b> of potential unemployment identity fraud with WorkForce West Virginia by following the steps at <fraud_link>www.dol.gov/fraud.</fraud_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity for your unemployment claim </b>with WorkForce West Virginia through USPS.',
    hitawall_para6: ' ',
    hitawall_para7: ' ',
    moreinfo_confirmation_link1:
      '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>{br}{br}',
    moreinfo_logout_link:
      '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>{br}{br}',
    out_of_network_para4:
      '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>{br}{br}',
    out_of_network_more_info_text: 'More Information',
    es: {
      // Confirmation & logout component
      confirmation_next_step_text: 'Recordatorio',
      logout_intro: 'Puede cerrar esta ventana.',
      uicp_more_info_text: 'Más información',
      logout_next_step_text: 'Recordatorio',
      confirmation_steps_content_line_first:
        '1. Si no te has registrado para trabajar con los Servicios de Empleo de WorkForce West Virginia, regístrate en: <wv_link>https://macc.workforcewv.org/.</wv_link>   Si necesitas ayuda para registrarte para trabajar, comunícate con WorkForce West Virginia al 1-800-252-JOBS (1-800-252-5627) o visita tu oficina local.',
      confirmation_steps_content_line_second: ' ',
      email_subError:
        'Ingrese su dirección de correo electrónico en formato: sunombre@ejemplo.com',
      error_invalid_login_para3: ' ',
      error_invalid_login_para4: ' ',
      error_invalid_login_para5: ' ',
      error_invalid_login_para6:
        'Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627);{br}De lunes a viernes de 8:00am to 5:00pm.{br} Cerramos los días festivos estatales.{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br}',
      error_invalid_login_moreinfo_link1: ' ',
      more_info_text2: 'Más información',
      hitawall_login_subheading:
        'La verificación de su identidad con Login.gov no está disponible actualmente.',
      // Fraud Modal
      fraud_modal_para1:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con WorkForce West Virginia.',
      fraud_modal_para2:
        '<b>Si no solicitó desempleo</b> con el WorkForce West Virginia, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el WorkForce West Virginia siguiendo los pasos en <fraud_link>www.dol.gov/fraud.</fraud_link>',
      fraud_modal_para1_usps:
        'Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con WorkForce West Virginia a través de USPS.',
      // UICP page
      unemployment_question1: ' ',
      unemployment_question2: ' ',
      online_radiobutton_label_text: 'En línea - Login.gov',
      online_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      online_radiobutton_doc_list_text_first:
        '<li>Documento de identidad estadual</li>',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      uicp_identity_footer_text:
        'El método escogido para verificar su identidad no afectará sus posibilidades de recibir beneficios de desempleo. El personal estatal procesará su solicitud siguiendo todas las leyes y reglamentos aplicables.',
      uicp_state_title:
        'Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627);{br}De lunes a viernes de 8:00am to 5:00pm.{br}',
      uicp_state_number: ' ',
      uicp_state_email: ' ',
      uicp_state_timing_para:
        'Cerramos los días festivos estatales.{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br} <b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
      // App level components texts
      // AddressSearch Component
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      input_zipcode: 'Código Postal',
      // Enrollment component
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      // usps-description component
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      postOffice_text: 'Post Office<sup>TM</sup> ',
      // usps_state_title:
      //   'Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con WorkForce West Virginia al 1-800-252-JOBS (1-800-252-5627); de lunes a viernes de 8:00am to 5:00pm.',
      usps_state_number:
        'Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627);{br}De lunes a viernes de 8:00am to 5:00pm. {br}',
      usps_state_timing_para: 'Cerramos los días festivos estatales.',
      usps_state_email:
        '<u>https://workforcewv.org/about-workforce-wv/local-office-locations/</u>',
      redirect_email:
        'https://workforcewv.org/about-workforce-wv/local-office-locations/',
      // error component
      // Error page(common key)
      login_gov_help_txt: 'Para obtener ayuda con Login.gov:',
      login_link: 'https://www.login.gov/contact/',
      // Invalid url
      invalid_url_para3:
        'Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627);{br}De lunes a viernes de 8:00am to 5:00pm.{br} Cerramos los días festivos estatales.{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br}<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
      invalid_url_para4: ' ',
      // more_info_text_404: 'Más información',
      // moreinfo_link1:
      //   '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>',
      more_info_text_invalid: 'Más información',
      moreinfo_link1_invalid:
        '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>',
      moreinfo_link2_invalid: ' ',
      // Hitawal component
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde o elija un método de verificación diferente.{br}Gracias por su paciencia y comprensión.',
      hitawall_para4:
        '{br}Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627);{br}De lunes a viernes de 8:00am to 5:00pm.{br} Cerramos los días festivos estatales.{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br}<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
      hitawall_para3: ' ',
      hitawall_more_info_text: 'Más información',
      hitawall_para5:
        '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>',
      // Out of network(No post office found)
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_no_participate_para1:
        'No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_no_participate_para2: ' ',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para2:
        'Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627);{br}De lunes a viernes de 8:00am to 5:00pm.{br} Cerramos los días festivos estatales.{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br}<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
      out_of_network_para3: ' ',
      out_of_network_more_info_text: 'Más información',
      // Error-500
      error_500_more_info_text1: ' ',
      error_500_more_info_text2: 'Más información',
      error_500_title: 'Tenemos problemas para conectar',
      error_500_heading:
        'La verificación de su identidad no está disponible actualmente.',
      error_500_para1:
        '{br}Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. ',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para3:
        'Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627);{br}De lunes a viernes de 8:00am to 5:00pm.{br} Cerramos los días festivos estatales.{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}',
      // Page not found(404)
      error404_para1:
        '{br}Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de ',
      choice_page_link: 'opciones de verificación de identificación.',
      error404_para2:
        'Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627);{br}De lunes a viernes de 8:00am to 5:00pm.{br} Cerramos los días festivos estatales.{br}<wv_link1>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link1>{br}{br}',
      more_info_text_404: 'Más información',
      moreinfo_link1:
        '<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link3>Information on unemployment in West Virginia</wv_link3></li><li><wv_link4>Your unemployment claimant handbook</wv_link4></li>',
      // moreinfo_link1: ' ',
      moreinfo_link2: ' ',
      moreinfo_link3: ' ',
    },
  },
  WI: {
    name: 'Wisconsin',
    stateCode: 'WI',
    chooseDifferentVerificationButton_USPS: true,
    showVerificationButton: true,
    showErrorPageButton_HitaWall: true,
    showLanguageDropdown: true,
    showFAQButton: 'true',
    showFAQLogoutButton: 'true',
    unemployment_question1:
      '{br}<b>Wisconsin Unemployment Insurance</b> Division staff are available to assist you with questions about unemployment benefits: Call (414) 435-7069 or toll-free (844) 910-3661.{br}{br}',
    unemployment_question2:
      'Help Center Business Hours: {br}<wi_link_service>https://dwd.wisconsin.gov/uiben/services.htm</wi_link_service>{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
    confirmation_checkmark: ' We shared your identity verification with',
    confirmation_button_text: 'Go to Wisconsin UI Claimant Portal',
    faq_link: ' https://my.unemployment.wisconsin.gov/',
    confirmation_steps_nextStep_line_first:
      'For updates on ID verification results and further information about your claim, please visit the Wisconsin Unemployment Insurance (UI) Claimant Portal.{br}',
    confirmation_steps_nextStep_line_second:
      'Please note that after verifying your ID, results in the claimant portal might take 1-4 hours to be updated.{br}{br}',
    uicp_more_info_text: ' ',
    confirmation_content_info:
      '<b>Wisconsin Unemployment Insurance Division</b> staff are available to assist you with questions about unemployment benefits: {br}Call (414) 435-7069 or toll-free (844) 910-3661.{br}{br}Help Center Business Hours: {br}<wi_link_service>https://dwd.wisconsin.gov/uiben/services.htm</wi_link_service>{br}{br}',
    moreinfo_confirmation_link1: ' ',
    logout_intro: 'You may close this window.',
    confirmation_next_step_text: 'Next Step',
    logout_next_step_text: 'Next Step',
    moreinfo_logout_link: '{br}',
    error_invalid_login_para3:
      '{br}<b>Wisconsin Unemployment Insurance</b> Division staff are available to assist you with questions about unemployment benefits: Call (414) 435-7069 or toll-free (844) 910-3661.{br}',
    error_invalid_login_para4:
      'Help Center Business Hours: {br}<wi_link_service>https://dwd.wisconsin.gov/uiben/services.htm</wi_link_service>{br}',
    usps_state_timing_para:
      '{br}<b>Wisconsin Unemployment Insurance</b> Division staff are available to assist you with questions about unemployment benefits: {br}Call (414) 435-7069 or toll-free (844) 910-3661.{br}{br}Help Center Business Hours: {br}<wi_link_service>https://dwd.wisconsin.gov/uiben/services.htm</wi_link_service>{br}{br}',
    postOffice_text: 'The Post Office',
    out_of_network_para2:
      '{br}<b>Wisconsin Unemployment Insurance</b> Division staff are available to assist you with questions about unemployment benefits: Call (414) 435-7069 or toll-free (844) 910-3661.{br}{br}Help Center Business Hours:{br}<wi_link_service>https://dwd.wisconsin.gov/uiben/services.htm</wi_link_service>{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
    out_of_network_para4: ' ',
    hitawall_para2:
      '{br}Please try again later or choose a different verification method.{br}Thank you for your patience and understanding.',
    hitawall_para4:
      '{br}<b>Wisconsin Unemployment Insurance</b> Division staff are available to assist you with questions about unemployment benefits: Call (414) 435-7069 or toll-free (844) 910-3661.{br}{br}Help Center Business Hours:{br}<wi_link_service>https://dwd.wisconsin.gov/uiben/services.htm</wi_link_service>{br}{br}<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
    hitawall_para6: ' ',
    error404_para2:
      '{br}<b>Wisconsin Unemployment Insurance</b> Division staff are available to assist you with questions about unemployment benefits: Call (414) 435-7069 or toll-free (844) 910-3661.{br}{br}Help Center Business Hours:{br}<wi_link_service>https://dwd.wisconsin.gov/uiben/services.htm</wi_link_service>{br}{br}',
    error_500_para2:
      'Please try again later {br}Thank you for your patience and understanding.',
    error_500_more_info_text1: ' ',
    error_500_more_info_text2: ' ',
    error_500_para3:
      '{br}<b>Wisconsin Unemployment Insurance</b> Division staff are available to assist you with questions about unemployment benefits: {br}Call (414) 435-7069 or toll-free (844) 910-3661.{br}{br}Help Center Business Hours: {br}<wi_link_service>https://dwd.wisconsin.gov/uiben/services.htm</wi_link_service>{br}{br}',
    invalid_url_para3:
      '<b>Wisconsin Unemployment Insurance</b> Division staff are available to assist you with questions about unemployment benefits: Call (414) 435-7069 or toll-free (844) 910-3661.{br}{br}Help Center Business Hours:{br}<wi_link_service>https://dwd.wisconsin.gov/uiben/services.htm</wi_link_service>',
    invalid_url_para4:
      '<b>For help with Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
    fraud_modal_para1:
      'You’re about to <b>confirm your identity </b>to access the Wisconsin Unemployment Insurance Claimant Portal.',
    fraud_modal_para2:
      '<b>If you did not attempt to access</b> the Wisconsin Unemployment Insurance Claimant Portal, <b>please file a report</b> of potential unemployment identity fraud with Wisconsin Unemployment Insurance by following the steps at <fraud_wi_link>https://dwd.wisconsin.gov/ui/fraud/report.htm.</fraud_wi_link>',
    fraud_modal_para1_usps:
      'You’re about to <b>confirm your identity</b> to access the Wisconsin Unemployment Insurance Claimant Portal through USPS.',
    confirmation_state_title: 'Wisconsin Unemployment Insurance',
    es: {
      // Confirmation & logout component
      confirmation_checkmark: ' Compartimos su verificación de identidad con',
      confirmation_state_title: 'Seguro de Desempleo de Wisconsin',
      confirmation_next_step_text: 'Siguiente paso',
      confirmation_steps_content_line_first: ' ',
      confirmation_steps_content_line_second: ' ',
      confirmation_steps_nextStep_line_first:
        'Para obtener actualizaciones sobre los resultados de la verificación de identificación y más información sobre su reclamo, visite el Portal de reclamantes del Seguro de Desempleo (UI) de Wisconsin.{br}',
      confirmation_steps_nextStep_line_second:
        'Tenga en cuenta que después de verificar su identificación, los resultados en el portal del reclamante pueden tardar entre 1 y 4 horas en actualizarse.{br}{br}',
      confirmation_button_text:
        'Vaya al portal del solicitante del UI de Wisconsin',
      confirmation_content_info:
        'El personal de la <b>División del Seguro de Desempleo de Wisconsin</b> está disponible para ayudarlo con preguntas sobre los beneficios de desempleo: Llame (414) 435-7069 o al número gratis (844) 910-3661.{br}{br}Horas de Oficina del Centro de Ayuda: {br}<wi_link>https://dwd.wisconsin.gov/uiben/servicess.htm</wi_link>{br}{br}',
      logout_intro: 'Puede cerrar esta ventana.',
      uicp_more_info_text: ' ',
      moreinfo_confirmation_link1: ' ',
      logout_next_step_text: 'Siguiente paso',
      moreinfo_logout_link: '{br}{br}',
      email_subError:
        'Ingrese su dirección de correo electrónico en formato: sunombre@ejemplo.com',
      error_invalid_login_para3:
        'El personal de la División del <b>Seguro de Desempleo de Wisconsin</b> está disponible para ayudarlo con preguntas sobre los beneficios de desempleo: Llame (414) 435-7069 o al número gratis (844) 910-3661.',
      error_invalid_login_para4: ' ',
      error_invalid_login_para5: ' ',
      error_invalid_login_para6:
        'Horas de Oficina del Centro de Ayuda:{br}<wi_link>https://dwd.wisconsin.gov/uiben/servicess.htm</wi_link>{br}',
      error_invalid_login_moreinfo_link1: ' ',
      more_info_text1: ' ',
      more_info_text2: ' ',
      error_invalid_login_moreinfo_link2: ' ',
      hitawall_login_subheading:
        'La verificación de su identidad con Login.gov no está disponible actualmente.',
      // Fraud Modal
      fraud_modal_header:
        'Bienvenidos a la Página de Verificación de Identidad del Departamento de Labor de los Estados Unidos!',
      fraud_modal_para1:
        'Estas a punto de <b>confirmar su identidad</b> para acceder el Portal del Reclamante del Seguro de Desempleo de Wisconsin.',
      fraud_modal_para2:
        '<b>Si no atentó a acceder</b> el Portal del Reclamante del Seguro de Desempleo de Wisconsin,<b> por favor presente un informe</b> de posible fraude de identidad de desempleo con el Seguro de Desempleo de Wisconsin, siguiendo los pasos en  <fraud_wi_link>https://dwd.wisconsin.gov/ui/fraud/report.htm</fraud_wi_link>.',
      fraud_modal_para1_usps:
        'Estas a punto de <b>confirmar su identidad</b> para acceder el Portal del Reclamante del Seguro de Desempleo de Wisconsin a través del Servicio Postal de los Estados Unidos (USPS).',
      // UICP page
      unemployment_question1: ' ',
      unemployment_question2: ' ',
      online_radiobutton_label_text: 'En línea - Login.gov',
      online_radiobutton_doc_list_title:
        '<b>Documentos de identidad aceptados</b>',
      online_radiobutton_doc_list_text_first:
        '<li>Documento de identidad estadual</li>',
      online_radiobutton_doc_list_text_second: ' ',
      usps_radiobutton_des_text:
        'Lleve sus documentos de identidad a una Post Office<sup>TM</sup> que ofrezca servicios de verificación.',
      uicp_identity_footer_text:
        'El método escogido para verificar su identidad no afectará sus posibilidades de recibir beneficios de desempleo. El personal estatal procesará su solicitud siguiendo todas las leyes y reglamentos aplicables.',
      uicp_state_title:
        '{br}El personal de la División del <b>Seguro de Desempleo de Wisconsin</b> está disponible para ayudarlo con preguntas sobre los beneficios de desempleo: Llame (414) 435-7069 o al número gratis (844) 910-3661.{br}',
      uicp_state_number: '{br}Horas de Oficina del Centro de Ayuda:{br}',
      uicp_state_email: ' ',
      uicp_state_timing_para:
        '<wi_link>https://dwd.wisconsin.gov/uiben/servicess.htm</wi_link>{br}{br} <b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
      // App level components texts
      // AddressSearch Component
      different_verification_method_link:
        'Seleccione un método de verificación diferente',
      input_zipcode: 'Código Postal',
      // Enrollment component
      post_office_visit_text:
        'Confirmación de visita a Post Office<sup>TM</sup>',
      // usps-description component
      required_docs_heading:
        'Utilizamos la dirección declarada en su identificación para verificar su identidad.',
      required_Identity_documents: 'Documentos de Identificación aceptables',
      acceptable_forms_of_ID: 'Formas aceptables de identificación:',
      postOffice_text: 'Post Office<sup>TM</sup> ',
      usps_state_number:
        'El personal de la División del <b>Seguro de Desempleo de Wisconsin</b> está disponible para ayudarlo con preguntas sobre los beneficios de desempleo: Llame (414) 435-7069 o al número gratis (844) 910-3661.{br}',
      usps_state_timing_para: '{br}Horas de Oficina del Centro de Ayuda:',
      usps_state_email: '<u>https://dwd.wisconsin.gov/uiben/servicess.htm</u>',
      redirect_email: 'https://dwd.wisconsin.gov/uiben/servicess.htm',
      // error component
      // Error page(common key)
      login_gov_help_txt: 'Para obtener ayuda con Login.gov:',
      login_link: 'https://www.login.gov/contact/',
      // Invalid url
      invalid_url_para3:
        'El personal de la División del <b>Seguro de Desempleo de Wisconsin</b> está disponible para ayudarlo con preguntas sobre los beneficios de desempleo: Llame (414) 435-7069 o al número gratis (844) 910-3661.{br}{br}Horas de Oficina del Centro de Ayuda:{br}<wi_link>https://dwd.wisconsin.gov/uiben/servicess.htm</wi_link>',
      invalid_url_para4:
        '<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
      more_info_text_invalid: ' ',
      moreinfo_link1_invalid: ' ',
      moreinfo_link2_invalid: ' ',
      // Hitawal component
      hitawall_para1:
        'Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.{br}',
      hitawall_para2:
        'Por favor, vuelva a intentarlo más tarde o elija un método de verificación diferente.{br}Gracias por su paciencia y comprensión.',
      hitawall_para4:
        'El personal de la División del <b>Seguro de Desempleo de Wisconsin</b> está disponible para ayudarlo con preguntas sobre los beneficios de desempleo: Llame (414) 435-7069 o al número gratis (844) 910-3661.{br}{br}Horas de Oficina del Centro de Ayuda:{br}<wi_link>https://dwd.wisconsin.gov/uiben/servicess.htm</wi_link>{br}{br}<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>',
      hitawall_para3: ' ',
      hitawall_more_info_text: ' ',
      hitawall_para5: ' ',
      hitawall_para6: ' ',
      // Out of network(No post office found)
      out_of_network_heading: 'No se encontraron Post Offices participantes',
      out_of_network_no_participate_para1:
        'No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.',
      out_of_network_para_text1:
        '{br}Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.{br}{br}',
      out_of_network_no_participate_para2: ' ',
      out_of_network_para1:
        '{br}Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.',
      out_of_network_para2:
        'El personal de la División del <b>Seguro de Desempleo de Wisconsin</b> está disponible para ayudarlo con preguntas sobre los beneficios de desempleo: Llame (414) 435-7069 o al número gratis (844) 910-3661.{br}{br}Horas de Oficina del Centro de Ayuda:{br}<wi_link>https://dwd.wisconsin.gov/uiben/servicess.htm</wi_link>{br}{br}<b>Para obtener ayuda con Login.gov:</b>{br}<login_link>https://www.login.gov/contact/</login_link>{br}{br}',
      out_of_network_para3: ' ',
      out_of_network_para4: ' ',
      out_of_network_more_info_text: ' ',
      // Error-500
      error_500_more_info_text1: ' ',
      error_500_more_info_text2: ' ',
      error_500_title: 'Tenemos problemas para conectar',
      error_500_heading:
        'La verificación de su identidad no está disponible actualmente.',
      error_500_para1:
        '{br}Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. ',
      error_500_para2:
        'Por favor, vuelva a intentarlo más tarde.{br}Gracias por su paciencia y comprensión.',
      error_500_para3:
        'El personal de la División del <b>Seguro de Desempleo de Wisconsin</b> está disponible para ayudarlo con preguntas sobre los beneficios de desempleo: Llame (414) 435-7069 o al número gratis (844) 910-3661.{br}{br}Horas de Oficina del Centro de Ayuda:{br}<wi_link>https://dwd.wisconsin.gov/uiben/servicess.htm</wi_link>{br}{br}',
      // Page not found(404)
      error404_para1:
        '{br}Parece que la URL que ingresó no es válida o ya no existe. Verifique su URL o regrese a la página de ',
      choice_page_link: 'opciones de verificación de identificación.',
      error404_para2:
        'El personal de la División del <b>Seguro de Desempleo de Wisconsin</b> está disponible para ayudarlo con preguntas sobre los beneficios de desempleo: Llame (414) 435-7069 o al número gratis (844) 910-3661.{br}{br}Horas de Oficina del Centro de Ayuda:{br}<wi_link>https://dwd.wisconsin.gov/uiben/servicess.htm</wi_link>{br}{br}',
      more_info_text_404: ' ',
      moreinfo_link1: ' ',
      moreinfo_link2: ' ',
      moreinfo_link3: ' ',
    },
  },
  WY: {
    name: 'Wyoming',
    stateCode: 'WY',
  },
};
export const richText = {
  b: (chunks) => <b>{chunks}</b>,
  u: (chunks) => <u>{chunks}</u>,
  hi_link: (chunks) => (
    <a href="https://www.hirenethawaii.com/vosnet/default.aspx">{chunks}</a>
  ),
  hi_link1: (chunks) => (
    <a href="https://labor.hawaii.gov/ui/contact">{chunks}</a>
  ),
  hi_link2: (chunks) => (
    <a href="https://labor.hawaii.gov/ui/appointments/">{chunks}</a>
  ),
  hi_link3: (chunks) => <a href="https://labor.hawaii.gov/ui/">{chunks}</a>,
  hi_link4: (chunks) => (
    <a href="https://labor.hawaii.gov/ui/ui-forms/unemployment-insurance-handbook-printable-pdf/">
      {chunks}
    </a>
  ),
  hi_link5: (chunks) => <a href="mailto:dlir.oahu.ui@hawaii.gov">{chunks}</a>,
  login_link: (chunks) => <a href="https://www.login.gov/contact">{chunks}</a>,
  ut_link: (chunks) => <a href="https://jobs.utah.gov/ui/home/">{chunks}</a>,
  co_link: (chunks) => (
    <a href="https://cdle.colorado.gov/unemployment/contact-us">{chunks}</a>
  ),
  co_link1: (chunks) => (
    <a href="https://www.connectingcolorado.com/">{chunks}</a>
  ),
  co_link2: (chunks) => <a href="https://www.coloradoui.gov">{chunks}</a>,
  co_link3: (chunks) => (
    <a href="https://cdle.colorado.gov/unemployment">{chunks}</a>
  ),
  ar_link: (chunks) => <a href="https://arjoblink.arkansas.gov">{chunks}</a>,
  ar_link1: (chunks) => (
    <a href="mailto:ADWS.UI.Hotline@arkansas.gov">{chunks}</a>
  ),
  ar_link2: (chunks) => (
    <a href="https://dws.arkansas.gov/workforce-services/contact/">{chunks}</a>
  ),
  ar_link3: (chunks) => (
    <a href="https://dws.arkansas.gov/workforce-services/unemployment/arkansas-claim-help/">
      {chunks}
    </a>
  ),
  ar_link4: (chunks) => (
    <a href="https://dws.arkansas.gov/wp-content/uploads/Newest_Division_update_500_BOOKLET_8X11_ART_v07-2-2019.pdf">
      {chunks}
    </a>
  ),
  nc_link: (chunks) => <a href="https://www.des.nc.gov">{chunks}</a>,
  br: <br />,
  sup: (chunks) => <sup>{chunks}</sup>,
  li: (chunks) => <li>{chunks}</li>,
  h3: (chunks) => <h3>{chunks}</h3>,
  or_link: (chunks) => (
    <a href="https://unemployment.oregon.gov/contact">{chunks}</a>
  ),
  ak_link: (chunks) => (
    <a href="https://labor.alaska.gov/unemployment/call-centers.htm">
      {chunks}
    </a>
  ),
  ak_link1: (chunks) => <a href="https://alaskajobs.alaska.gov">{chunks}</a>,
  ak_link2: (chunks) => (
    <a href="https://alaskajobs.alaska.gov/vosnet/ContactUs.aspx?tab=0">
      {chunks}
    </a>
  ),
  ak_link3: (chunks) => (
    <a href="https://labor.alaska.gov/unemployment/call-centers.htm">
      {chunks}
    </a>
  ),
  ak_link4: (chunks) => (
    <a href="https://labor.alaska.gov/unemployment/home.htm">{chunks}</a>
  ),
  ak_link5: (chunks) => (
    <a href="https://labor.alaska.gov/unemployment/ui-handbook.html">
      {chunks}
    </a>
  ),
  oh_link: (chunks) => <a href="https://unemployment.ohio.gov">{chunks}</a>,
  oh_link1: (chunks) => (
    <a href="https://jfs.ohio.gov/job-services-and-unemployment/unemployment/overview">
      {chunks}
    </a>
  ),
  oh_link2: (chunks) => (
    <a href="https://jfs.ohio.gov/wps/portal/gov/jfs/job-services-and-unemployment/unemployment/for-unemployed-workers/resources/01-workers-guide-to-unemployment-insurance">
      {chunks}
    </a>
  ),
  fraud_link: (chunks) => (
    <a href="https://www.dol.gov/agencies/eta/UIIDtheft">{chunks}</a>
  ),
  ma_link: (chunks) => (
    <a href="https://www.hirenethawaii.com/vosnet/default.aspx">{chunks}</a>
  ),
  ma_link1: (chunks) => (
    <a href="https://uionline.detma.org/Claimant/Core/Login.ASPX">{chunks}</a>
  ),
  ma_link2: (chunks) => (
    <a href="https://www.mass.gov/orgs/department-of-unemployment-assistance">
      {chunks}
    </a>
  ),
  sc_link: (chunks) => <a href="https://dew.sc.gov/">{chunks}</a>,
  wv_link: (chunks) => <a href="https://macc.workforcewv.org/">{chunks}</a>,
  wv_link1: (chunks) => (
    <a href="https://workforcewv.org/about-workforce-wv/local-office-locations/">
      {chunks}
    </a>
  ),
  wv_link3: (chunks) => (
    <a href="https://workforcewv.org/unemployment-insurance-benefits/">
      {chunks}
    </a>
  ),
  wv_link4: (chunks) => (
    <a href="https://workforcewv.org/unemployment-insurance-benefits/unemployment-resources/">
      {chunks}
    </a>
  ),
  ks_link: (chunks) => (
    <a href="https://www.getkansasbenefits.gov/">{chunks}</a>
  ),
  ks_link1: (chunks) => <a href="https://www.dol.ks.gov/">{chunks}</a>,
  nbsp: <>&nbsp;</>,
  nh_link: (chunks) => <a href="https://www.nhes.nh.gov/">{chunks}</a>,
  nh_link1: (chunks) => (
    <a href="https://nhworksjobmatch.nhes.nh.gov/vosnet/default.aspx">
      {chunks}
    </a>
  ),
  nh_link2: (chunks) => (
    <a href="https://www.nhes.nh.gov/locations/index.htm">{chunks}</a>
  ),
  nh_link3: (chunks) => (
    <a href="https://www.nhes.nh.gov/forms/documents/uc-r-and-o.pdf">
      {chunks}
    </a>
  ),
  tx_link: (chunks) => <a href="https://www.twc.texas.gov/">{chunks}</a>,
  in_link: (chunks) => <a href="https://www.unemployment.in.gov/">{chunks}</a>,
  in_link1: (chunks) => <a href="https://dwd.in.gov/">{chunks}</a>,
  ne_link: (chunks) => (
    <a href="https://dol.nebraska.gov/UIBenefits">{chunks}</a>
  ),
  ne_link1: (chunks) => <a href="https://NEworks.nebraska.gov">{chunks}</a>,
  ne_link2: (chunks) => (
    <a href="https://www.dol.nebraska.gov/ContactUs">{chunks}</a>
  ),
  fraud_wi_link: (chunks) => (
    <a href="https://dwd.wisconsin.gov/ui/fraud/report.htm">{chunks}</a>
  ),
  wi_link: (chunks) => (
    <a href="https://dwd.wisconsin.gov/uiben/servicess.htm">{chunks}</a>
  ),
  wi_link_service: (chunks) => (
    <a href="https://dwd.wisconsin.gov/uiben/services.htm">{chunks}</a>
  ),
  ga_link: (chunks) => (
    <a href="https://dol.georgia.gov/locations/career-center">{chunks}</a>
  ),
  ga_link1: (chunks) => (
    <a href="https://dol.georgia.gov/contact-us">{chunks}</a>
  ),
  ga_link2: (chunks) => (
    <a href="https://www.dol.state.ga.us/public/uiben/customer/login">
      {chunks}
    </a>
  ),
  ga_link3: (chunks) => (
    <a href="https://dol.georgia.gov/blog-post/2022-08-16/new-ui-claimant-video-and-handbook">
      {chunks}
    </a>
  ),
  nm_link: (chunks) => <a href="https://www.jobs.state.nm.us">{chunks}</a>,
  nm_link1: (chunks) => (
    <a href="https://www.dws.state.nm.us/en-us/Office-Locations">{chunks}</a>
  ),
  nm_link2: (chunks) => (
    <a href="https://www.dws.state.nm.us/en-us/Unemployment">{chunks}</a>
  ),
  ky_link1: (chunks) => (
    <a href="https://kcc.ky.gov/Pages/Locations.aspx">{chunks}</a>
  ),
  ky_link2: (chunks) => (
    <a href="https://kcc.ky.gov/career/Documents/PAM400.pdf">{chunks}</a>
  ),
  me_link: (chunks) => (
    <a href="https://assist.reemployme.maine.gov/cp/landing">{chunks}</a>
  ),
  me_link1: (chunks) => (
    <a href="https://www.maine.gov/unemployment/">{chunks}</a>
  ),
  me_link2: (chunks) => (
    <a href="https://www.maine.gov/unemployment/fraud/index.shtml">{chunks}</a>
  ),
  me_link3: (chunks) => (
    <a href="https://www.mainecareercenter.gov/locations/index.shtml">
      {chunks}
    </a>
  ),
  nv_link: (chunks) => <a href="mailto:INTERNETHELP@detr.nv.gov">{chunks}</a>,
  al_link: (chunks) => <a href="https://adol.alabama.gov/">{chunks}</a>,
  al_link1: (chunks) => (
    <a href="https://adol.alabama.gov/wp-content/uploads/2023/11/uc_brr.pdf">
      {chunks}
    </a>
  ),
  al_link2: (chunks) => (
    <a href="https://alabamaworks.alabama.gov/vosnet/default.aspx">{chunks}</a>
  ),
  al_link3: (chunks) => (
    <a href="https://alabamaworks.alabama.gov/vosnet/ContactUs.aspx?enc=K3xHsfYP7auZKyge9pmxFw==">
      {chunks}
    </a>
  ),
  md_link1: (chunks) => (
    <a href="https://labor.maryland.gov/employment/new-filers.shtml">
      {chunks}
    </a>
  ),
  md_link2: (chunks) => (
    <a href="https://labor.maryland.gov/employment/claimfaq.shtml">{chunks}</a>
  ),
  md_link3: (chunks) => (
    <a href="https://labor.maryland.gov/employment/clmtguide/uiclmtpamphlet.pdf">
      {chunks}
    </a>
  ),
  md_fraud_link: (chunks) => (
    <a href="https://www.labor.md.gov/employment/uistopfraud.shtml">{chunks}</a>
  ),
};
