import React from 'react';
import { FormattedMessage } from 'react-intl';
import { richText } from '../../i18n/states';

export const StateContent = () => {
  return (
    <div
      className="state-content-container"
      data-test-id="state-contact-container"
    >
      <div
        data-test-id="state-content-header-info"
        className="list-style1-extra"
      >
        <div data-test-id="state-content-para-header">
          <FormattedMessage
            id="state_content_para_header"
            values={{ ...richText }}
          />
        </div>

        <div data-test-id="state-content-para-info1">
          <FormattedMessage
            id="state_content_para_info1"
            values={{ ...richText }}
          />
        </div>
        <div data-test-id="state-content-para-info2">
          <FormattedMessage
            id="state_content_para_info2"
            values={{ ...richText }}
          />
        </div>
        <h3
          className="h3-more-info"
          data-test-id="state-content-more-information-header"
        >
          <FormattedMessage
            id="state_more_information_header"
            values={{ ...richText }}
          />
        </h3>

        <ul className="list-style">
          <span
            className="bullet-color"
            data-test-id="state-content-more-information-para"
          >
            <FormattedMessage
              id="state_more_information_para"
              values={{ ...richText }}
            />
          </span>
        </ul>
      </div>
    </div>
  );
};
