import React, { useState, useEffect } from 'react';
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import baseConfig from '../../utils/config';
import Button from '../../common/Button';
import {
  getUrlParams,
  BASE_URL,
  useAnalyticsEventTracker,
  scrollToTop,
  logMessage,
} from '../../utils/utils';
import './UICP.scss';
import states, { richText } from '../../i18n/states';
import FraudPreventionModal from '../fraudPrevention-modal/FraudPreventionModal';
import { StateContent } from '../state-content/StateContent';

const UICP = (props) => {
  const { setLoading } = props;
  const gaEventTracker = useAnalyticsEventTracker('Choice Screen');
  const [radioOption, setRadioOption] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [loginEnabled, setLoginEnabled] = useState(false);
  const [loginAvailable, setLoginAvailable] = useState(false);
  const [uspsEnabled, setUSPSEnabled] = useState(false);
  const [uspsAvailable, setUSPSAvailable] = useState(false);
  const [workforceEnabled, setWorkforceEnabled] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);
  const routeParams = useParams();
  const query = getUrlParams();
  const [searchParams] = useSearchParams();
  const lang_cd = searchParams.get('lang_cd');
  const swa_xid = searchParams.get('swa_xid');
  const { formatMessage } = useIntl();
  const [optionSum, setOptionSum] = useState(0);
  const [showFraudPreventionModal, setShowFraudPreventionModal] =
    useState(false);

  const [indexTab, setIndexTab] = useState(0);

  const href = formatMessage({
    id: 'redirect_email',
  });

  const [clickedOutside, setClickedOutside] = useState(false);

  const intl = useIntl();

  const [biometricEnabled, setBiometricEnabled] = useState({
    enabled: false,
    per_xid: false,
  });

  const biometricEnabledPerSwaXid = sessionStorage.getItem('biometric_enabled');

  useEffect(() => {
    sessionStorage.setItem('state_errorType', null);

    if (sessionStorage.getItem('auth_modal') === 'true') {
      setShowFraudPreventionModal(false);
      setClickedOutside(true);
    } else {
      setShowFraudPreventionModal(true);
    }
    setLoading(true);
    axios
      .get(`${BASE_URL}v1/services/${routeParams.state}`)
      .then((response) => {
        const responseLoginEnabled =
          response.data.identity_providers.login_dot_gov.enabled;
        const responseLoginAvailable =
          response.data.identity_providers.login_dot_gov.available;
        const responseUspsEnabled =
          response.data.identity_providers.usps_ipp.enabled;
        const responseUspsAvailable =
          response.data.identity_providers.usps_ipp.available;
        const responseWorkforceEnabled =
          response.data.identity_providers.local_workforce.enabled;
        sessionStorage.setItem(
          'allow_alternate_address',
          response.data.identity_providers.usps_ipp.allow_alternative_location
        );
        sessionStorage.setItem(
          'show_scanning_image',
          response.data.identity_providers.usps_ipp.image_config
        );
        sessionStorage.setItem(
          'validDuration',
          response.data.identity_providers.usps_ipp.valid_duration
        );
        sessionStorage.setItem('state_usage_code', response.data.state_usage);
        if (
          responseLoginEnabled === false &&
          responseUspsEnabled === false &&
          responseWorkforceEnabled === false
        ) {
          sessionStorage.clear();
          sessionStorage.setItem(
            'state_errorType',
            JSON.stringify({ type: 'Error_500' })
          );
          sessionStorage.setItem('reason', 'service_unavailable');
          gaEventTracker(
            `${routeParams.state}-External sevices are temporarily unavailable`
          );
          navigate(
            `/error/${routeParams.state}/?swa_xid=${swa_xid}&lang_cd=${
              lang_cd || baseConfig.defaultLanguage
            }`,
            {
              state: { type: 'Error_500' },
            }
          );
        } else if ((responseLoginEnabled && responseLoginAvailable) === true) {
          setRadioOption('online');
        } else if ((responseUspsEnabled && responseUspsAvailable) === true) {
          setRadioOption('usps');
        } else {
          setRadioOption('workforce');
        }
        setLoginEnabled(responseLoginEnabled);
        setLoginAvailable(responseLoginAvailable);
        setUSPSEnabled(responseUspsEnabled);
        setUSPSAvailable(responseUspsAvailable);
        setWorkforceEnabled(responseWorkforceEnabled);
        setOptionSum(
          responseLoginEnabled + responseUspsEnabled + responseWorkforceEnabled
        );
        setNextEnabled(
          (responseLoginEnabled && responseLoginAvailable) ||
            (responseUspsEnabled && responseUspsAvailable) ||
            responseWorkforceEnabled
        );
        setBiometricEnabled({
          enabled:
            response.data.identity_providers.login_dot_gov.biometric_comparison
              .enabled || false,
          per_xid:
            response.data.identity_providers.login_dot_gov.biometric_comparison
              .per_xid || false,
        });
        localStorage.removeItem('swa_xid');
        setLoading(false);
      })
      .catch(() => {
        sessionStorage.clear();
        sessionStorage.setItem('auth_modal', 'true');
        sessionStorage.setItem(
          'state_errorType',
          JSON.stringify({ type: 'Error_500' })
        );
        gaEventTracker(
          `${routeParams.state}-Application failed to respond due to internal server error`
        );
        navigate(
          `/error/${routeParams.state}/?swa_xid=${swa_xid}&lang_cd=${
            lang_cd || baseConfig.defaultLanguage
          }`,
          {
            state: { type: 'Error_500' },
          }
        );
        setLoading(false);
      });
  }, []);

  const getOptionArray = () => {
    const options = [];

    if (loginEnabled === true) {
      options.push('online');
    }

    if (uspsEnabled === true) {
      options.push('usps');
    }

    if (workforceEnabled === true) {
      options.push('workforce');
    }

    return options;
  };

  const handleKeyDown = (e) => {
    const options = getOptionArray();

    if (e.key === 'Tab' && clickedOutside) {
      if (options[0] !== 'online') {
        setIndexTab(options.indexOf(radioOption) + 1);
      } else {
        setIndexTab(options.indexOf(radioOption));
      }
    } else {
      setClickedOutside(true);
      setIndexTab(-3);
    }
  };

  const handleDocumentClick = () => {
    setClickedOutside(true);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
      document.addEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const onOptionChange = (e) => {
    const radioOptionValue = e.target.value;

    const options = getOptionArray();

    setRadioOption(radioOptionValue);
    setIndexTab(options.indexOf(radioOption));
  };

  const handleRadioButtonDivClick = (value) => {
    logMessage(`${value} radio-button clicked`);
    setRadioOption(value);
  };

  const handleRedirect = async () => {
    const config = {
      method: 'GET',
      url: `${BASE_URL}v1/logindotgov/authorization/${
        baseConfig.logindotgovIAL
      }?return_url=true&lang-cd=${lang_cd || baseConfig.defaultLanguage}`,
      headers: {
        swa: routeParams.state,
        'swa-xid': query.swa_xid,
      },
    };

    try {
      const response = await axios(config);
      window.location.href = response.data.url;
    } catch (error) {
      sessionStorage.clear();
      sessionStorage.setItem('auth_modal', 'true');

      if (error.response?.status === 409) {
        sessionStorage.setItem(
          'state_errorType',
          JSON.stringify({ type: 'DuplicateSwaXid' })
        );
        gaEventTracker(
          `${routeParams.state}-Duplicate swa-xid, Redirected to DuplicateSwaXid`
        );
        navigate(
          `/error/${
            routeParams.state
          }/?option_sum=${optionSum}&redirect_from=${'login'}&lang_cd=${
            lang_cd || baseConfig.defaultLanguage
          }`,
          { state: { type: 'DuplicateSwaXid' } }
        );
      } else {
        sessionStorage.setItem(
          'state_errorType',
          JSON.stringify({ type: 'HitaWall' })
        );
        gaEventTracker(`${routeParams.state}-Login.gov service unavailable`);
        navigate(
          `/error/${
            routeParams.state
          }/?swa_xid=${swa_xid}&option_sum=${optionSum}&redirect_from=${'login'}&lang_cd=${
            lang_cd || baseConfig.defaultLanguage
          }`,
          { state: { type: 'HitaWall' } }
        );
      }
    }
  };

  const workforceRedirect = () => {
    if (states[routeParams.state]?.workforceRedirect === true) {
      window.open(
        `${intl.formatMessage({ id: 'workforce_direct_navigation' })}`
      );
    } else {
      navigate(`/workforce/${routeParams.state}/${location.search}`);
    }
  };

  const formSubmit = (e) => {
    logMessage('Next-button clicked');
    gaEventTracker(`${routeParams.state}-Next Button Clicked`);
    e.preventDefault();

    switch (radioOption) {
      case 'online':
        handleRedirect();
        break;
      case 'usps':
        navigate(
          `/search/${routeParams.state}/${location.search}&option_sum=${optionSum}&auth_modal=true`
        );
        break;
      default:
        workforceRedirect();
        break;
    }
  };

  const handleKeyPress = (e) => {
    const options = getOptionArray();

    const currentIndex = options.indexOf(radioOption);

    let newIndex = 0;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      newIndex = currentIndex === 0 ? options.length - 1 : currentIndex - 1;
      setRadioOption(options[newIndex]);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      newIndex = currentIndex === options.length - 1 ? 0 : currentIndex + 1;
      setRadioOption(options[newIndex]);
    } else {
      newIndex = currentIndex;
    }

    const radioElement = document.getElementById(options[newIndex]);

    if (radioElement) radioElement.focus();
    setClickedOutside(false);
    setIndexTab(newIndex);
  };

  return (
    <div className="uicp-container" id="mainContent">
      <h2 className="heading" data-test-id="UICP-HEADING">
        <FormattedMessage id="uicp_header" />
      </h2>
      <hr />
      <form className="uicp">
        <div>
          {optionSum > 1 && (
            <h3 className="identity-option-text">
              <FormattedMessage id="identity_text" values={{ ...richText }} />
            </h3>
          )}
          <div className="choice" id="radio-button-group">
            {loginEnabled && loginAvailable && (
              <section className="uicp-container">
                <div
                  className={
                    radioOption === 'online'
                      ? 'online selected-background'
                      : 'online unselected-background'
                  }
                  data-test-id="UICP-LOGIN-OPTION"
                  onClick={() => handleRadioButtonDivClick('online')}
                >
                  <div className="online-input">
                    {optionSum > 1 && (
                      <div>
                        <input
                          value="online"
                          name="online"
                          type="radio"
                          id="online"
                          onChange={onOptionChange}
                          checked={radioOption === 'online'}
                          data-testid="online-radio"
                          onClick={() => {
                            gaEventTracker(
                              `${routeParams.state}-Login.gov radio-button clicked`
                            );
                          }}
                          aria-required="true"
                          tabIndex={indexTab === 0 ? '0' : '-1'}
                          onKeyDown={(e) => handleKeyPress(e)}
                        />
                      </div>
                    )}
                    <label
                      htmlFor="online"
                      id="online-login.gov"
                      data-testid="online-label"
                      aria-label="online-login.gov"
                      data-test-id="login.gov"
                    >
                      <strong>
                        <FormattedMessage id="online_radiobutton_label_text" />
                      </strong>
                    </label>
                  </div>
                  <div className="document-container">
                    {(biometricEnabled.enabled && !biometricEnabled.per_xid) ||
                    (biometricEnabled.enabled &&
                      biometricEnabled.per_xid &&
                      biometricEnabledPerSwaXid) ? (
                      <div>
                        <FormattedMessage id="online_radiobutton_des_main_text" />
                        <br />
                        <FormattedMessage
                          id="online_radiobutton_des_biometric_text"
                          values={{ ...richText }}
                        />
                      </div>
                    ) : (
                      <div>
                        <FormattedMessage id="online_radiobutton_des_text" />
                      </div>
                    )}
                    <div className="document-info">
                      <p className="list-title">
                        {' '}
                        <FormattedMessage
                          id="online_radiobutton_doc_list_title"
                          values={{ ...richText }}
                        />
                      </p>
                      <ul>
                        <div>
                          <FormattedMessage
                            id="online_radiobutton_doc_list_text_first"
                            values={{ ...richText }}
                          />
                        </div>
                        <div>
                          <FormattedMessage
                            id="online_radiobutton_doc_list_text_second"
                            values={{ ...richText }}
                          />
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            )}
            {loginEnabled && !loginAvailable && (
              <section className="uicp-container">
                <div
                  className="disabled-online unselected-background"
                  data-test-id="UICP-LOGIN-OPTION"
                >
                  <div className="disabled-online-input">
                    {optionSum > 1 && (
                      <div>
                        <input
                          value="online"
                          name="online-disabled"
                          type="radio"
                          id="online-disabled"
                          data-testid="online-radio-disabled"
                          disabled={true}
                        />
                      </div>
                    )}
                    <label
                      htmlFor="online"
                      id="online-login.gov"
                      data-testid="online-label"
                      aria-label="online-login.gov"
                      data-test-id="login.gov"
                    >
                      <strong>
                        <FormattedMessage id="online_radiobutton_label_text" />
                      </strong>
                    </label>
                  </div>
                  <div className="disabled-description-container">
                    <div>
                      <FormattedMessage
                        id="online_radiobutton_des_unavailable_text"
                        values={{ ...richText }}
                      />
                      <FormattedMessage
                        id="base_radiobutton_des_unavailable_text_first"
                        values={{ ...richText }}
                      />
                      <FormattedMessage
                        id="base_radiobutton_des_unavailable_text_second"
                        values={{ ...richText }}
                      />
                      <FormattedMessage id="base_radiobutton_des_unavailable_text_third" />
                    </div>
                  </div>
                </div>
              </section>
            )}
            {uspsEnabled && uspsAvailable && (
              <section className="usps-container">
                <div
                  className={
                    radioOption === 'usps'
                      ? 'usps selected-background'
                      : 'usps unselected-background'
                  }
                  data-test-id="UICP-USPS-OPTION"
                  onClick={() => handleRadioButtonDivClick('usps')}
                >
                  <div className="usps-input">
                    {optionSum > 1 && (
                      <input
                        value="usps"
                        type="radio"
                        id="usps"
                        className="radio-option"
                        onChange={onOptionChange}
                        checked={radioOption === 'usps'}
                        data-testid="usps-radio"
                        onClick={() => {
                          gaEventTracker(
                            `${routeParams.state}-USPS radio-button clicked`
                          );
                        }}
                        aria-required="true"
                        tabIndex={indexTab === 1 ? '0' : '-1'}
                        onKeyDown={(e) => handleKeyPress(e)}
                      />
                    )}
                    <label
                      htmlFor="usps"
                      id="In-person-postoffice"
                      data-test-id="usps"
                    >
                      <strong>
                        <FormattedMessage
                          id="usps_radiobutton_label_text"
                          values={{ ...richText }}
                        />
                      </strong>
                    </label>
                  </div>
                  <div className="document-container">
                    <div>
                      <FormattedMessage
                        id="usps_radiobutton_des_text"
                        values={{ ...richText }}
                      />
                    </div>
                    {states[routeParams.state]?.showIdDetailsAtUICP === true ? (
                      <div
                        data-test-id="required-id-details"
                        className="required-id-details"
                      >
                        <FormattedMessage
                          id="required_id_details"
                          values={{ ...richText }}
                        />
                      </div>
                    ) : (
                      <div
                        data-test-id="document-info"
                        className="document-info"
                      >
                        <p className="list-title">
                          <FormattedMessage
                            id="usps_radiobutton_doc_list_title"
                            values={{ ...richText }}
                          />
                        </p>
                        <ul>
                          <li>
                            <FormattedMessage id="usps_radiobutton_doc_list_text_first" />
                          </li>
                          <li>
                            <FormattedMessage id="usps_radiobutton_doc_list_text_second" />
                          </li>
                          <li>
                            <FormattedMessage id="usps_radiobutton_doc_list_text_third" />
                          </li>
                          <li>
                            <FormattedMessage id="usps_radiobutton_doc_list_text_fourth" />
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            )}
            {uspsEnabled && !uspsAvailable && (
              <section className="usps-container">
                <div
                  className="disabled-usps unselected-background"
                  data-test-id="UICP-USPS-OPTION"
                >
                  <div className="disabled-usps-input">
                    {optionSum > 1 && (
                      <div>
                        <input
                          value="usps"
                          name="usps-disabled"
                          type="radio"
                          id="usps-disabled"
                          data-testid="usps-radio-disabled"
                          disabled={true}
                        />
                      </div>
                    )}
                    <label
                      htmlFor="usps"
                      id="In-person-postoffice"
                      data-test-id="usps"
                    >
                      <strong>
                        <FormattedMessage
                          id="usps_radiobutton_label_text"
                          values={{ ...richText }}
                        />
                      </strong>
                    </label>
                  </div>
                  <div className="disabled-description-container">
                    <div>
                      <FormattedMessage
                        id="usps_radiobutton_des_unavailable_text"
                        values={{ ...richText }}
                      />
                      <FormattedMessage
                        id="base_radiobutton_des_unavailable_text_first"
                        values={{ ...richText }}
                      />
                      <FormattedMessage
                        id="base_radiobutton_des_unavailable_text_second"
                        values={{ ...richText }}
                      />
                      <FormattedMessage id="base_radiobutton_des_unavailable_text_third" />
                    </div>
                  </div>
                </div>
              </section>
            )}
            {workforceEnabled && (
              <section className="workforce">
                <div
                  className={
                    radioOption === 'workforce'
                      ? 'workforce selected-background'
                      : 'workforce unselected-background'
                  }
                  onClick={() => handleRadioButtonDivClick('workforce')}
                >
                  <div className="workforce-input">
                    {optionSum > 1 && (
                      <input
                        value="workforce"
                        type="radio"
                        id="workforce"
                        onChange={onOptionChange}
                        checked={radioOption === 'workforce'}
                        data-testid="workforce"
                        onClick={() => {
                          gaEventTracker(
                            `${routeParams.state}-Workforce radio-button clicked`
                          );
                        }}
                        aria-required="true"
                        tabIndex={indexTab === 2 ? '0' : '-1'}
                        onKeyDown={(e) => handleKeyPress(e)}
                      />
                    )}
                    <label
                      htmlFor="workforce"
                      id="In-person-workforce-center"
                      className="workforceLabel"
                    >
                      <strong>
                        <FormattedMessage
                          id="workforce_radiobutton_label_text"
                          values={{ ...richText }}
                        />
                      </strong>
                    </label>
                  </div>
                  <div className="document-container">
                    <div>
                      <FormattedMessage
                        id="workforce_radiobutton_des_text"
                        values={{ ...richText }}
                      />
                    </div>
                    <div className="document-info">
                      <p className="list-title">
                        <FormattedMessage
                          id="workforce_radiobutton_doc_list_title"
                          values={{ ...richText }}
                        />
                      </p>
                      <ul>
                        <div>
                          <FormattedMessage
                            id="workforce_radiobutton_doc_list_text_first"
                            values={{ ...richText }}
                          />
                        </div>
                        <div>
                          <FormattedMessage
                            id="workforce_radiobutton_doc_list_text_second"
                            values={{ ...richText }}
                          />
                        </div>
                        <div>
                          <FormattedMessage
                            id="workforce_radiobutton_doc_list_text_third"
                            values={{ ...richText }}
                          />
                        </div>
                        <div>
                          <FormattedMessage
                            id="workforce_radiobutton_doc_list_text_fourth"
                            values={{ ...richText }}
                          />
                        </div>
                        <div>
                          <FormattedMessage
                            id="workforce_radiobutton_doc_list_text_fifth"
                            values={{ ...richText }}
                          />
                        </div>
                        <div>
                          <FormattedMessage
                            id="workforce_radiobutton_doc_list_text_sixth"
                            values={{ ...richText }}
                          />
                        </div>
                        <div>
                          <FormattedMessage
                            id="workforce_radiobutton_doc_list_text_seven"
                            values={{ ...richText }}
                          />
                        </div>
                        <div>
                          <FormattedMessage
                            id="workforce_radiobutton_doc_list_text_eight"
                            values={{ ...richText }}
                          />
                        </div>
                      </ul>
                      <p className="list-title">
                        <FormattedMessage
                          id="workforce_radiobutton_doc_list_extra_title"
                          values={{ ...richText }}
                        />
                      </p>
                      <ul>
                        <div>
                          <FormattedMessage
                            id="workforce_radiobutton_doc_list_extra_text"
                            values={{ ...richText }}
                          />
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
          <section className="uicp-button">
            <Button
              type="primary"
              disabled={!nextEnabled}
              onClick={formSubmit}
              data-testid="submit-button"
            >
              <FormattedMessage id="uicp_section_next_button_text" />
            </Button>
          </section>
          {!(
            states[routeParams.state]?.showSameStateContent === true &&
            states[routeParams.state]?.uicp_identity_footer_text === ' '
          ) && (
            <section className="identity-footer">
              <div data-test-id="identity_footer_text1">
                <FormattedMessage
                  id="uicp_identity_footer_text"
                  values={{ ...richText }}
                />
                <br />
              </div>
              <div data-test-id="identity_footer_text2">
                <FormattedMessage
                  id="unemployment_question1"
                  values={{ ...richText }}
                />
              </div>
              <div
                className="information-uicp"
                data-test-id="identity_footer_text3"
              >
                <FormattedMessage
                  id="more_information"
                  values={{ ...richText }}
                />
              </div>
              <div data-test-id="identity_footer_text4">
                <FormattedMessage
                  id="unemployment_question2"
                  values={{ ...richText }}
                />
                <FormattedMessage
                  id="department_workforce"
                  values={{ ...richText }}
                />
                <FormattedMessage
                  id="uicp_state_title"
                  values={{ ...richText }}
                />
                <FormattedMessage
                  id="uicp_state_number"
                  values={{ ...richText }}
                />
                <FormattedMessage
                  id="uicp_state_timing_para"
                  values={{ ...richText }}
                />
                <a className="uicp-link" href={href}>
                  <FormattedMessage
                    id={`uicp_state_email`}
                    values={{ ...richText }}
                  />
                </a>
              </div>
            </section>
          )}
          {states[routeParams.state]?.showSameStateContent === true && (
            <section>
              <StateContent />
            </section>
          )}
          <div className="scroll-top-height">
            <a
              className="scroll-top-align"
              onClick={scrollToTop}
              href=""
              data-testid="returnToTop"
            >
              <FormattedMessage
                id="scroll_to_top_text"
                values={{ ...richText }}
              />
            </a>
          </div>
          <br />
        </div>
      </form>
      <FraudPreventionModal
        showFraudPreventionModal={showFraudPreventionModal}
        setShowFraudPreventionModal={setShowFraudPreventionModal}
      />
    </div>
  );
};

export default UICP;
