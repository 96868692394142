import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  updateQueryStringParameter,
  useAnalyticsEventTracker,
  scrollToMainContent,
  logMessage,
} from '../utils/utils';
import baseConfig from '../utils/config';
import LanguageSelector from './LanguageSelector';
import SideMenu from '../components/side-menu/SideMenu';
import LanguageModal from '../components/language-modal/LanguageModal';
import { ReactComponent as LogoutHover } from '../icons/logout-hover.svg';
import { ReactComponent as Logout } from '../icons/logout.svg';
import states from './../i18n/states';
import './Header.scss';

const Header = (props) => {
  const noLanguageSelected = 1;
  const languageList = [
    {
      label: (
        <span data-test-id="language" style={{ alignContent: 'center' }}>
          <FormattedMessage id="language_label_header" />
        </span>
      ),
      value: noLanguageSelected,
      style: { display: 'none' },
    },
    {
      label: (
        <span
          data-test-id="english"
          onClick={() => logMessage('English language clicked')}
        >
          <FormattedMessage id="language_label_english" />
        </span>
      ),
      value: 'en',
      style: { display: 'block' },
    },
    {
      label: (
        <span
          data-test-id="spanish"
          onClick={() => logMessage('Spanish language clicked')}
        >
          <FormattedMessage id="language_label_spanish" />
        </span>
      ),
      value: 'es',
      style: { display: 'block' },
    },
  ];

  const isDesktop = useMediaQuery('(min-width:48.063em)');
  const gaEventTracker = useAnalyticsEventTracker('Header');
  const [banner, setBanner] = useState('none');
  const [language, setLanguage] = useState(1);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const urlPathname = location.pathname.split('/')[1];
  const [hover, setHover] = useState(false);
  const [logoutHover, setLogoutHover] = useState(false);
  const changedLocation = useLocation();
  const [isFocused, setFocus] = useState(false);

  const navigate = useNavigate();

  const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    const cleanedPath = location.pathname.replace(/\/{2,}/g, '/error/');
    const params = new URLSearchParams(location.search);
    params.set('lang_cd', lang);

    const urlString = localStorage.getItem('previousValidPage');
    const urlParams = new URLSearchParams(urlString.split('?')[1]);
    urlParams.set('lang_cd', lang);
    const updatedUrl = `${urlString.split('?')[0]}?${urlParams.toString()}`;
    localStorage.setItem('previousValidPage', updatedUrl);
    navigate(`${cleanedPath}?${params.toString()}`, { replace: true });
  };

  let swa = useParams().state;
  if (!swa) {
    swa = location.pathname.split('/')[2];
  }

  const handleLanguageHover = () => {
    setHover(true);
  };

  const handleLanguageOut = () => {
    setHover(false);
    setFocus(false);
  };

  const handleLogoutHoverIn = () => {
    setLogoutHover(true);
  };

  const handleLogoutHoverOut = () => {
    setLogoutHover(false);
    setFocus(false);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    if (
      window.location.href.includes('/search') &&
      lang !== noLanguageSelected &&
      window.isDirty
    ) {
      setShowLanguageModal(true);
    } else {
      const url = updateQueryStringParameter(
        window.location.href,
        'lang_cd',
        lang
      );
      window.location.href = url;
    }
  };

  useEffect(() => {
    if (showLanguageMenu === true) {
      setShowLanguageMenu(false);
    }
  }, [changedLocation]);

  return (
    <header className="usa-header usa-header--basic">
      <a
        className="usa-skipnav"
        href="javascript:;"
        onClick={scrollToMainContent}
      >
        Skip to main content
      </a>
      {baseConfig.enableTestSiteBanner === 'true' ? (
        <section
          role="banner"
          aria-label="Test website"
          data-testid="testBanner"
        >
          <div className="padding-1 bg-secondary-darker text-white line-height-1 text-center">
            TEST SITE - Do not use real personal information (demo purposes
            only) - TEST SITE
          </div>
        </section>
      ) : (
        <></>
      )}
      <header className="usa-banner__header" data-testid="usaBannerHeader">
        <div className="usa-banner__inner">
          <div className="grid-col-auto">
            <img
              className="usa-banner__header-flag"
              src="static/img/us_flag_small.png"
              alt="U.S. flag"
            />
          </div>
          <div className="grid-col-fill tablet:grid-col-auto">
            <p className="usa-banner__header-text">
              <FormattedMessage id="banner_header_text" />
            </p>
            <p className="usa-banner__header-action" aria-hidden="true">
              <FormattedMessage id="banner_button_text" />
            </p>
          </div>
          <button
            data-testid="bannerControl"
            onClick={() => {
              setBanner(banner === 'block' ? 'none' : 'block');
              gaEventTracker(`${swa}-Clicked on Heres how you know`);
            }}
            className="usa-banner__button"
          >
            <span className="usa-banner__button-text">
              <FormattedMessage id="banner_button_text" />
            </span>
          </button>
        </div>
      </header>
      <div
        className="usa-banner__content usa-accordion__content"
        style={{ display: banner }}
        id="gov-banner"
        data-testid="banner"
      >
        <div className="grid-row grid-gap-lg">
          <div className="usa-banner__guidance tablet:grid-col-6">
            <img
              className="usa-banner__icon usa-media-block__img"
              src="static/img/icon-dot-gov.svg"
              role="img"
              alt=""
              aria-hidden="true"
              data-testid="banner-image"
            />
            <div className="usa-media-block__body">
              <p>
                <strong>
                  <FormattedMessage id="header_media_block" />
                </strong>
                <br />
                <FormattedMessage id="header_media_block_two" />{' '}
                <strong>.gov</strong>{' '}
                <FormattedMessage id="header_media_block_three" />
              </p>
            </div>
          </div>
          <div className="usa-banner__guidance tablet:grid-col-6">
            <img
              className="usa-banner__icon usa-media-block__img"
              src="static/img/icon-https.svg"
              role="img"
              alt=""
              aria-hidden="true"
            />
            <div className="usa-media-block__body">
              <p>
                <strong>
                  <FormattedMessage id="header_media_block_body" />{' '}
                </strong>
                <br />
                <FormattedMessage id="header_media_block_two" />{' '}
                <strong>
                  <FormattedMessage id="header_media_block_one" />
                </strong>
                (
                <span className="icon-lock">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="52"
                    height="64"
                    viewBox="0 0 52 64"
                    className="usa-banner__lock-image"
                    role="img"
                    aria-labelledby="banner-lock-title banner-lock-description"
                    focusable="false"
                  >
                    <title id="banner-lock-title">
                      <FormattedMessage id="banner_lock_title" />
                    </title>
                    <desc id="banner-lock-description">
                      <FormattedMessage id="banner_lock_description_main" />
                    </desc>
                    <path
                      fill="#000000"
                      fillRule="evenodd"
                      d="M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z"
                    />
                  </svg>
                </span>
                )
                <FormattedMessage id="banner_lock_description_one" />{' '}
                <strong>https://</strong>
                <FormattedMessage id="banner_lock_description_two" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <section
        role="banner"
        className="usa-banner dol-banner bg-primary-darker"
      >
        <header className="usa-banner__header" style={{ padding: 0 }}>
          <div
            className="usa-banner__inner"
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '2.625rem',
            }}
          >
            <a
              href="https://www.dol.gov/"
              className="text-no-underline"
              style={{ display: 'flex', width: '50%' }}
            >
              <div className="grid-col-auto">
                <img
                  className="float-left height-205 margin-right-05"
                  src="static/img/Agency_DOL_Logo_dark.svg"
                  alt="United States Department of Labor"
                />
              </div>
              <div className="grid-col-fill tablet:grid-col-auto padding-top-05">
                <p className="usa-banner__header-text usdol-title">
                  <strong className="text-base-lightest TEXT-UPPER">
                    <FormattedMessage id="header_usdol_title" />
                  </strong>
                </p>
              </div>
            </a>
            {isDesktop ? (
              <>
                {(swa in states === false ||
                  !states[swa] ||
                  states[swa]?.showLanguageDropdown) && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '2.625rem',
                      width: '10rem',
                      backgroundColor: hover === true && '#F2F9FF',
                      marginRight: urlPathname === 'identity' && '8rem',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        height: '2.625rem',
                      }}
                    >
                      <LanguageSelector
                        languageList={languageList}
                        handleLanguageChange={handleLanguageChange}
                        handleHover={handleLanguageHover}
                        handleOut={handleLanguageOut}
                        hover={hover}
                        handleBlur={handleBlur}
                        handleFocus={handleFocus}
                        isFocused={isFocused}
                      />
                      {urlPathname === 'identity' && (
                        <div
                          style={{
                            width: '10rem',
                            justifyContent: 'center',
                            backgroundColor: logoutHover === true && '#F2F9FF',
                            border:
                              logoutHover === true
                                ? '0.063rem solid #CEDCED'
                                : '',
                            borderRadius: '0.25rem',
                          }}
                          onMouseEnter={handleLogoutHoverIn}
                          onMouseLeave={handleLogoutHoverOut}
                          onClick={props.handleLogout}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {logoutHover === false ? (
                              <Logout
                                style={{
                                  margin: '0.75rem 0.8rem 0.75rem -1rem',
                                }}
                                aria-label="Logout Icon"
                              />
                            ) : (
                              <LogoutHover
                                style={{
                                  margin: '0.75rem 0.8rem 0.75rem -1rem',
                                }}
                                aria-label="Logout-Hover Icon"
                              />
                            )}
                            <div
                              data-testid="logout-button"
                              style={{
                                color:
                                  logoutHover === true ? '#162E51' : 'white',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                fontSize: '1rem',
                                fontFamily:
                                  'Roboto, Helvetica, Arial, sans-serif',
                              }}
                            >
                              <FormattedMessage id="confirmation_link_item_third" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {(swa in states === false ||
                  !states[swa] ||
                  states[swa]?.showLanguageDropdown) && (
                  <Box
                    sx={{
                      color: 'white',
                      backgroundColor: '#005EA2',
                      padding: '0.7rem',
                      height: '2.625rem',
                    }}
                    onClick={() => setShowLanguageMenu(true)}
                    data-test-id="menu"
                  >
                    {' '}
                    <FormattedMessage id="header_usdol_menu" />{' '}
                  </Box>
                )}
                <SideMenu
                  languageList={languageList}
                  showLanguageMenu={showLanguageMenu}
                  setShowLanguageMenu={setShowLanguageMenu}
                  handleLanguageChange={handleLanguageChange}
                  handleLogout={props.handleLogout}
                  urlPathname={urlPathname}
                />
              </>
            )}
            <LanguageModal
              language={language}
              showLanguageModal={showLanguageModal}
              setShowLanguageModal={setShowLanguageModal}
              setLanguage={setLanguage}
            />
          </div>
        </header>
      </section>
    </header>
  );
};

export default Header;
