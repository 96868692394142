import React from 'react';
import './Logout.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import Button from '../../common/Button';
import { scrollToTop } from '../../utils/utils';
import states, { richText } from '../../i18n/states';
import { StateContent } from '../state-content/StateContent';

const Logout = () => {
  const routeParams = useParams();
  const { state } = routeParams;
  const { formatMessage } = useIntl();
  const faq_link = formatMessage({
    id: 'faq_link',
  });

  const linkRedirect = () => {
    window.location.href = faq_link;
  };

  return (
    <div className="logout" id="mainContent">
      <section className="grid-container header">
        <h1 data-test-id="logout-title" className="logout-title">
          <FormattedMessage id="logout_title" />
        </h1>
      </section>
      <hr className="hr1" />
      <section className="grid-container">
        <div data-test-id="logout-intro" className="side-title">
          <FormattedMessage id="logout_intro" />
        </div>
        <hr className="hr2" />
        <div data-test-id="logout-next-step-text" className="side-title">
          <FormattedMessage
            id="logout_next_step_text"
            values={{ ...richText }}
          />
        </div>
      </section>
      <section className="grid-container section3">
        <div
          data-test-id="logout-confirmation-content"
          className="list-style1-extra"
        >
          <FormattedMessage
            id="confirmation_content_extra"
            values={{ ...richText }}
          />
        </div>
        <ul className="confirmation-style">
          <div data-test-id="logout-confirmation-steps-content-line-first">
            <FormattedMessage
              id="confirmation_steps_content_line_first"
              values={{ ...richText }}
            />
          </div>
          <div data-test-id="logout-confirmation-steps-content-line-second">
            <FormattedMessage
              id="confirmation_steps_content_line_second"
              values={{ ...richText }}
            />
          </div>
        </ul>
        <div>
          <div data-test-id="logout-confirmation-nextStep-line-first">
            <FormattedMessage
              id="confirmation_steps_nextStep_line_first"
              values={{ ...richText }}
            />
          </div>
          <div data-test-id="logout-confirmation-nextStep-line-second">
            <FormattedMessage
              id="confirmation_steps_nextStep_line_second"
              values={{ ...richText }}
            />
          </div>
        </div>
      </section>
      {states[state]?.showFAQLogoutButton === 'true' && (
        <div className="FAQ-button">
          <Button
            data-test-id="login-confirmation-button"
            type="primary"
            onClick={() => linkRedirect()}
          >
            <FormattedMessage id="confirmation_button_text" />
          </Button>
        </div>
      )}
      {states[state]?.showSameStateContent === true && (
        <section className="grid-container">
          <StateContent />
        </section>
      )}
      <section className="grid-container section4">
        <div data-test-id="logout-more-info-text" className="side-title">
          <FormattedMessage id="uicp_more_info_text" />
        </div>
        <div
          data-test-id="logout-confirmation-content-info"
          className="list-style1-extra"
        >
          <FormattedMessage
            id="confirmation_content_info"
            values={{ ...richText }}
          />
        </div>
        <ul className="list-style">
          <span data-test-id="logout-moreinfo-link" className="bullet-color">
            <FormattedMessage
              id="moreinfo_logout_link"
              values={{ ...richText }}
            />
          </span>
        </ul>
      </section>
      <section
        data-test-id="logout-scroll-to-top"
        className="grid-container section5"
      >
        <a onClick={scrollToTop} href="">
          {' '}
          <FormattedMessage
            id="scroll_to_top_text"
            values={{ ...richText }}
          />{' '}
        </a>
      </section>
    </div>
  );
};

export default Logout;
