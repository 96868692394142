import React from 'react';
import { useParams } from 'react-router';
import './usps-description.scss';
import { FormattedMessage } from 'react-intl';
import { Email } from './templates/Email';
import states, { richText } from '../../i18n/states';
import { StateContent } from '../state-content/StateContent';

export const USPSDescription = (stateParams) => {
  const routeParams = useParams();
  const { state } = routeParams;
  return (
    <section className="usps-description-container">
      <section>
        <h3 className="documents-info-label">
          <FormattedMessage id="required_Identity_documents" />
        </h3>
        <div className="documents-info-conainer">
          <div className="documents-info">
            <h3
              className="required-document-headings"
              data-test-id="required-document-headings-1"
            >
              <FormattedMessage id="acceptable_forms_of_ID" />
            </h3>
            <ul className="document-list">
              <li>
                <FormattedMessage id="State_drivers_license" />
              </li>
              <li>
                <FormattedMessage id="state_id_card" />
              </li>
              <li>
                <FormattedMessage id="uniformed_id_cards" />
              </li>
              <li>
                {' '}
                <FormattedMessage id="secondary_id" />
              </li>
            </ul>
          </div>
        </div>
        <div className="documents-info-conainer">
          <div className="documents-info">
            <h3
              className="required-document-headings"
              data-test-id="required-document-headings-2"
            >
              <FormattedMessage
                id="required_docs_heading"
                values={{ ...richText }}
              />
            </h3>{' '}
            <FormattedMessage id="required_docs_para" />
            <ul className="document-list">
              <li>
                <FormattedMessage id="reqired_docs_list_lease" />
              </li>
              <li>
                <FormattedMessage id="reqired_docs_list_voter" />
              </li>
              <li>
                <FormattedMessage id="reqired_docs_list_vehical" />
              </li>
              <div>
                <FormattedMessage
                  id="reqired_docs_list_home"
                  values={{ ...richText }}
                />
              </div>
            </ul>
          </div>
        </div>
        <section className="postoffice-info-conainer">
          <h3
            className="required-document-headings"
            data-test-id="required-document-headings-3"
          >
            <FormattedMessage id="postOffice_text" values={{ ...richText }} />{' '}
            <span>
              <FormattedMessage id="postOffice_span" />
            </span>
          </h3>
          <ul className="document-list1">
            <li>
              <FormattedMessage id="list_social_security" />
            </li>
            <li>
              <FormattedMessage id="list_credit_card" />
            </li>
            <li>
              <FormattedMessage id="list_school_id" />
            </li>
            <li>
              <FormattedMessage id="list_medical_marijuana" />
            </li>
            <li>
              <FormattedMessage id="list_concealed_licence" />
            </li>
            <li>
              <FormattedMessage id="list_expierd_forms" />
            </li>
          </ul>
        </section>
        <div className="state-section-container">
          {states[state]?.showSameStateContent === true && <StateContent />}
          <h3 className="state-title" data-test-id="usps-state-title">
            <FormattedMessage
              id={`usps_state_title`}
              values={{ ...richText }}
            />
          </h3>
        </div>
        <div className="state-section" data-test-id="state-contact-container">
          <FormattedMessage id={`usps_state_number`} values={{ ...richText }} />
          <FormattedMessage
            id={`usps_state_timing_para`}
            values={{ ...richText }}
          />
          <FormattedMessage id={`usps_contact`} values={{ ...richText }} />
          <div className="state-section">
            <Email swa={stateParams.swa} />
          </div>
        </div>
      </section>
    </section>
  );
};
